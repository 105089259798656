import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/Loader";
import BlogContext from "../../../context/blogContext";
import MainAbout from "../../../components/MainAbout";
import IndusBenoProvides from "../../../components/IndusBenoProvides";
import he from "he";
const EcomIndus = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".ecom-main-indus-page-index"
    );
    const stickyDiv = "sticky-ites-indus";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  const [areCardsVisible, setAreCardsVisible] = useState(true);
  const toggleCards = () => {
    setAreCardsVisible(!areCardsVisible);
  };
  const [areReadVisible, setAreReadVisible] = useState(true);
  const toggleRead = () => {
    setAreReadVisible(!areReadVisible);
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    mainServicesData,
    benefitsData,
    ourExpertiseData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 23 && i.page_id === 87),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 23 && i.page_id === 87),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 61),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 23 && i.page_id === 87),
    [mainServicesData]
  );

  const benefitsTop = useMemo(
    () => pagesData.find((i) => i.id === 62),
    [pagesData]
  );

  const benefitsBoxData = useMemo(
    () => benefitsData.filter((i) => i.category_id === 23 && i.page_id === 87),
    [benefitsData]
  );

  const expertiseTop = useMemo(
    () => pagesData.find((i) => i.id === 63),
    [pagesData]
  );

  const expertiseData = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 23 && i.page_id === 87),
    [ourExpertiseData]
  );
  if (!serviceTitleDes || !benefitsTop || !expertiseTop) return null;
  return (
    <>
      {loading && <Loader />}

      {/*-------------- banner section --------------*/}
      {first ? (
        <section
          id="ecom-indus-main-banner"
          className="ecom-indus-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="ecom-indus-main-bet-content ecom-indus-main-banner-content ecom-indus-main-banner-content-main">
              <h3
                data-aos="zoom-in"
                className="ecom-indus-main-banner-head ecom-indus-main-banner-head-unique"
              >
                {first.title}
              </h3>
            </div>
            <div className="indus-ecom-banner-main-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-ind-ecom">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}

      {/* ------------ ITES industry page indexing section -------------- */}
      <section className="ecom-main-indus-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="ecom-main-indus-index-nav">
                <ul>
                  <li>
                    <a
                      className={`index-ecom-main-indus-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-ecom-main-indus-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-ecom-main-indus-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-ecom-main-indus-item ${
                        current === "overview" ? "active" : ""
                      }`}
                      href="#overview"
                      aria-label="overview?"
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-ecom-main-indus-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/e-commerce"}> E-Commerce </Link>
        </div>
      </section>

      <MainAbout mainAboutData={dgtMainAboutArray} />

      <IndusBenoProvides
        indusHeadDesc={serviceTitleDes}
        benoProvides={serviceData}
      />

      {/* -------- our capabilities section start -------- */}
      <section id="services" className="ecom-main-ind-capabilities-sec">
        <div className="container">
          <div>
            <h2
              className="indus-main-ecom-section-heading text-center ecom-main-ind-capab-head-bg"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTop.Heading),
              }}
            ></h2>
            <p
              className="ecom-main-indus-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTop.description),
              }}
            ></p>
          </div>
          <div className="row mt-4">
            {benefitsBoxData && benefitsBoxData.length > 0
              ? benefitsBoxData.map((i) => (
                  <div
                    id="ecom-ind-business-areas-main"
                    data-aos="fade-up-left"
                    className="col-lg-4 col-md-4 col-sm-12"
                  >
                    <div className="ecom-ind-inner-area-customer-main">
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ----------we effortlessly handle it all section start----------- */}
      <section id="overview">
        <div className="container">
          <div>
            <h2
              className="indus-main-ecom-section-heading text-center ecom-main-carter-head-bg"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiseTop.Heading),
              }}
            ></h2>
            <p
              className="ecom-main-indus-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiseTop.description),
              }}
            ></p>
          </div>

          {/* service area cards */}
          <div className="row">
            {expertiseData && expertiseData.length > 0
              ? expertiseData.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 ecom-main-indus-service-area-cards"
                    key={i.id}
                  >
                    <h2 data-aos="fade-left">{i.title}</h2>
                    <p
                      data-aos="fade-left"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends in business process automation with our
            dynamic insights. Learn about the most recent developments in the
            field, as well as successful stories and best practices, to enable
            your company to prosper in the digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.EcomBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Personalization in Ecommerce: Strategies to Enhance Customer
                Engagement and Drive Sales
              </h2>
              <p className="recent-blogs-slider-desc">
                In today's competitive ecommerce landscape, personalization has
                emerged as a key strategy to engage customers and boost sales.
                This blog explores various personalized marketing techniques,
                such as tailored product recommendations, dynamic content, and
                customized shopping experiences. By understanding the
                preferences and behaviors of individual shoppers, ecommerce
                businesses can create more meaningful interactions, improve
                customer satisfaction, and ultimately drive conversion rates.
                Discover practical strategies and tools to implement effective
                personalization strategies and unlock the full potential of your
                ecommerce platform.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Sustainability in Ecommerce: Explore eco-friendly
                            practices for online retailers
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover strategies for online retailers to implement
                          eco-friendly practices and reduce their environmental
                          footprint effectively.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Role of AI in Ecommerce: How Artificial
                            Intelligence is Revolutionizing the Online Shopping
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          The Role of AI in Ecommerce": Learn how AI is
                          reshaping online shopping with personalized
                          recommendations, chatbots, and predictive analytics.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Navigating Supply Chain Challenges in Ecommerce:
                            Strategies for Efficient Inventory Management and
                            Fulfillment
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore strategies to optimize inventory management
                          and streamline fulfillment processes for online
                          retailers.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Sustainability in Ecommerce: Explore eco-friendly
                            practices for online retailers
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover strategies for online retailers to implement
                          eco-friendly practices and reduce their environmental
                          footprint effectively.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Role of AI in Ecommerce: How Artificial
                            Intelligence is Revolutionizing the Online Shopping
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          The Role of AI in Ecommerce": Learn how AI is
                          reshaping online shopping with personalized
                          recommendations, chatbots, and predictive analytics.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EcomIndus;

import React from "react";
import he from "he";
import LazyLoad from "react-lazyload";

const InnerServices = ({ headDesc, innerServices }) => {
  if (!headDesc || !innerServices) return null;

  return (
    <section id="services" className="ites-dgt-bg-effect">
      <div className="container">
        <h2
          className="bpo-sub-section-heading"
          dangerouslySetInnerHTML={{ __html: he.decode(headDesc.Heading) }}
        ></h2>
        {headDesc.description ? (
          <p
            className="bpo-sub-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(headDesc.description),
            }}
          ></p>
        ) : null}

        <div className="row d-flex justify-content-center">
          {innerServices &&
            innerServices.length > 0 &&
            innerServices.map((i) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                id="bpo-sub-service-areas-cont-first"
                data-aos="fade-up-left"
                key={i.id}
              >
                <div className="row">
                  <div
                    className="col-lg-5 col-md-5 col-sm-12"
                    id="bpo-sub-service-areas-cont-first-img"
                  >
                    <LazyLoad height={200} offset={100}>
                      <img
                        src={i.service_img}
                        className="rounded float-start"
                        alt={i.service_img_alt}
                      />
                    </LazyLoad>
                  </div>
                  <div
                    className="col-lg-7 col-md-7 col-sm-12"
                    id="bpo-sub-service-areas-cont-first-desc"
                  >
                    <h4>{i.title}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default InnerServices;

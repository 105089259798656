import React, { useState, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IMG from "../../../layouts/ImagesOld2";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { CtaStripe } from "./ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const DesignStrategy = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    mainServicesData,
    pagesData,
    segmentData,
    featureImages,
    benefitsData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 11 && i.page_id === 35),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 11 && i.page_id === 35),
    [bannerData]
  );

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 11 && i.page_id === 35),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const [first] = bpoBanner;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 99),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 11 && i.page_id === 35),
    [mainServicesData]
  );

  const multiSegment = useMemo(
    () =>
      segmentData.filter(
        (i) =>
          i.page_id === 35 &&
          i.section_id === 99 &&
          i.segment_type === "multi-segment"
      ),
    [segmentData]
  );

  const singleSegment = useMemo(
    () =>
      segmentData.find(
        (i) =>
          i.page_id === 35 &&
          i.section_id === 99 &&
          i.segment_type === "single-segment"
      ),
    [segmentData]
  );

  const keyStep = useMemo(
    () => pagesData.find((i) => i.id === 216),
    [pagesData]
  );

  const feature = useMemo(
    () => featureImages.find((i) => i.section_id === 216 && i.page_id === 35),
    [featureImages]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 11 && i.page_id === 35),
    [benefitsData]
  );

  if (!keyStep || !feature) return null;
  if (loading) return <Loader />;
  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/software-services"}
        pageTitle={"Software Services"}
        currentPage={"Design Strategy"}
      />

      {/*------------- application service --------------------*/}

      {/*-------------------------BPO service What-we-do start--------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* ------------------- application what think first-------------------- */}
      <section className="application-ds-successful-journey">
        <div className="container">
          <h6 class="application-cat-pre-head">{keyStep.pre_heading}</h6>
          <div className="appser-head-div">
            <h2
              className="application-sub-section-heading-spec"
              id="app-srvice-head-bg"
              dangerouslySetInnerHTML={{ __html: he.decode(keyStep.Heading) }}
            ></h2>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-8 application-dssjour">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {benefits && benefits.length > 0
                  ? benefits.map((item, index) => (
                      <div
                        className="accordion-item app-juour-item"
                        data-aos="fade-up"
                        key={index}
                      >
                        <h2
                          className="accordion-header"
                          id={`flush-heading-${index}`}
                        >
                          <button
                            className={`accordion-button ${
                              index !== 0 ? "collapsed" : ""
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse-${index}`}
                            aria-expanded={index === 0 ? "true" : "false"}
                            aria-controls={`flush-collapse-${index}`}
                          >
                            <span>0{index + 1}. &nbsp;</span>
                            {item.title}
                          </button>
                        </h2>
                        <div
                          id={`flush-collapse-${index}`}
                          className={`accordion-collapse collapse ${
                            index === 0 ? "show" : ""
                          }`}
                          aria-labelledby={`flush-heading-${index}`}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: he.decode(item.description),
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 application-dssjour-img"
              data-aos="fade-right"
            >
              <img src={feature.single_pic} alt={feature.pic_alt} />
            </div>
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ------------------- application what think first-------------------- */}

      {singleSegment ? (
        <section
          id="sales-what-we-do"
          className="What-we-do"
          style={{
            backgroundImage: `url(${IMG.BpoUnderBack})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="what-we-heading">
                  <h2
                    className="application-services-sub-section-heading understand-head-bg"
                    id="app-srvice-head-bg"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(singleSegment.title),
                    }}
                  ></h2>
                  <p
                    className="application-services-sub-section-sub-heading"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(singleSegment.description),
                    }}
                  ></p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="application-services-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${singleSegment.image})`,
                  }}
                ></div>

                <span className="application-services-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ---------- application what think second ------------- */}
      <section id="application-services-what-think-seocnd">
        <div className="container">
          {multiSegment && multiSegment.length > 0
            ? multiSegment.map((i) => (
                <div className="row" key={i.id}>
                  <div
                    className="col-lg-3 col-md-3 col-sm-12"
                    data-aos="flip-up"
                    id="application-services-success-stories-multi"
                  >
                    <img
                      src={i.image}
                      className="application-services-success-stories-multi-img"
                      alt={i.image_alt}
                    />
                  </div>
                  <div
                    className="col-lg-9 col-md-9 col-sm-12"
                    data-aos="fade-up-left"
                    id="application-services-success-stories-multi-data"
                  >
                    <h6 className="appser-ds-think-pre">{i.pre_heading}</h6>
                    <h5>{i.title}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default DesignStrategy;

import React, { useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import he from "he";
import { faArrowRightLong, faPlay } from "@fortawesome/free-solid-svg-icons";
import { CtaStripe } from "./ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const DevOps = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    segmentData,
    mainServicesData,
    pagesData,
    benefitsData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 11 && i.page_id === 40),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 11 && i.page_id === 40),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 11 && i.page_id === 40),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const segment = useMemo(
    () => segmentData.find((i) => i.page_id === 40 && i.section_id === 111),
    [segmentData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 112),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 11 && i.page_id === 40),
    [mainServicesData]
  );

  const benefitsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 113),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 11 && i.page_id === 40),
    [benefitsData]
  );

  if (!servicesTitleDes || !benefitsTitleDes) return null;

  if (loading) return <Loader />;
  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#application-sevices"} />

      <BreadCrumb
        url={"/software-services"}
        pageTitle={"Software Services"}
        currentPage={"DevOps"}
      />

      {/*------------- application DevOps service --------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* ------------ Corporate Adaptability -------------- */}

      {segment ? (
        <section className="application-ds-successful-journey">
          <div className="container">
            <div className="appser-head-div" id="appser-head-agile">
              <h2
                className="application-sub-section-heading-spec application-sub-section-heading-spec-devops "
                dangerouslySetInnerHTML={{
                  __html: he.decode(segment.pre_heading),
                }}
              ></h2>
            </div>

            <div className="row" id="agile-corporate-main">
              <div
                className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 agile-corporate-para"
                data-aos="fade-left"
              >
                <h3>{segment.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>

                <div class="pb-4 agile-corporate-content-btn">
                  <a href="/blog-details">Learn More</a>
                </div>
              </div>
              <div
                class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 agile-corporate-img-div"
                data-aos="fade-right"
              >
                <img
                  src={segment.image}
                  alt={segment.image_alt}
                  className="agile-corp-img"
                />
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/*------------- application DevOps service area  --------------*/}

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      <CtaStripe />

      {/*------------- DevOps Services first --------------------*/}
      <section id="application-sevices" className="application-smart-sevices">
        <div className="container">
          <h2
            class="application-sub-section-heading"
            id="devops-app-serve-notch-head"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitleDes.Heading),
            }}
          ></h2>
          <p
            class="application-sub-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitleDes.description),
            }}
          ></p>
          <div className="row">
            {benefits && benefits.length > 0
              ? benefits.map((i, index) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 app-smart-best"
                    data-aos="fade-up"
                    key={i.id}
                  >
                    <div className="app-smart-best-errow">
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </div>
                    <div className="app-smart-best-head">
                      <h2>
                        0{index + 1} &nbsp;<span>{i.title}</span>
                      </h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ----------------  latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default DevOps;

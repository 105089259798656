import React, { useContext, useMemo } from "react";

import he from "he";
import IndustryBanner from "../components/IndustryBanner";
import Loader from "../components/Loader";
import BlogContext from "../context/blogContext";
import { CtaStripe } from "../pages/Services/ApplicationService/ApplicationServices";
import IndustyAbout from "../components/IndustyAbout";

const VrEntertain = () => {
  const { bannerData, loading, mainAboutData, pagesData } =
    useContext(BlogContext);

  const fintechBanner = useMemo(
    () => bannerData.find((i) => i.category_id === 14 && i.page_id === 119),
    [bannerData]
  );

  const fintechAbout = useMemo(
    () => mainAboutData.find((i) => i.category_id === 14 && i.page_id === 119),
    [mainAboutData]
  );

  const fintechTitle1 = useMemo(
    () => pagesData.find((i) => i.id === 224),
    [pagesData]
  );

  const fintechTitle2 = useMemo(
    () => pagesData.find((i) => i.id === 225),
    [pagesData]
  );

  if (!fintechTitle1 || !fintechTitle2) return null;

  return (
    <>
      {loading && <Loader />}
      <IndustryBanner
        industyBanner={fintechBanner}
        indexId={"#industry-specific"}
        blackTitle
      />

      <IndustyAbout industryAboutData={fintechAbout} />

      <section className="bpo-travel-best" id="industry-specific">
        <div className="container">
          <div className="row">
            <div className="bpo-travel-best-inner col-sm-12 col-lg-12 col-md-12">
              <h2
                dangerouslySetInnerHTML={{
                  __html: he.decode(fintechTitle1.Heading),
                }}
              ></h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(fintechTitle1.description),
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>
      {/* ---------bpo travel back front */}
      <section className="bpo-travel-back-front">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12  bpo-travel-best-under">
              <h2
                dangerouslySetInnerHTML={{
                  __html: he.decode(fintechTitle2.Heading),
                }}
              ></h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(fintechTitle2.description),
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      <CtaStripe />
    </>
  );
};

export default VrEntertain;

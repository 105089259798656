import React, { useContext, useMemo, useState } from "react";
import { CtaStripe } from "./ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const ApplicationTransformation = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    mainServicesData,
    pagesData,
    statisticsData,
    segmentData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 11 && i.page_id === 38),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 11 && i.page_id === 38),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 11 && i.page_id === 38),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 106),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 11 && i.page_id === 38),
    [mainServicesData]
  );

  const counterTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 107),
    [pagesData]
  );

  const counter = useMemo(
    () =>
      statisticsData.filter((i) => i.category_id === 11 && i.page_id === 38),
    [statisticsData]
  );

  const segmentTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 108),
    [pagesData]
  );

  const multiSegment = useMemo(
    () => segmentData.filter((i) => i.page_id === 38 && i.section_id === 106),
    [segmentData]
  );

  if (!counterTitleDes || !segmentTitleDes) return null;
  if (loading) return <Loader />;

  return (
    <>
      <ServiceBanner
        serviceBanner={first}
        indexId={"#app-trans-words-from-us"}
      />

      <BreadCrumb
        url={"/software-services"}
        pageTitle={"Software Services"}
        currentPage={"Application Transformation"}
      />

      {/*------------- application Agile Transformation service --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/*---------------- words from us section ------------------*/}
      <section id="app-trans-words-from-us">
        <div className="container">
          <div className="application-service-inno-head">
            <h2
              className="application-sub-section-heading text-center"
              id="app-srvice-head-bg"
              dangerouslySetInnerHTML={{
                __html: he.decode(counterTitleDes.Heading),
              }}
            ></h2>
            <p
              className="application-sub-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(counterTitleDes.description),
              }}
            ></p>
          </div>
          <div className="row" id="app-trans-words-from-row">
            {counter && counter.length > 0
              ? counter.map((i) => (
                  <div
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 apt-words-from-us-cards"
                    key={i.id}
                  >
                    <h2 data-aos="slide-down">
                      {i.prefix}
                      {i.number}
                      {i.suffix}
                    </h2>
                    <p data-aos="slide-down">{i.title}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ------------ aplication transformation  what we think-------------*/}
      <section id="aps-transformation">
        <div className="container">
          <div className="application-service-inno-head">
            <h2
              className="application-sub-section-heading application-sub-section-heading-aptrans"
              dangerouslySetInnerHTML={{
                __html: he.decode(segmentTitleDes.Heading),
              }}
            ></h2>
          </div>
          <div className="row" id="aps-trans-row">
            {multiSegment && multiSegment.length > 0
              ? multiSegment.map((i) => (
                  <div
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
                    data-aos="flip-up"
                    key={i.id}
                  >
                    <div className="card aps-trans-card">
                      <img
                        src={i.image}
                        className="aps-trans-think-card-img"
                        alt={i.image_alt}
                      />
                      <span className="aps-trans-triangle-down"></span>
                      <div className="card-body">
                        <p className="aps-trans-under-txt">{i.pre_heading}</p>
                        <h5 className="card-title aps-trans-under-head">
                          {i.title}
                        </h5>
                        <p
                          className="card-text aps-trans-under-para"
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default ApplicationTransformation;

import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import MainAbout from "../../../components/MainAbout";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const BpoLegalProcess = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    mainAboutData,
    blogData,
    mainServicesData,
    pagesData,
    statisticsData,
    segmentData,
    featureImages,
  } = useContext(BlogContext);
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 6 && i.page_id === 21),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 6 && i.page_id === 21),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 6 && i.page_id === 21),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 29),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 6 && i.page_id === 21),
    [mainServicesData]
  );

  const statistics = useMemo(
    () => statisticsData.filter((i) => i.category_id === 6 && i.page_id === 21),
    [statisticsData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.section_id === 29 && i.page_id === 21),
    [segmentData]
  );

  const feature = useMemo(
    () => featureImages.find((i) => i.section_id === 29 && i.page_id === 21),
    [featureImages]
  );
  return (
    <>
      <ServiceBanner serviceBanner={first} />

      <BreadCrumb
        url={"/bpo-service"}
        pageTitle={"Bpo Services"}
        currentPage={"Legal Process Outsourcing"}
      />

      {/*-------------------------BPO "understand the process" start--------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={serviceTitleDes} innerServices={serviceData} />

      {/* ---------------------------------work with us start--------------------------- */}
      <section id="legel-work-with-us" className="bpo-sub-work-with-us">
        <div className="container">
          <div className="row" id="doi-data-counter-row">
            {statistics && statistics.length > 0
              ? statistics.map((i) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 bpo-sub-why-box-content"
                    key={i.id}
                  >
                    <h2 data-aos="fade-down">
                      {i.prefix}
                      {i.number}
                      {i.suffix}
                    </h2>
                    <p data-aos="fade-down">{i.title}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/*-------------------------BPO "understand the process" start--------------------*/}
      {segment ? (
        <section id="operations-designed" className="operations-designed ">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                id="operations-designed-cols"
                data-aos="fade-up-left"
              >
                <div className="operations-heading">
                  <h2
                    className="bpo-sub-section-heading"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(segment.title),
                    }}
                  ></h2>
                  <p
                    className="bpo-sub-section-sub-heading"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(segment.description),
                    }}
                  ></p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-up-right"
              >
                <div className="doi-understand-img-do">
                  <img
                    src={segment.image}
                    className="doi-understand-img-do"
                    alt={segment.image_alt}
                  />
                </div>

                <span className="bpo-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/*-------------------------BPO "Beno Support’s LPO Business Modal" start--------------------*/}
      {feature ? (
        <section id="operations-designed" className="operations-designed">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="operations-heading">
                  <h2
                    className="LPO-head"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(feature.name),
                    }}
                  ></h2>
                  <img
                    src={feature.single_pic}
                    alt={feature.pic_alt}
                    className="LPO-img"
                    data-aos="flip-up"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default BpoLegalProcess;

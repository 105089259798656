import React, { Fragment, useContext, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import { CtaStripe } from "./ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const QualityEngineering = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    mainServicesData,
    pagesData,
    benefitsData,
    segmentData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 11 && i.page_id === 39),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 11 && i.page_id === 39),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 11 && i.page_id === 39),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 109),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 11 && i.page_id === 39),
    [mainServicesData]
  );

  const benefitsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 110),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 11 && i.page_id === 39),
    [benefitsData]
  );

  const multiSegment = useMemo(
    () => segmentData.filter((i) => i.page_id === 39 && i.section_id === 109),
    [segmentData]
  );

  if (!benefitsTitleDes) return null;

  if (loading) return <Loader />;
  return (
    <>
      {/* {first && (
        <section
          id="application-sub-banner-ds"
          className="ads-sub-banner ads-sub-banner-quality"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container" id="ads-sub-banner-main-cont">
            <div className="row">
              <div
                className="col-lg-8 col-md-8 col-sm-12"
                id="agile-banner-sec"
              >
                <h1
                  className="ads-sub-banner-Heading text-white"
                  data-aos="fade-left"
                >
                  {first.title}
                </h1>
                <p
                  className="ads-sub-banner-para text-white"
                  data-aos="fade-right"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>


                <div className="app-service-inner-banner-bg-btn">
                  <a href="#services">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="app-service-inner-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/software-services"}
        pageTitle={"Software Services"}
        currentPage={"Quality Engineering"}
      />

      {/*------------- application Agile Transformation service --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* --------- Our Approach Towards Quality Engineering Process section -------- */}
      <section id="aps-quality-eng-process">
        <div className="container">
          <div className="application-service-inno-head">
            <h2
              className="application-sub-section-heading application-services-sub-section-heading-apstrans"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTitleDes.Heading),
              }}
            ></h2>
            <p
              className="application-sub-section-sub-heading"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTitleDes.description),
              }}
            ></p>
          </div>
          <div className="row" id="aps-quality-eng-process-row">
            {benefits && benefits.length > 0
              ? benefits.map((i, index) => (
                  <Fragment key={i.id}>
                    <div
                      className="col-1 aps-right-arrow mb-3"
                      data-aos="flip-right"
                    >
                      <img src={IMG.ApproachArrow} alt="img" />
                    </div>
                    <div
                      className="col-2 apo-modernization-num mb-3"
                      data-aos="fade-up-left"
                    >
                      <h2> 0{index + 1}.</h2>
                    </div>
                    <div
                      className="col-9 apo-modernization-num-para mb3"
                      data-aos="fade-left"
                    >
                      <h2>{i.title}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </Fragment>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ------------ aplication transformation  what we think second-------------*/}
      <section id="aps-transformation-quality-enng">
        <div className="container">
          <div className="row" id="aps-trans-row">
            {multiSegment && multiSegment.length > 0
              ? multiSegment.map((i) => (
                  <div
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
                    data-aos="flip-up"
                  >
                    <div className="card aps-trans-card">
                      <img
                        src={i.image}
                        className="aps-trans-think-card-img"
                        alt={i.image_alt}
                      />
                      <span className="aps-trans-triangle-down"></span>
                      <div className="card-body">
                        <p className="aps-trans-under-txt">{i.pre_heading}</p>
                        <h5 className="card-title aps-trans-under-head">
                          {i.title}
                        </h5>
                        <p
                          className="card-text aps-trans-under-para"
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ----------------  latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default QualityEngineering;

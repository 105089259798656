import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltRight,
  faArrowRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import { Link } from "react-router-dom";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import he from "he";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import IndustryServe from "../../../components/IndustryServe";

const Cloud = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".nag-page-index-cloud");
    const stickyDiv = "sticky-cloud";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    serviceIndustryData,
    pagesData,
    mainServicesData,
    ourExpertiseData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 9 && i.page_id === 31),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 9 && i.page_id === 31),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const industryWeServe = useMemo(
    () => pagesData.filter((i) => i.id === 16),
    [pagesData]
  );

  const [industryWeServeData] = industryWeServe;

  const serviceIndustry = useMemo(
    () =>
      serviceIndustryData.filter(
        (i) => i.category_id === 9 && i.page_id === 31
      ),
    [serviceIndustryData]
  );

  const whatWeDoTop = useMemo(
    () => pagesData.filter((i) => i.id === 38),
    [pagesData]
  );

  const [whatWeDoTopHead] = whatWeDoTop;

  const filteredServices = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 9 && i.page_id === 31),
    [mainServicesData]
  );

  const ourExp = useMemo(
    () => pagesData.filter((i) => i.id === 39),
    [pagesData]
  );

  const [ourExpData] = ourExp;

  const ourApproach = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 9 && i.page_id === 31),
    [ourExpertiseData]
  );

  if (!whatWeDoTopHead || !filteredServices || !ourExpData || !ourApproach)
    return null;
  if (loading) return <Loader />;
  return (
    <>
      {/* ---------------- banner section --------------- */}
      {first && (
        <section
          className="cloud-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="cloud-div-banner-title">
                  <h2
                    className="cloud-banner-heading text-white"
                    data-aos="fade-down"
                  >
                    {first.title}
                  </h2>
                  <p
                    className="cloud-banner-para text-white"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>

                <div className="row" id="play-button-cloud ">
                  <div className="col-lg-9  col-sm-12 cloud-know-how-btn mt-3">
                    <div className="cloud-banner-know-how">
                      <a href="#cloud-approach">
                        {first.btn_first}{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* --------------- page index section ------------- */}
      <section className="nag-page-index-cloud">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar-index-cloud">
                <ul className="nav-index-cloud">
                  <li>
                    <a
                      className={`nav-link-index-cloud ${
                        current === "cloud-about" ? "active" : ""
                      }`}
                      href="#cloud-about"
                      aria-label="About"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-cloud ${
                        current === "cloud-industry" ? "active" : ""
                      }`}
                      href="#cloud-industry"
                      aria-label="Industry"
                    >
                      Industry
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-cloud ${
                        current === "cloud-approach" ? "active" : ""
                      }`}
                      href="#cloud-approach"
                      aria-label="BENEFITS"
                    >
                      Approach
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-cloud ${
                        current === "cloud-services" ? "active" : ""
                      }`}
                      href="#cloud-services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-cloud ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link className="text-black" to="/">
            Home{" "}
          </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link className="text-black" to={"/cloud-services"}>
            {" "}
            Cloud Services{" "}
          </Link>
        </div>
      </section>

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* --------- industries  section ----------- */}
      <IndustryServe
        industryWeServeData={industryWeServeData}
        serviceIndustry={serviceIndustry}
      />

      {/* --------- Approach section ----------- */}
      <section id="cloud-approach" className="cloud-approach-sec">
        <div className="container">
          <h3
            className="cloud-approach-main-heading"
            dangerouslySetInnerHTML={{ __html: he.decode(ourExpData.Heading) }}
          ></h3>
          <p
            className="cloud-head-main-para"
            dangerouslySetInnerHTML={{
              __html: he.decode(ourExpData.description),
            }}
          ></p>

          <div className="row" id="cloud-approach-row-main-content">
            {ourApproach.map((i) => (
              <div
                className="col-lg-3 col-md-3 col-sm-12 cloud-main-approach-box"
                key={i.id}
              >
                <h2 data-aos="fade-down">{i.title}</h2>
              </div>
            ))}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ----------- service section ------------------ */}
      <section id="cloud-services">
        <div className="container">
          <h2
            className="cloud-head-main-title text-center"
            id="cloud-service-head-effect"
          >
            {whatWeDoTopHead.Heading}
          </h2>
          <p
            className="cloud-head-main-para text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(whatWeDoTopHead.description),
            }}
          ></p>
          <div className="row d-flex justify-content-center">
            {filteredServices.map((i) => (
              <div
                className="col-lg-4 col-md-4 col-sm-12 cloud-services-div-main"
                data-aos="fade-up"
                key={i.id}
              >
                <div className="card cloud-service-crd-main">
                  <div className="cloud-service-crd-img-main">
                    <Link to={i.page_link}>
                      <img
                        src={i.service_img}
                        alt={i.service_img_alt}
                        className="card-img-top"
                      />
                    </Link>
                  </div>

                  <div className="card-body">
                    <Link to={i.page_link}>
                      <h3>{i.title}</h3>
                    </Link>
                    <p
                      className="card-text"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                    <div className="cloud-service-small-box-down">
                      <Link to={i.page_link}>
                        <FontAwesomeIcon icon={faArrowRightLong} />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* ---------  latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Explore cutting-edge trends and dive into the latest developments,
            successful case studies, and best practices, including cloud
            services-related blogs, to empower your company's success in the
            digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.CloudBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <Link to="/blog-details">
                <h2 className="recent-blogs-slider-title">
                  Five Ways Cloud Computing Can Benefit Your Organization
                </h2>
              </Link>
              <p className="recent-blogs-slider-desc">
                Immerse in the transformative realm of cloud computing, where
                innovation flourishes without infrastructure worries. With
                budget-friendly plans, unleash creativity, scale confidently
                into new markets, and manage costs effectively. Explore cloud
                cost management for technological breakthroughs, streamlined
                processes, and fortified security. Enjoy the flexibility to
                adapt to changing needs and collaborate seamlessly across teams.
                Cloud computing offers myriad benefits, from scalability and
                collaboration to cost-efficiency and security, revolutionizing
                organizational efficiency and resilience.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Decoding AWS, Azure, and GCP: Comparing the Leading
                            Cloud Giants
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Market leaders AWS, Microsoft, and Google compete, but
                          multi-cloud strategies cater to diverse enterprise
                          needs.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Mastering Cloud Compliance: Best Practices to Ensure
                            Regulatory Conformance and Data Security
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Emerging Trends in Cloud Security: Safeguarding Data
                            and Systems in Evolving Digital Landscapes
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore the latest developments shaping cloud security
                          and learn to mitigate emerging threats effectively.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Decoding AWS, Azure, and GCP: Comparing the Leading
                            Cloud Giants
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Market leaders AWS, Microsoft, and Google compete, but
                          multi-cloud strategies cater to diverse enterprise
                          needs.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Mastering Cloud Compliance: Best Practices to Ensure
                            Regulatory Conformance and Data Security
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover actionable insights for maintaining
                          compliance standards and safeguarding data integrity
                          within cloud environments.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cloud;

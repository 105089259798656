import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const BreadCrumb = ({ url, pageTitle, currentPage }) => {
  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".nag-page-index-dgt-experience"
    );
    const stickyDiv = "sticky-dgt-experience";
    const yourHeader = 620;
    const handleScroll = () => {
      var currentWidth =
        window.innerWidth || document.documentElement.clientWidth;
      if (currentWidth >= 991) {
        if (window.scrollY > yourHeader) {
          yourNavigation.classList.add(stickyDiv);
        } else {
          yourNavigation.classList.remove(stickyDiv);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <section className="nag-page-index-dgt-experience breadcrumb">
        <Link to={"/"}>Home </Link>
        <MdOutlineArrowForwardIos style={{ fontSize: "13px", color: "#fff" }} />
        <Link className="breadcrumb-url" to={url}>
          {pageTitle}
        </Link>
        <MdOutlineArrowForwardIos style={{ fontSize: "13px", color: "#fff" }} />
        <span style={{ color: "#fff", cursor: "pointer" }}> {currentPage}</span>
      </section>
    </>
  );
};

export default BreadCrumb;

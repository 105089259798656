import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import { CtaStripe } from "../../Services/ApplicationService/ApplicationServices";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import he from "he";
import MainAbout from "../../../components/MainAbout";
const LogisticMain = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".logistic-main-indus-page-index"
    );
    const stickyDiv = "sticky-logistic-indus";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    benefitsData,
    mainServicesData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 20 && i.page_id === 74),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 20 && i.page_id === 74),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const benefitsTop = useMemo(
    () => pagesData.find((i) => i.id === 54),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 20 && i.page_id === 74),
    [benefitsData]
  );

  const providesTop = useMemo(
    () => pagesData.find((i) => i.id === 55),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.find((i) => i.category_id === 20 && i.page_id === 74),
    [mainServicesData]
  );

  const segmentTitle = useMemo(
    () => pagesData.find((i) => i.id === 163),
    [pagesData]
  );

  const segment = useMemo(
    () => segmentData.filter((i) => i.section_id === 163 && i.page_id === 74),
    [segmentData]
  );

  if (loading) return <Loader />;

  if (!benefitsTop || !providesTop || !segmentTitle) return null;
  return (
    <>
      {/*-------------- banner section --------------*/}

      {first ? (
        <section
          id="logistic-indus-main-banner"
          className="logistic-indus-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="logistic-indus-main-bet-content logistic-indus-main-bet-content-unique logistic-indus-main-banner-content">
              <h3
                data-aos="zoom-in"
                className="logistic-indus-main-banner-head text-white"
              >
                {first.title}
              </h3>
            </div>
            <div className="indus-logistic-banner-main-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-ind-logistic">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}

      {/* ------------ Logistic industry page indexing section -------------- */}
      <section className="logistic-main-indus-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="logistic-main-indus-index-nav">
                <ul>
                  <li>
                    <a
                      className={`index-logistic-main-indus-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-logistic-main-indus-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-logistic-main-indus-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-logistic-main-indus-item ${
                        current === "overview" ? "active" : ""
                      }`}
                      href="#overview"
                      aria-label="overview?"
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-logistic-main-indus-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/logistics"}> Logistics </Link>
        </div>
      </section>

      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* -------- Logistic industry provides section -------- */}
      <section id="provides">
        <div class="container">
          <div>
            <h2
              className="indus-main-logistic-section-heading text-center logi-main-provides-head-bg"
              dangerouslySetInnerHTML={{
                __html: he.decode(providesTop.Heading),
              }}
            ></h2>
            <p
              className="logistic-main-about-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(providesTop.description),
              }}
            ></p>
          </div>

          {serviceData ? (
            <>
              <div class="row mt-4">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <ul class="inner-logistic-main-provides">
                    <p
                      className="logistics-bpo-prodv-para"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(serviceData.description),
                      }}
                    ></p>
                  </ul>
                </div>

                <div
                  class="col-lg-6 col-md-6 col-sm-12 aos-init"
                  id="ind-logi-we-think-second"
                  data-aos="fade-left"
                >
                  <Link to="/logistics/bpo">
                    <img
                      src={serviceData.service_img}
                      class="ind-logis-main-we-think-img"
                      alt={serviceData.service_img_alt}
                    />
                  </Link>
                </div>
              </div>
              <div className="indus-education-banner-main-btn indus-education-banner-main-btn-logistic d-flex justify-content-center">
                <Link
                  to={serviceData.page_link}
                  className="bnr-nbtn-ind-education"
                >
                  Read More
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </section>

      {/* -------- Logistic our capabilities section start -------- */}
      <section id="services" className="logistic-main-ind-capabilities-sec">
        <div className="container">
          <div>
            <h2
              className="indus-main-logistic-section-heading text-center logistic-main-ind-capab-head-bg"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTop.Heading),
              }}
            ></h2>
            <p
              className="logistic-main-about-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTop.description),
              }}
            ></p>
          </div>
          <div className="row mt-4">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div
                    id="logistic-ind-capabilities-main"
                    data-aos="fade-up-left"
                    className="col-lg-4 col-md-4 col-sm-12"
                    key={i.id}
                  >
                    <div className="logistic-ind-inner-area-customer-main">
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* -===============================  Logistic  whats new  section =============================- */}
      <section id="overview" className="logistic-main-whats-new-sec">
        <div className="container">
          <h2
            className="indus-main-logistic-section-heading main-logistic-indus-whats-new-eff"
            dangerouslySetInnerHTML={{
              __html: he.decode(segmentTitle.Heading),
            }}
          ></h2>
          <div className="row">
            {segment && segment.length > 0
              ? segment.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12"
                    id="logistic-ind-frid-first-whats-new-sec"
                    data-aos="flip-up"
                    key={i.id}
                  >
                    <div className="card logistic-ind-whats-new-grid-first-card">
                      <img
                        src={i.image}
                        className="card-img-top logistic-ind-whats-new-grid-first-card-img"
                        alt={i.image_alt}
                      />
                      <div className="card-body">
                        <h6 className="logistic-ind-whats-new-grid-first-card-category">
                          {i.pre_heading}
                        </h6>
                        <a className="logistic-ind-whats-new-grid-first-card-link">
                          <h5 className="card-title logistic-ind-whats-new-grid-first-card-title">
                            {i.title}
                          </h5>
                        </a>
                        <p
                          className="card-text logistic-ind-whats-new-grid-first-card-para"
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
      {/* ---------------- Bpo service latest one blog section  ----------- */}

      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends shaping the logistics industry in our
            recent blog. From AI-driven solutions to sustainable practices, stay
            updated on advancements revolutionizing supply chain management.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.LogisticBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Customer-Centric Logistics: Enhancing Shipper Experience with
                Technology
              </h2>
              <p className="recent-blogs-slider-desc">
                In today's competitive market, customer satisfaction is
                paramount. In our latest blog post, "Customer-Centric Logistics:
                Enhancing Shipper Experience with Technology," we explore how
                technology is revolutionizing the logistics industry to
                prioritize the needs of shippers. From real-time tracking and
                predictive analytics to streamlined communication and
                personalized services, discover how innovative solutions are
                reshaping the logistics landscape, delivering greater
                transparency, efficiency, and satisfaction for shippers around
                the globe. Join us as we delve into the future of
                customer-centric logistics powered by cutting-edge technology
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Innovative Approaches: AR and RFID in Warehouse
                            Operations
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore how AR and RFID technologies are transforming
                          warehouse operations, optimizing processes and
                          enhancing accuracy for efficient logistics management.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            From Planning to Performance: Streamlining Logistics
                            with Technology
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how technology streamlines logistics
                          operations from initial planning stages to real-time
                          performance monitoring...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Driving Success: Enhancing Supply Chain Efficiency
                            with Advanced Tools
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Learn how advanced tools like AI, IoT, and predictive
                          analytics drive supply chain success by optimizing
                          operations, reducing costs, and improving overall
                          efficiency.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Innovative Approaches: AR and RFID in Warehouse
                            Operations
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore how AR and RFID technologies are transforming
                          warehouse operations, optimizing processes and
                          enhancing accuracy for efficient logistics management.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            From Planning to Performance: Streamlining Logistics
                            with Technology
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how technology streamlines logistics
                          operations from initial planning stages to real-time
                          performance monitoring...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogisticMain;

import React, { useContext, useMemo, useState } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import Loader from "../../../components/Loader";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const Sales = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    loading,
    blogData,
    mainAboutData,
    pagesData,
    mainServicesData,
    segmentData,
    statisticsData,
  } = useContext(BlogContext);
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 6 && i.page_id === 11),
    [bannerData]
  );
  const [first] = bpoBanner;

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 6 && i.page_id === 11),
    [blogData]
  );

  const [firstItem, ...otherItems] = bpoBlog;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 6 && i.page_id === 11),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 24),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 6 && i.page_id === 11),
    [mainServicesData]
  );

  const statisticsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 69),
    [pagesData]
  );

  const stateOfSales = useMemo(
    () => segmentData.find((i) => i.page_id === 11 && i.section_id === 24),
    [segmentData]
  );

  const salesStats = useMemo(
    () => statisticsData.filter((i) => i.category_id === 6 && i.page_id === 11),
    [statisticsData]
  );
  if (!statisticsTitleDes || !stateOfSales) return null;

  if (loading) return <Loader />;

  return (
    <>
      {/*-------------------------BPO service sales page banner Start --------------------*/}

      {/* {first && (
        <section
          id="bpo-sub-banner-sales"
          className="bpo-sub-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container" id="bpo-sub-banner-main-cont">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12">
                <h1 className="bpo-sub-banner-Heading" data-aos="fade-left">
                  {first.title}
                </h1>
                <p
                  className="bpo-sub-banner-para"
                  data-aos="fade-right"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>

                <div className="bpo-inner-banner-bg-btn">
                  <a href="#services">Services</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="bpo-inner-banner-bg-btn-num"
                  >
                    892-988-4560
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/bpo-service"}
        pageTitle={"Bpo Services"}
        currentPage={"Sales & Customer Operations"}
      />

      {/*-------------------------BPO service What-we-do start--------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={serviceTitleDes} innerServices={serviceData} />

      {/* ---------------------------------work with us start--------------------------- */}
      <section id="sales-work-with-us" className="bpo-sub-work-with-us">
        <div className="container">
          <h1
            className="bpo-sub-section-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(statisticsTitleDes.Heading),
            }}
          ></h1>

          <p
            className="bpo-sub-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(statisticsTitleDes.description),
            }}
          ></p>

          <div className="row">
            {salesStats && salesStats.length > 0
              ? salesStats.map((i) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 bpo-sub-why-box-content"
                    key={i.id}
                  >
                    <h2 data-aos="slide-down">
                      {i.prefix} {i.number}
                      {i.suffix}
                    </h2>
                    <p data-aos="slide-down">{i.title}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* -----------------------state of sales start---------------- */}
      <section id="sales-state-sec" className="sales-state-sec ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sales-state-heading">
                <h2
                  className="bpo-sub-section-heading"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(stateOfSales.title),
                  }}
                ></h2>
                <p
                  className="bpo-sub-section-sub-heading"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(stateOfSales.description),
                  }}
                ></p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div
                className="bpo-sub-under-img-right"
                style={{
                  backgroundImage: `url(${stateOfSales.image})`,
                }}
              ></div>
              <span className="bpo-sub-triangle-down"></span>
            </div>
          </div>
        </div>
      </section>
      <CtaStripe />
      {/* ---------------- Bpo service latest one blog section  ----------- */}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default Sales;

import React, { useContext, useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import IMG from "../../../layouts/ImagesOld2";

import {
  faLongArrowAltRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { CtaStripe } from "../../Services/ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import he from "he";
import IndustyAbout from "../../../components/IndustyAbout";
import InnerServices from "../../../components/InnerServices";
import IndustryBanner from "../../../components/IndustryBanner";
const RetailCyber = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    mainServicesData,
    benefitsData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 18 && i.page_id === 70),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 18 && i.page_id === 70),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const aboutTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 156),
    [pagesData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 157),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 18 && i.page_id === 70),
    [mainServicesData]
  );

  const benefitsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 158),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 18 && i.page_id === 70),
    [benefitsData]
  );

  if (!aboutTitleDes || !benefitsTitleDes) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/*-------------- banner section --------------*/}
      {/* {first ? (
        <section
          id="retail-indus-main-banner"
          className="retail-indus-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="retail-indus-main-bet-content retail-indus-main-banner-content retail-cyber-head-outline retail-indus-main-banner-content retail-cyber-head-outline-cyber">
              <h3
                data-aos="zoom-in"
                className="retail-indus-main-banner-head text-white"
              >
                {first.title}
              </h3>
              <p
                className="text-white"
                dangerouslySetInnerHTML={{
                  __html: he.decode(first.description),
                }}
              ></p>
            </div>
            <div className="indus-retail-banner-main-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-ind-retail">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null} */}

      <IndustryBanner industyBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/retail"}
        pageTitle={"Retail"}
        currentPage={"Cyber Security "}
      />

      {/* ----------retail managed it retail page , retail managed it services section =============== */}
      <section id="retailmis-managed-it-support">
        <div className="container ">
          <div className="retailmis-managed-it-support-content">
            <h2
              className="indus-main-retail-section-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(aboutTitleDes.Heading),
              }}
            ></h2>
            <p
              dangerouslySetInnerHTML={{
                __html: he.decode(aboutTitleDes.description),
              }}
            ></p>
          </div>
        </div>
      </section>

      <IndustyAbout industryAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* -------- why beno support  section start -------- */}
      <section id="services">
        <div className="container">
          <div>
            <h2
              className="indus-main-retail-section-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTitleDes.Heading),
              }}
            ></h2>
          </div>
          <div className="row mt-4">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div
                    id="retail-ind-capabilities-main"
                    data-aos="fade-up-left"
                    className="col-lg-4 col-md-4 col-sm-12"
                    key={i.id}
                  >
                    <div className="retail-ind-inner-area-customer-main">
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>

                      <div className="retail-main-indus-serve-small-box">
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ---------------- Bpo service latest one blog section  ----------- */}

      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends in business process automation with our
            dynamic insights. Learn about the most recent developments in the
            field, as well as successful stories and best practices, to enable
            your company to prosper in the digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.BpoBlogLatest}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Business Outsourcing Makes Businesses More Resilient for
                Long-Term Growth
              </h2>
              <p className="recent-blogs-slider-desc">
                Businesses that are resilient can navigate setbacks adeptly,
                preserving operations and reputations through swift adaptation.
                The key lies in agile responses, with digital tools playing an
                increasingly crucial role in fostering flexibility. Despite
                economic uncertainty, most IT budgets are on the rise, as
                indicated by 80% planning increased spending, while only 11%
                anticipate cuts. This resilience is attributed to the strategic
                use of resources and technology, reinforcing the vital
                connection between adaptability, digitalization, and sustained
                business success.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Data Governance Matters: How to Keep Your Data Clean
                            and Accurate?
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Data governance enhances data quality, reliability,
                          and security, ensuring accurate decision-making and
                          service delivery...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RetailCyber;

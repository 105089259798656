import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import he from "he";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  faArrowRight,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import "slick-carousel/slick/slick-theme.css";
import IMG from "../layouts/ImagesOld2";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import BlogContext from "../context/blogContext";

const CountUpAnimation = ({ initialValue, targetValue, text }) => {
  const [count, setCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const duration = 3000;
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsCounting(true);
          } else {
            setIsCounting(false);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let startValue = initialValue;
    let frame;
    const interval = Math.floor(duration / (targetValue - initialValue));

    const startCounting = () => {
      frame = requestAnimationFrame(() => {
        startValue += 1;
        setCount(startValue);
        if (startValue < targetValue) {
          startCounting();
        }
      });
    };

    if (isCounting) {
      startCounting();
    } else {
      cancelAnimationFrame(frame);
    }

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [isCounting, targetValue, initialValue, duration]);

  return (
    <div className="our-experience-inner-circle" ref={counterRef}>
      <h4>{count}+</h4>
      <p>{text}</p>
    </div>
  );
};

const Home = () => {
  const {
    blogData,
    headerFooterData,
    homeAboutData,
    statisticsData,
    featureImages,
    bannerData,
    pagesData,
    techLogoData,
    mainServicesData,
    serviceIndustryData,
  } = useContext(BlogContext);
  // Only run once on component mount
  //--------------  Testimonials slider js ----------------//
  const Testimonials = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 6000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    pauseOnHover: false,
  };

  const partners = {
    dots: false,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const excellence = {
    dots: false,
    infinite: true,
    speed: 700,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // ------------  blog slider js ------------//

  const homeBlog = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // ---------- number counter code--------

  // ---------- number counter code end --------
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // ----------- contact form data ---------- //

  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const { first_name, email, phone, message } = formData;

  const isNameValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(first_name);
  };
  const isEmailValid = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isMobileNumberValid = () => {
    const phonePattern = /^\d{1,14}$/;
    return phonePattern.test(phone);
  };
  const handleChange = (e) => {
    if (e.target.name === "phone" && !/^\d*$/.test(e.target.value)) {
      return; // Ignore non-numeric characters
    }

    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "https://admin.benosupport.com/api/contact-add";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    try {
      setLoading(true);
      const response = await fetch(url, options);
      const responseData = await response.json();

      setFormData({
        first_name: "",
        email: "",
        phone: "",
        message: "",
      });
      setMsg("Message Sent Successfully Thanks for Contact Us.");
      setTimeout(() => {
        setLoading(false);
        setMsg(null);
      }, 2500);
    } catch (error) {
      setMsg("Failed to send message. Please try again later.");
      setTimeout(() => {
        setLoading(false);
        setMsg(null);
      }, 2500);
    }
  };

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 14),
    [bannerData]
  );

  const ourExp = useMemo(
    () => pagesData.filter((i) => i.id === 3),
    [pagesData]
  );

  const [ourExpData] = ourExp;

  const paltformAndTech = useMemo(
    () => pagesData.filter((i) => i.id === 2),
    [pagesData]
  );

  const [paltformAndTechData] = paltformAndTech;

  const certification = useMemo(
    () => pagesData.filter((i) => i.id === 1),
    [pagesData]
  );

  const [certificationData] = certification;

  const leaders = useMemo(
    () => pagesData.filter((i) => i.id === 4),
    [pagesData]
  );

  const [leadersData] = leaders;

  const areaExcell = useMemo(
    () => pagesData.filter((i) => i.id === 5),
    [pagesData]
  );

  const [areaExcellData] = areaExcell;

  const featureClient = useMemo(
    () => pagesData.filter((i) => i.id === 6),
    [pagesData]
  );

  const [featureClientData] = featureClient;

  const whatThink = useMemo(
    () => pagesData.filter((i) => i.id === 7),
    [pagesData]
  );

  const [whatThinkData] = whatThink;

  const getTouch = useMemo(
    () => pagesData.filter((i) => i.id === 8),
    [pagesData]
  );

  const [getTouchData] = getTouch;

  const platformTechnologyFilter = useMemo(
    () => techLogoData.filter((i) => i.tcategory === "platforms-technologies"),
    [techLogoData]
  );

  const certificateAndPsrtnersLogo = useMemo(
    () => techLogoData.filter((i) => i.tcategory === "certifications-partners"),
    [techLogoData]
  );

  const ourClientLogo = useMemo(
    () => techLogoData.filter((i) => i.tcategory === "our-clients"),
    [techLogoData]
  );

  const ourExpTop = useMemo(
    () => pagesData.filter((i) => i.id === 23),
    [pagesData]
  );

  const [ourExpTopHead] = ourExpTop;

  // home statistics

  const statisticsHome = useMemo(
    () =>
      statisticsData.filter((i) => i.category_id === 14 && i.page_id === 105),
    [statisticsData]
  );

  const homeAboutExp = useMemo(
    () => statisticsData.find((i) => i.id === 8),
    [statisticsData]
  );

  const areaExcellence = useMemo(
    () => serviceIndustryData.filter((i) => i.category_id === 14),
    [serviceIndustryData]
  );

  const feature = useMemo(
    () => featureImages.find((i) => i.section_id === 8 && i.page_id === 105),
    [featureImages]
  );

  if (
    !ourExpData ||
    !paltformAndTechData ||
    !certificationData ||
    !leadersData ||
    !areaExcellData ||
    !featureClientData ||
    !whatThinkData ||
    !getTouchData ||
    !platformTechnologyFilter ||
    !certificateAndPsrtnersLogo ||
    !ourClientLogo ||
    !ourExpTopHead ||
    !homeAboutData ||
    !homeAboutExp ||
    !areaExcellence ||
    !feature
  )
    return null;

  return (
    <>
      <section className="home-banner-menu">
        <Slider {...Testimonials}>
          {bpoBanner.length > 0 &&
            bpoBanner.slice(-3).map((i) => (
              <section className="hmg-banner-content" key={i.id}>
                <div
                  className="hmg-banner"
                  style={{
                    backgroundImage: `url(${i.banner_img})`,
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <div
                        className="col-lg-7 col-md-10 col-sm-12"
                        id="hmg-banner-card-beno"
                      >
                        <div className="inrs-hmg">
                          <h2 className="heading">{i.title}</h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: he.decode(i.description),
                            }}
                          ></p>
                          <Link
                            to="/software-services"
                            className="btn theame-btn"
                          >
                            {i.btn_first}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ))}
        </Slider>
      </section>

      <section id="home-about-beno" className="home-about-beno">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-4 col-lg-4 col-md-4 col-sm-12 home-about-beno-left"
              style={{ backgroundImage: `url(${homeAboutData.beno_img})` }}
            >
              <div className="home-about-beno-left-inner-img">
                <img src={IMG.HomeBgDot} data-aos="fade-right" alt="img" />
              </div>
              <div className="home-about-beno-left-inner-box col-sm-12">
                <h3>{homeAboutExp.number}</h3>
                <p>{homeAboutExp.title}</p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
              <div className="home-about-beno-right">
                <Link to="/about-beno">{leadersData.pre_heading}</Link>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: he.decode(homeAboutData.title),
                  }}
                ></h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(homeAboutData.description),
                  }}
                ></p>
                <div className="home-about-beno-right-inner">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 col-lg-6 col-sm-12">
                      {homeAboutData.add_point.slice(0, 3).map((i) => (
                        <p key={i.id}>
                          <img src={IMG.Bullet} alt="" /> &nbsp;
                          {i}
                        </p>
                      ))}
                    </div>
                    <div className="col-xl-6 col-md-6 col-lg-6 col-sm-12">
                      {homeAboutData.add_point.slice(3, 6).map((i) => (
                        <p key={i.id}>
                          <img src={IMG.Bullet} alt="" /> &nbsp; {i}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="services-section-h space excellence"
        id="certificates"
      >
        <div className="container">
          <div className="subheading text-center">
            <h2
              className="innovations-home-beno"
              dangerouslySetInnerHTML={{
                __html: he.decode(areaExcellData.Heading),
              }}
            ></h2>
            <p
              className="industries_content"
              dangerouslySetInnerHTML={{
                __html: he.decode(areaExcellData.description),
              }}
            ></p>
          </div>
          <Slider {...excellence} style={{ padding: "0 1.5rem" }}>
            {areaExcellence.map((i) => (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="excellence-slider"
                  style={{ backgroundImage: `url(${i.industry_img})` }}
                >
                  <h4
                    dangerouslySetInnerHTML={{ __html: he.decode(i.title) }}
                  ></h4>

                  <Link to={i.industry_link}>Learn More</Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section id="our-experience" className="our-experience">
        <div className="fluid-container">
          <div className="our-experience-main-content">
            <h5 className="text-center">{ourExpData.pre_heading}</h5>
            <h4
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(ourExpData.Heading),
              }}
            ></h4>
            <p
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(ourExpData.description),
              }}
            ></p>
          </div>
          <div className="row our-experience-circle-container">
            {statisticsHome &&
              statisticsHome.map((i) => (
                <CountUpAnimation
                  key={i.id}
                  initialValue={0}
                  targetValue={i.number}
                  text={i.title}
                />
              ))}
          </div>
        </div>
      </section>

      <section id="any-question" className="any-question">
        <div className="container any-question-inner">
          <p>Feel free to reach us!</p>
          <Link to={`tel:${headerFooterData.mobile_number}`}>
            {" "}
            Schedule a Free Call
          </Link>
        </div>
      </section>

      <section>
        <div className="industry-new-trends-grid">
          <div className="row">
            {mainServicesData
              .filter((i) => i.category_id === 14 && i.page_id === 105)
              .map((item) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-12 industry-new-trends-content-main"
                  style={{ backgroundImage: `url(${item.service_img})` }}
                  key={item.id}
                >
                  <div className="container">
                    <h3>{item.title}</h3>
                    <div className="on-hover-data-indus-trend">
                      <h4>{item.title}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(item.description),
                        }}
                      ></p>
                      <Link to={item.page_link}>Read More</Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section
        id="platform"
        style={{ backgroundImage: `url(${IMG.HomePlatformBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6- col-md-6 col-sm-12">
              <div className="platform-section-left">
                <h5
                  dangerouslySetInnerHTML={{
                    __html: he.decode(paltformAndTechData.Heading),
                  }}
                ></h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(paltformAndTechData.description),
                  }}
                ></p>

                <Link to="/it">
                  Explore All Technologies &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
            <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12 platform-section-right">
              <div class="platform-tech-marquee-container">
                <div class="platform-tech-marquee">
                  {platformTechnologyFilter &&
                    platformTechnologyFilter.length > 0 &&
                    platformTechnologyFilter.slice(0, 4).map((i) => (
                      <div className="ptm-inr" key={i.id}>
                        <img src={i.tlogo} alt={i.tlogo_alt} />
                        <div>
                          <h5>{i.tname}</h5>
                          <p>{i.special_txt}</p>
                        </div>
                      </div>
                    ))}
                </div>

                {/* shadow */}
                <div class="platform-tech-marquee">
                  {platformTechnologyFilter &&
                    platformTechnologyFilter.length > 0 &&
                    platformTechnologyFilter.slice(0, 4).map((i) => (
                      <div className="ptm-inr" key={i.id}>
                        <img src={i.tlogo} alt={i.tlogo_alt} />
                        <div>
                          <h5>{i.tname}</h5>
                          <p>{i.special_txt}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div class="platform-tech-marquee-container">
                <div class="platform-tech-marquee2">
                  {platformTechnologyFilter &&
                    platformTechnologyFilter.length > 0 &&
                    platformTechnologyFilter.slice(4, 8).map((i) => (
                      <div className="ptm-inr" key={i.id}>
                        <img src={i.tlogo} alt={i.tlogo_alt} />
                        <div>
                          <h5>{i.tname}</h5>
                          <p>{i.special_txt}</p>
                        </div>
                      </div>
                    ))}
                </div>

                {/* shadow  */}
                <div class="platform-tech-marquee2">
                  {platformTechnologyFilter &&
                    platformTechnologyFilter.length > 0 &&
                    platformTechnologyFilter.slice(4, 8).map((i) => (
                      <div className="ptm-inr" key={i.id}>
                        <img src={i.tlogo} alt={i.tlogo_alt} />
                        <div>
                          <h5>{i.tname}</h5>
                          <p>{i.special_txt}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div class="platform-tech-marquee-container">
                <div class="platform-tech-marquee">
                  {platformTechnologyFilter &&
                    platformTechnologyFilter.length > 0 &&
                    platformTechnologyFilter.slice(8, 12).map((i) => (
                      <div className="ptm-inr" key={i.id}>
                        <img src={i.tlogo} alt={i.tlogo_alt} />
                        <div>
                          <h5>{i.tname}</h5>
                          <p>{i.special_txt}</p>
                        </div>
                      </div>
                    ))}
                </div>

                {/* shadow  */}
                <div class="platform-tech-marquee">
                  {platformTechnologyFilter &&
                    platformTechnologyFilter.length > 0 &&
                    platformTechnologyFilter.slice(8, 12).map((i) => (
                      <div className="ptm-inr" key={i.id}>
                        <img src={i.tlogo} alt={i.tlogo_alt} />
                        <div>
                          <h5>{i.tname}</h5>
                          <p>{i.special_txt}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="services-section-h space certificates "
        id="certificates"
        style={{ backgroundImage: `url(${IMG.HomeCertificateBg})` }}
      >
        <div className="container">
          <div className="subheading text-center">
            <h2 className="innovations-home-beno">
              <h2
                dangerouslySetInnerHTML={{
                  __html: he.decode(certificationData.Heading),
                }}
              ></h2>
            </h2>
            <p
              className="industries_content"
              dangerouslySetInnerHTML={{
                __html: he.decode(certificationData.description),
              }}
            ></p>
          </div>
          <Slider {...partners}>
            {certificateAndPsrtnersLogo &&
              certificateAndPsrtnersLogo.length > 0 &&
              certificateAndPsrtnersLogo.map((i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                  key={i.id}
                >
                  <div className="single_blog-certificates mb-4">
                    <div className="single_blog_thumb_certificates">
                      <img src={i.tlogo} alt={i.tlogo_alt} />
                    </div>
                  </div>
                </div>
              ))}
            {certificateAndPsrtnersLogo &&
              certificateAndPsrtnersLogo.length > 0 &&
              certificateAndPsrtnersLogo.map((i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                  key={i.id}
                >
                  <div className="single_blog-certificates mb-4">
                    <div className="single_blog_thumb_certificates">
                      <img src={i.tlogo} alt={i.tlogo_alt} />
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </section>

      <section className="inner-Platforms" id="our-features">
        <div className="fluid-container">
          <div>
            <div className="">
              <div className="inner-plat text-center">
                <h2
                  className="technologies-heading"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(featureClientData.Heading),
                  }}
                ></h2>
                <p
                  className="technologies-content"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(featureClientData.description),
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div class="platform-tech-marquee-container mt-4">
            <div class="platform-tech-marquee3">
              {ourClientLogo &&
                ourClientLogo.length > 0 &&
                ourClientLogo.map((i) => (
                  <img src={i.tlogo} alt={i.tlogo_alt} />
                ))}
            </div>
            <div class="platform-tech-marquee3">
              {ourClientLogo &&
                ourClientLogo.length > 0 &&
                ourClientLogo.map((i) => (
                  <img src={i.tlogo} alt={i.tlogo_alt} />
                ))}
            </div>
          </div>
        </div>
      </section>

      <section className="services-section-h space">
        <div className="shape-bg-12">
          <img src={IMG.Shape121} alt="img" />
        </div>
        <div className="container">
          <div className="subheading text-center">
            <h2 className="innovations-home-beno">
              <h2
                dangerouslySetInnerHTML={{
                  __html: he.decode(whatThinkData.Heading),
                }}
              ></h2>
            </h2>
            <p
              className="industries_content"
              dangerouslySetInnerHTML={{
                __html: he.decode(whatThinkData.description),
              }}
            ></p>
          </div>
          <Slider {...homeBlog}>
            {blogData &&
              blogData.length > 0 &&
              blogData.slice(0, 5).map((i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                  key={i.id}
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to={`/blog/${i.blog_slug}`}>
                        <img src={i.blog_img} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a>{i.blog_date}</a>
                        <span className="meta-date pl-3">
                          {i.blog_category}
                        </span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to={`/blog/${i.blog_slug}`}>{i.title}</Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(
                              i.description.split(" ").slice(0, 25).join(" ") +
                                "..."
                            ),
                          }}
                        ></p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to={`/blog/${i.blog_slug}`}>
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </section>

      <section
        id="home-contact-form-beno"
        className="inr-started"
        style={{ backgroundImage: `url(${IMG.HomeContgactBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
              <div className="contact-form-left">
                <div className="inner-start">
                  <ul>
                    <li>{ourExpTopHead.pre_heading}</li>
                  </ul>
                  <h2
                    className="heading"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(ourExpTopHead.Heading),
                    }}
                  ></h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: he.decode(ourExpTopHead.description),
                    }}
                  ></p>
                </div>
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                    <div className="contact-form-left-main-head">
                      <h4>Location:</h4>
                      <p className="mt-2">
                        {headerFooterData.corporate_location}
                      </p>
                    </div>
                    <div className="contact-form-left-main-head contact-form-left-main-head-unique mt-2">
                      <h4>Enquiry:</h4>
                      <p className="mt-3">
                        {" "}
                        <Link to={`mailto:${headerFooterData.email}`}>
                          <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
                          {headerFooterData.email}
                        </Link>
                      </p>
                      <p>
                        <Link to={`tel:${headerFooterData.mobile_number}`}>
                          <FontAwesomeIcon icon={faPhone} /> &nbsp;
                          {headerFooterData.mobile_number}
                        </Link>
                      </p>
                      <p>
                        <a href={headerFooterData.linkedin_url} target="_blank">
                          <FontAwesomeIcon icon={faLinkedinIn} /> &nbsp; Beno
                          Support Technologies
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div
                          className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3"
                          data-aos="fade-up"
                        >
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            name="first_name"
                            value={first_name}
                            onChange={handleChange}
                            required
                          />
                          {first_name.trim().length > 0 && !isNameValid() && (
                            <span style={{ fontSize: "12px", color: "red" }}>
                              name must contain only letters.
                            </span>
                          )}
                        </div>
                        <div
                          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"
                          data-aos="fade-up"
                        >
                          <input
                            type="email"
                            className="form-control"
                            placeholder="E-Mail"
                            name="email"
                            aria-describedby="emailHelp"
                            value={email}
                            onChange={handleChange}
                          />
                          {email.trim().length > 0 && !isEmailValid() && (
                            <span style={{ fontSize: "12px", color: "red" }}>
                              please enter a valid email.
                            </span>
                          )}
                        </div>
                        <div
                          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3 "
                          data-aos="fade-up"
                        >
                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Phone"
                            name="phone"
                            value={phone}
                            onChange={handleChange}
                            required
                          />
                          {phone.trim().length > 0 &&
                            !isMobileNumberValid() && (
                              <span style={{ fontSize: "12px", color: "red" }}>
                                mobile number must be at least 10 digits.
                              </span>
                            )}
                        </div>
                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          data-aos="fade-up"
                        >
                          <div className="mb-3">
                            <textarea
                              className="form-control"
                              placeholder="Write your message"
                              rows="6"
                              id="home-contact-textarea"
                              name="message"
                              value={message}
                              onChange={handleChange}
                              pattern="^\S.*$"
                              title="Leading space is not allowed."
                              required
                            ></textarea>
                          </div>
                        </div>
                        <div
                          className="col-lg-12 col-md-12 col-sm-12"
                          data-aos="fade-up"
                        >
                          <div className="button-right">
                            <button type="submit" className="btn theame-btn">
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="home-notification-msg">
                    {msg && <span className="text-center">{msg}</span>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
              <div className="contact-form-right">
                <img src={feature.single_pic} alt={feature.pic_alt} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

import React, { useState } from "react";
import he from "he";

const IndustyAbout = ({ industryAboutData }) => {
  const charLimit = 500;
  const decodedDescription = industryAboutData?.description
    ? he.decode(industryAboutData.description)
    : "";
  const [isReadMore, setIsReadMore] = useState(false);
  const shouldTruncate = decodedDescription.length > charLimit;
  const displayedDescription = isReadMore
    ? decodedDescription
    : decodedDescription.substring(0, charLimit) +
      (shouldTruncate ? "..." : "");

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (!industryAboutData) return null;
  return (
    <section className="bfsi-about-sec" id="about">
      <div className="container">
        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <h2
              className="bfsi-section-heading main-page-bfsi-about-eff"
              dangerouslySetInnerHTML={{
                __html: he.decode(industryAboutData.title),
              }}
            ></h2>
            <p
              className="bfsi-section-sub-heading"
              dangerouslySetInnerHTML={{ __html: displayedDescription }}
            ></p>

            {shouldTruncate && (
              <button onClick={toggleReadMore} id="bfsi-read-btn-about">
                {isReadMore ? "Read Less" : "Read More"}
              </button>
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
            <div>
              <img
                className="bfsi-sub-img-about"
                src={industryAboutData.about_img}
                alt={industryAboutData.about_img_alt}
              />
              <span className="bfsi-sub-triangle-down-right"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustyAbout;

import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltRight,
  faArrowRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import BlogContext from "../../../context/blogContext";
import he from "he";
import MainAbout from "../../../components/MainAbout";
import IndustryServe from "../../../components/IndustryServe";

const CyberSecurity = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".nag-page-index-cyber-security"
    );
    const stickyDiv = "sticky-security";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [visibleCount, setVisibleCount] = useState(3);
  const [areCardsVisibleServiceCyber, setAreCardsVisibleServiceCyber] =
    useState(false);

  const toggleCardsServiceCyber = () => {
    setAreCardsVisibleServiceCyber(!areCardsVisibleServiceCyber);
    setVisibleCount(areCardsVisibleServiceCyber ? 3 : filteredServices.length);
  };

  const {
    bannerData,
    mainAboutData,
    serviceIndustryData,
    pagesData,
    mainServicesData,
    benefitsData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 10 && i.page_id === 29),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 10 && i.page_id === 29),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const industryWeServe = useMemo(
    () => pagesData.filter((i) => i.id === 15),
    [pagesData]
  );

  const [industryWeServeData] = industryWeServe;

  const serviceIndustry = useMemo(
    () =>
      serviceIndustryData.filter(
        (i) => i.category_id === 10 && i.page_id === 29
      ),
    [serviceIndustryData]
  );

  const filteredServices = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 10 && i.page_id === 29),
    [mainServicesData]
  );

  const whatWeDoTop = useMemo(
    () => pagesData.filter((i) => i.id === 36),
    [pagesData]
  );

  const [whatWeDoTopHead] = whatWeDoTop;

  const benefitsTop = useMemo(
    () => pagesData.filter((i) => i.id === 37),
    [pagesData]
  );

  const [benefitsTopHead] = benefitsTop;

  const benefitsFilter = useMemo(() =>
    benefitsData.filter(
      (i) => i.category_id === 10 && i.page_id === 29,
      [benefitsData]
    )
  );

  console.log(benefitsFilter.length);

  if (
    !filteredServices ||
    !whatWeDoTopHead ||
    !benefitsTopHead ||
    !benefitsFilter
  )
    return null;

  return (
    <>
      {/* ---------------- banner section --------------- */}
      {first && (
        <section
          className="cyber-security-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-10 col-sm-12">
                <div className="cyber-security-div-banner-title">
                  <h2
                    className="cyber-security-banner-heading"
                    data-aos="fade-down"
                  >
                    {first.title}
                  </h2>
                  <p
                    className="cyber-security-banner-para"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>

                <div className="row" id="play-button-cyber-security">
                  <div className="col-lg-9  col-sm-12 cyber-security-know-how-btn mt-3">
                    <div className="cyber-security-banner-know-how">
                      <a href="#cyber-benefits">
                        {first.btn_first}{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* --------------- page index section ------------- */}

      <section className="nag-page-index-cyber-security">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar-index-cyber-security">
                <ul className="nav-index-cyber-security">
                  <li>
                    <a
                      className={`nav-link-index-cyber-security ${
                        current === "cyber-about" ? "active" : ""
                      }`}
                      href="#cyber-about"
                      aria-label="About"
                    >
                      Expertise
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-cyber-security ${
                        current === "cyber-industry" ? "active" : ""
                      }`}
                      href="#cyber-industry"
                      aria-label="Industry"
                    >
                      Industry
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-cyber-security ${
                        current === "cyber-benefits" ? "active" : ""
                      }`}
                      href="#cyber-benefits"
                      aria-label="BENEFITS"
                    >
                      Benefits
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-cyber-security ${
                        current === "cyber-services" ? "active" : ""
                      }`}
                      href="#cyber-services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-cyber-security ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link className="text-black" to="/">
            Home{" "}
          </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link className="text-black" to={"/cyber-security"}>
            {" "}
            Cyber Security{" "}
          </Link>
        </div>
      </section>

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* --------- industries  section ----------- */}
      <IndustryServe
        industryWeServeData={industryWeServeData}
        serviceIndustry={serviceIndustry}
      />

      {/* -------------BENEFITS section ------------- */}
      <section id="cyber-benefits" className="cyber-benefits-sec">
        <div className="container">
          <h5>{benefitsTopHead.pre_heading}</h5>
          <h2
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTopHead.Heading),
            }}
          ></h2>
          <div className="row cyber-benefits-content">
            {benefitsFilter.map((i) => (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                key={i.id}
                dangerouslySetInnerHTML={{ __html: he.decode(i.description) }}
              ></div>
            ))}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ----------- service section ------------------ */}
      <section id="cyber-services">
        <div className="container">
          <h2
            className="cyber-security-head-main-title text-center"
            id="cyber-security-service-head-effect"
          >
            {whatWeDoTopHead.Heading}
          </h2>
          <p
            className="cyber-security-head-main-para text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(whatWeDoTopHead.description),
            }}
          ></p>
          <div className="row d-flex justify-content-center">
            {filteredServices.slice(0, visibleCount).map((i) => (
              <div
                className="col-lg-4 col-md-4 col-sm-12 cyber-services-div-main"
                data-aos="fade-up"
                key={i.id}
              >
                <div className="card cyber-security-service-crd-main">
                  <div className="cyber-security-service-crd-img-main">
                    <Link to={i.page_link}>
                      <img
                        src={i.service_img}
                        alt={i.service_img_alt}
                        className="card-img-top"
                      />
                    </Link>
                  </div>
                  <div className="card-body">
                    <Link to={i.page_link}>
                      <h3>{i.title}</h3>
                    </Link>
                    <p
                      className="card-text"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                    <div className="cyber-security-service-small-box-down">
                      <Link to={i.page_link}>
                        <FontAwesomeIcon icon={faArrowRightLong} />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {filteredServices.length > 3 && (
            <div className="text-center btn-div-cyber-security-it-main">
              <button
                onClick={toggleCardsServiceCyber}
                className="load-more-cyber-security-it-btn"
              >
                {!areCardsVisibleServiceCyber ? "Load More" : "Load Less"}
              </button>
            </div>
          )}
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Use our in-depth blogs to learn about the most recent developments
            in business process automation. We've discussed the most recent
            industry advancements and technological innovations to support the
            growth of your business.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.CyberBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <Link to="/blog-details">
                <h2 className="recent-blogs-slider-title">
                  A Comprehensive Guide to Effectively Testing Your Network
                  Security Measures
                </h2>
              </Link>
              <p className="recent-blogs-slider-desc">
                As cybersecurity threats continue to rise, businesses face
                increasing pressure to strengthen their defenses. While many
                rely on automated security testing tools, incorporating manual
                testing is essential for comprehensive protection. Methods such
                as risk identification, ongoing surveillance, and network
                scanning help identify vulnerabilities and ensure robust
                security measures. By prioritizing security testing,
                organizations can safeguard their systems and mitigate the risk
                of cyber-attacks and data breaches.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            How can you integrate Artificial Intelligence into
                            the core of your cyber security strategy?
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Embedding AI solutions in your cybersecurity framework
                          demands a comprehensive strategy, embracing adoption,
                          feasibility, and long-term benefits.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Enhancing Cybersecurity Resilience: Practical
                            Simulations of Real-World Cyber Attacks
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Numerous methods exist for conducting cyber resilience
                          testing, yet tabletop exercises for cyber attacks
                          emerge as an invaluable resource.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Why Does Business Security Relies on Data
                            Encryption?
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          In the digital age, with the rising prevalence of
                          cybercrime, every business proprietor must defend
                          their company against malicious cyber and ransomware
                          attacks
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            How can you integrate Artificial Intelligence into
                            the core of your cyber security strategy?
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Embedding AI solutions in your cybersecurity framework
                          demands a comprehensive strategy, embracing adoption,
                          feasibility, and long-term benefits.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Enhancing Cybersecurity Resilience: Practical
                            Simulations of Real-World Cyber Attacks
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Numerous methods exist for conducting cyber resilience
                          testing, yet tabletop exercises for cyber attacks
                          emerge as an invaluable resource.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CyberSecurity;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import he from "he";

const IndusBenoProvides = ({ indusHeadDesc, benoProvides }) => {
  const [visibleItems, setVisibleItems] = useState(4); // Initial number of items to show

  if (!indusHeadDesc) return null;

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4);
  };

  const handleLoadLess = () => {
    setVisibleItems(4); // Reset to initial number of items
  };

  return (
    <section id="provides">
      <div className="container">
        <div>
          <h2
            className="bfsi-section-heading text-center provides-main-page-efft"
            dangerouslySetInnerHTML={{
              __html: he.decode(indusHeadDesc.Heading),
            }}
          ></h2>
          <p
            className="bfsi-section-sub-heading text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(indusHeadDesc.description),
            }}
          ></p>
        </div>
        <div className="row d-flex justify-content-center mt-4 pt-4">
          {benoProvides &&
            benoProvides.length > 0 &&
            benoProvides.slice(0, visibleItems).map((i) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 service-provides-beno-bfsi"
                data-aos="fade-up"
                key={i.id}
              >
                <div className="inner-box-service-provide">
                  <div className="image-box-service-provide">
                    <img src={i.service_img} alt={i.service_img_alt} />
                    <div className="caption-service-provide">{i.title}</div>
                    <div className="overlay-box-service-provide">
                      <Link to={i.page_link}>
                        <h4>{i.title}</h4>
                      </Link>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="text-center btn-div-bfsi-main">
          {benoProvides.length > visibleItems ? (
            <button
              className="load-more-bfsi-main-btn"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          ) : (
            visibleItems > 4 && (
              <button
                className="load-more-bfsi-main-btn"
                onClick={handleLoadLess}
              >
                Load Less
              </button>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default IndusBenoProvides;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import IMG from "../../layouts/ImagesOld2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";
import BlogContext from "../../context/blogContext";
import he from "he";
import Loader from "../../components/Loader";
import "../../assets/css/layout/ExploreCareer.css";
const TechnologyTrends = () => {
  const [page, setPage] = useState(1);
  const { blogData, loading, bannerData } = useContext(BlogContext);
  const [keyword, setKeyword] = useState("");
  const [selectYear, setYear] = useState("All");
  const [selectedIndustry, setIndustry] = useState("ind");
  const [selectedCategory, setCategory] = useState("cat");
  const [searchResult, setSearchResult] = useState([]);

  const clearFilter = () => {
    setKeyword("");
    setYear("All");
    setCategory("cat");
    setIndustry("ind");
  };

  useEffect(() => {
    var filtered = blogData;

    if (keyword !== "") {
      const keywords = keyword.toLowerCase().split(" ").filter(Boolean);
      filtered = filtered.filter((item) =>
        keywords.every((key) => item.title.toLowerCase().includes(key))
      );
    }

    if (selectYear !== "All") {
      filtered = filtered.filter(
        (item) => item.blog_date.split("-")[0] === selectYear
      );
    }

    if (selectedCategory !== "cat") {
      filtered = filtered.filter(
        (item) => item.category_id === parseInt(selectedCategory)
      );
    }

    if (selectedIndustry !== "ind") {
      filtered = filtered.filter(
        (item) => item.category_id.toString() === selectedIndustry
      );
    }

    setSearchResult(filtered);
  }, [blogData, keyword, selectYear, selectedCategory, selectedIndustry]);

  const selectPageHandler = (selectedPage) => {
    const totalPages = Math.ceil(searchResult.length / 8);
    if (
      selectedPage >= 1 &&
      selectedPage <= totalPages &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const generatePagination = (currentPage, totalPages) => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 6;

    if (totalPages <= maxPageNumbersToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage > totalPages - 3) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 1);
        pageNumbers.push(currentPage);
        pageNumbers.push(currentPage + 1);
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 29 && i.page_id === 111),
    [bannerData]
  );
  const [first] = bpoBanner;

  return (
    <>
      {/* ------------ banner section -------- */}
      {first ? (
        <section
          className="explore-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-12">
                <div className="banner-content-explore">
                  <h2 className="text-white">{first.title}</h2>
                  <p
                    className="text-white"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {loading && <Loader />}

      {/* ------------ portfolio section -------- */}
      <section className="portfolio-ceo-sec" id="technology-explore-filter-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <form id="filter-techtrend">
                <div className="row">
                  <div
                    className="col-lg-6 col-md-6 col-sm-6"
                    id="technology-explore-filter"
                  >
                    <h3>Filter's</h3>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 text-end"
                    id="technology-explore-filter"
                  >
                    <button
                      className="technology-explore-filter-clear-serach"
                      type="reset"
                      onClick={() => clearFilter()}
                    >
                      Clear Filters
                    </button>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Search..."
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedCategory}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="cat">Services</option>
                      <option value="6">BPO</option>
                      <option value="7">Digital Transformation</option>
                      <option value="8">IT Consulting & Support</option>
                      <option value="9">Cloud</option>
                      <option value="10">Cyber Security</option>
                      <option value="11">Software</option>
                      <option value="12">HR & Talent</option>
                      <option value="13">Digital Experience</option>
                    </select>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedIndustry}
                      onChange={(e) => setIndustry(e.target.value)}
                    >
                      <option value="ind">Industries</option>
                      <option value="15">BFSI</option>
                      <option value="16">IT</option>
                      <option value="17">ITES</option>
                      <option value="18">Retail</option>
                      <option value="19">Education</option>
                      <option value="20">Logistics</option>
                      <option value="21">Technology</option>
                      <option value="22">Health Care</option>
                      <option value="23">E-Commerce</option>
                      <option value="24">Manufacturing</option>
                      <option value="25">Travel and Tourism</option>
                    </select>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectYear}
                      onChange={(e) => setYear(e.target.value)}
                    >
                      <option value="All">All Years</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="row d-flex justify-content-center">
                {searchResult && searchResult.length > 0 ? (
                  searchResult.slice(page * 8 - 8, page * 8).map((i) => (
                    <div
                      key={i.id}
                      className="col-lg-6 col-md-6 col-sm-12"
                      id="technology-trend-filter-data-col"
                    >
                      <div className="portfolio-ceo-main">
                        <div className="portfolio-ceo-img">
                          <Link to={`/blog/${i.blog_slug}`}>
                            <img src={i.blog_img} alt="img" />
                          </Link>
                        </div>
                        <div className="portfolio-ceo-content">
                          <Link to={`/blog/${i.blog_slug}`}>
                            <h3>{i.title}</h3>
                          </Link>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: he.decode(
                                i.description
                                  .split(" ")
                                  .slice(0, 60)
                                  .join(" ") + "..."
                              ),
                            }}
                          ></p>
                          <div className="row person-tech-trends mt-4">
                            <div className="col-2">
                              <img
                                src={
                                  i.profile
                                    ? i.profile
                                    : IMG.TechTrendBlogAvatar
                                }
                                alt="img"
                              />
                            </div>
                            <div className="col-10">
                              <h5>{i.name ? i.name : "Admin"}</h5>
                              <p>{i.blog_date}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <DataNotFound />
                )}

                {searchResult.length > 8 && (
                  <div className="container">
                    <div className="pagination">
                      <span
                        onClick={() => selectPageHandler(page - 1)}
                        className={page === 1 ? "disabled" : ""}
                      >
                        <FontAwesomeIcon icon={faBackward} />
                      </span>

                      {generatePagination(
                        page,
                        Math.ceil(searchResult.length / 8)
                      ).map((pageNumber, index) => (
                        <span
                          key={index}
                          className={
                            pageNumber === page ? "pagination_selected" : ""
                          }
                          onClick={() =>
                            pageNumber !== "..." &&
                            selectPageHandler(pageNumber)
                          }
                        >
                          {pageNumber}
                        </span>
                      ))}

                      <span
                        onClick={() => selectPageHandler(page + 1)}
                        className={
                          page === Math.ceil(searchResult.length / 8)
                            ? "disabled"
                            : ""
                        }
                      >
                        <FontAwesomeIcon icon={faForward} />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnologyTrends;

function DataNotFound() {
  return (
    <div className="container text-center tech-trends-data-not-found">
      <div className="row">
        <h3>Data Not Found</h3>
        <p>Please Fill Correct Information.</p>
      </div>
    </div>
  );
}

export { DataNotFound };

import React from "react";
import IMG from "../layouts/ImagesOld2";

const Sitemap = () => {
  return (
    <>
      <section
        className="contact-banner"
        style={{
          backgroundImage: `url(${IMG.SitemapBanner})`,
        }}
      >
        <div className="container">
          <div className="banner-content-contact terms-condition-content">
            <h2>Sitemap</h2>
          </div>
        </div>
      </section>

      <section className="service-sitemap-main">
        <div className="container">
          <h3>Services Category:</h3>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="sitemap-content-main">
            <h4>Business Process Outsourcing</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Sales & Customer Operations</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Talent & HR Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Insurance Services</p>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Finance & Accounting Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Health Care Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Marketing Operations Services</p>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Data Operations & Insight</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Banking & Financial Services</p>
              </div>
            </div>
          </div>

          <div className="sitemap-content-main">
            <h4>Digital Transformation</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Customer experience Transformation</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Human Capital Management</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Digital technology Integration</p>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Digital Workplace Enablement</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Business Process & ERP’s</p>
              </div>
            </div>
          </div>

          <div className="sitemap-content-main">
            <h4>Application Services</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Design Strategy</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Agile Transformation</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Application Transformation</p>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Quality Engineering</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>DevOps</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Application Lifecycle</p>
              </div>
            </div>
          </div>

          <div className="sitemap-content-main">
            <h4>Managed IT Support</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Managed Network & Infra Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Managed Security Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Managed IT Support</p>
              </div>
            </div>
          </div>

          <div className="sitemap-content-main">
            <h4>Cyber Security</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Identity and access Management</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Threat Detection and Response</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Security and Risk advisory</p>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Cloud & Infra Security</p>
              </div>
            </div>
          </div>

          <div className="sitemap-content-main">
            <h4>Cloud</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>App & Data migration Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Managed Cloud Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>DevOps</p>
              </div>
            </div>
          </div>

          <div className="sitemap-content-main">
            <h4>HR & Talent Services</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Talent Acquisition Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Contingent Staffing</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>HR & Talent Consulting</p>
              </div>
            </div>
          </div>

          <div className="sitemap-content-main">
            <h4>Digital Experience</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Content & Marketing Services</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>User Experience & Design</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Multi-cultural Marketing Strategies</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Website Transformation & Localization</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Virtual Experience Design</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-sitemap-main">
        <div className="container">
          <h3>Industries:</h3>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="sitemap-content-main">
            <h4>Business Process Outsourcing</h4>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>BFSI</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Travel & Tourism</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Health Care</p>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Technology</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>E-Commerce</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Retail</p>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Logistics</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>ITES</p>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Manufacturing</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>PSU's</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>Education</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                <p>IT</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ---------- sitemap contact section ---------- */}
      <section class="slip-box-questoin-ask" id="policy-contact-sec">
        <div class="container">
          <ul class="text-center">
            <li>
              <p>Still have any questions for us? Don’t Worry!</p>
            </li>
            <li>
              <a href="#">Contact Us</a>{" "}
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Sitemap;

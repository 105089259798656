import React, { useEffect, useState, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import he from "he";
import {
  faLongArrowAltRight,
  faArrowRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BlogContext from "../../../context/blogContext";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import IndustryServe from "../../../components/IndustryServe";
import IMG from "../../../layouts/ImagesOld2";

const DigitalTransformation = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          window.pageYOffset >= sectionTop - 150 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".nag-page-index-dgt");
    const stickyDiv = "sticky-dgt";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [visibleCount, setVisibleCount] = useState(3);
  const [areCardsVisibleDgt, setAreCardsVisibleDgt] = useState(false);

  const toggleCardsDgt = () => {
    setAreCardsVisibleDgt(!areCardsVisibleDgt);
    setVisibleCount(areCardsVisibleDgt ? 3 : filteredServices.length);
  };
  const {
    bannerData,
    blogData,
    mainAboutData,
    pagesData,
    serviceIndustryData,
    ourExpertiseData,
    mainServicesData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 7),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 7 && i.page_id === 8),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 7 && i.page_id === 8),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const industryWeServe = useMemo(
    () => pagesData.filter((i) => i.id === 13),
    [pagesData]
  );

  const [industryWeServeData] = industryWeServe;

  const ourExpTop = useMemo(
    () => pagesData.filter((i) => i.id === 20),
    [pagesData]
  );

  const [ourExpTopHead] = ourExpTop;

  const serviceIndustry = useMemo(
    () =>
      serviceIndustryData.filter((i) => i.category_id === 7 && i.page_id === 8),
    [serviceIndustryData]
  );

  const ourExpertiseFilter = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 7 && i.page_id === 8),
    [ourExpertiseData]
  );

  const filteredServices = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 7 && i.page_id === 8),
    [mainServicesData]
  );

  const whatWeDoTop = useMemo(
    () => pagesData.filter((i) => i.id === 34),
    [pagesData]
  );

  const [whatWeDoTopHead] = whatWeDoTop;
  if (
    !ourExpTopHead ||
    !ourExpertiseData ||
    !filteredServices ||
    !whatWeDoTopHead
  )
    return null;

  return (
    <>
      {/* ---------------- Digital transformation banner section ---------------------- */}
      {first && (
        <section
          id="dgt-banner"
          className="digital-transformation-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12 dgt-banner-front">
                <div className="dgt-div-banner-title">
                  <p className="dgt-banner-heading" data-aos="fade-down">
                    {first.title}
                  </p>
                </div>
                <div className="dgt-banner-para">
                  <p
                    className="dgt-banner-para"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>
                <div className="row" id="play-button-dgt">
                  <div className="col-lg-12  col-md-12  col-sm-12 dgt-know-how-btn">
                    <div className="dgt-banner-know-how">
                      <a href="#dgt-expertise">
                        {first.btn_first}{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* --------------- Digital transformation page index section ------------- */}
      <section className="nag-page-index-dgt">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar-index-dgt">
                <ul className="nav-index-dgt">
                  <li>
                    <a
                      className={`nav-link-index-dgt ${
                        current === "ai-business" ? "active" : ""
                      }`}
                      href="#ai-business"
                      aria-label="business"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-dgt ${
                        current === "dgt-expertise" ? "active" : ""
                      }`}
                      href="#dgt-expertise"
                      aria-label="Expertise"
                    >
                      Expertise
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-dgt ${
                        current === "dgt-services" ? "active" : ""
                      }`}
                      href="#dgt-services"
                      aria-label="Services"
                    >
                      Industry
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-dgt ${
                        current === "dgt-what-we-do" ? "active" : ""
                      }`}
                      href="#dgt-what-we-do"
                      aria-label="what-we-do"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-dgt ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/digital-transformation"}> Digital Transformation </Link>
        </div>
      </section>
      {/*-------------digital transformation "AI for business" --------------------*/}

      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/*-------------- digital transformation "OUR EXPERTIES section"  -----------*/}
      <section
        id="dgt-expertise"
        className="inr-Digital-Our-Expertise"
        style={{
          backgroundImage: `url(${IMG.BpoUnderBack})`,
        }}
      >
        <div className="container">
          <div className="inner-dgt-section-heading text-center dgt-our-exper-head-bg">
            <h2
              dangerouslySetInnerHTML={{
                __html: he.decode(ourExpTopHead.Heading),
              }}
            ></h2>
            <p
              dangerouslySetInnerHTML={{
                __html: he.decode(ourExpTopHead.description),
              }}
            ></p>
          </div>
          <div className="row">
            {ourExpertiseFilter && ourExpertiseFilter.length > 0
              ? ourExpertiseFilter.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12"
                    id="dgt-expert-col-th"
                    data-aos="flip-left"
                  >
                    <div className="Digital-expert-service text-center">
                      <img
                        src={i.expertise_img}
                        className="top-customer"
                        alt={i.expertise_img_alt}
                      />
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                      <div className="dgt-small-box">
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ---------- digital transformation "Our Segments "sec   ------------- */}
      <IndustryServe
        industryWeServeData={industryWeServeData}
        serviceIndustry={serviceIndustry}
      />

      <CtaStripe />
      {/* --------------- digital transformaiton "what we do" section ------------ */}

      <section className="dgt-what-we-do" id="dgt-what-we-do">
        <span className="dgt-seg-dotted-img-right">
          <img src={IMG.Bg4} alt="img" />
        </span>
        <span className="dgt-seg-dotted-img-left dgt-wwd-dot-left">
          <img src={IMG.Bg4} alt="img" />
        </span>
        <div className="container">
          <div className="inner-dgt-section-heading text-center dgt-what-we-do-head-bg">
            <h2
              dangerouslySetInnerHTML={{
                __html: he.decode(whatWeDoTopHead.Heading),
              }}
            ></h2>
            <p
              dangerouslySetInnerHTML={{
                __html: he.decode(whatWeDoTopHead.description),
              }}
            ></p>
          </div>
          <div
            className="row d-flex justify-content-center"
            id="row_what_wedo_dgt"
          >
            {filteredServices.slice(0, visibleCount).map((i) => (
              <div
                className="col-lg-4 col-md-4 col-sm-12 what_wedo_box_dgt"
                data-aos="zoom-in"
                key={i.id}
              >
                <div className="card dgt-we-do-img-sec">
                  <Link to={i.page_link}>
                    <img
                      src={i.service_img}
                      alt={i.service_img_alt}
                      className="dgt-what-we-do-card-image"
                    />
                  </Link>
                  <div className="card-body">
                    <Link to={i.page_link}>
                      <h5 className="card-title" id="dgt-what-we-do-card-head">
                        {i.title}
                      </h5>
                    </Link>
                    <p
                      className="card-text"
                      id="dgt-what-we-do-card-para"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                    <div className="blog_page_button">
                      <Link to={i.page_link} id="dgt-serv-wedo-mless-btn1">
                        Read More &nbsp;
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {filteredServices.length > 3 && (
              <div className="text-center dgt-load-btn">
                <button
                  onClick={toggleCardsDgt}
                  className="load-more-btn-dgt px-4"
                >
                  {!areCardsVisibleDgt ? "Load More" : "Load Less"}
                </button>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default DigitalTransformation;

import React, { useState } from "react";
import he from "he";
import { Link } from "react-router-dom";

const IndustryServe = ({ industryWeServeData, serviceIndustry }) => {
  const [visibleItems, setVisibleItems] = useState(3);

  const loadMoreItems = () => {
    setVisibleItems(serviceIndustry.length);
  };

  const loadLessItems = () => {
    setVisibleItems(3);
  };

  if (
    !industryWeServeData ||
    !serviceIndustry ||
    industryWeServeData.length === 0
  )
    return null;
  return (
    <section id="industries" className="bpo-serv-service-sec">
      <span className="shadow-img-segments-right">
        <img src={process.env.PUBLIC_URL + "assets/img/bg4.png"} alt="img" />
      </span>
      <span className="shadow-img-segments-left">
        <img src={process.env.PUBLIC_URL + "assets/img/bg4.png"} alt="img" />
      </span>
      <div className="container">
        <h2 className="bpo-serv-our-segments-heading text-center">
          <h2
            dangerouslySetInnerHTML={{
              __html: he.decode(industryWeServeData.Heading),
            }}
          ></h2>
        </h2>
        <p
          className="bpo-serv-our-segments-desc"
          dangerouslySetInnerHTML={{
            __html: he.decode(industryWeServeData.description),
          }}
        ></p>

        <div className="row bpo-serv-segments-row d-flex justify-content-center">
          {serviceIndustry && serviceIndustry.length > 0
            ? serviceIndustry.slice(0, visibleItems).map((i) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-12 bpo-serv-segments_box"
                  data-aos="fade-up"
                  key={i.id}
                >
                  <div className="card bpo-serv-segments-card">
                    <figure className="bpo-serv-segments-effect-steve">
                      <Link to={i.industry_link}>
                        <img src={i.industry_img} alt={i.industry_img_alt} />
                      </Link>
                      <div className="bpo-serv-segments-intro-img">
                        <h2>{i.title}</h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                          style={{ width: "100%" }}
                        ></p>
                      </div>
                    </figure>
                  </div>
                </div>
              ))
            : null}
        </div>
        {serviceIndustry.length > 3 && (
          <div className="text-center btn-div-segments">
            <button
              onClick={visibleItems === 3 ? loadMoreItems : loadLessItems}
              className="load-more-segments-btn px-4"
            >
              {visibleItems === 3 ? "Load More" : "Load Less"}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default IndustryServe;

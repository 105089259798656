import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const BpoHealth = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    blogData,
    mainAboutData,
    pagesData,
    mainServicesData,
    statisticsData,
  } = useContext(BlogContext);
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 6 && i.page_id === 19),
    [bannerData]
  );
  const [first] = bpoBanner;

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 6 && i.page_id === 19),
    [blogData]
  );

  const [firstItem, ...otherItems] = bpoBlog;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 6 && i.page_id === 19),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 27),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 6 && i.page_id === 19),
    [mainServicesData]
  );

  const statisticsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 73),
    [pagesData]
  );

  const statistics = useMemo(
    () => statisticsData.filter((i) => i.category_id === 6 && i.page_id === 19),
    [statisticsData]
  );
  if (!statisticsTitleDes || !statistics) return null;
  return (
    <>
      {/*-------------------------BPO service sales page banner Start --------------------*/}

      {/* {first && (
        <section
          id="bpo-sub-banner-health"
          className="bpo-sub-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container" id="bpo-sub-banner-main-cont">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <h1
                  className="bpo-sub-banner-Heading bpo-health-sub-banner-Heading"
                  data-aos="fade-left"
                >
                  {first.title}
                </h1>
                <p
                  className="bpo-sub-banner-para bpo-health-sub-banner-para"
                  data-aos="fade-right"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>

                <div className="bpo-inner-banner-bg-btn">
                  <a
                    href="tel:+91-892-988-4560"
                    class="bpo-inner-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#services"} blackTitle />

      <BreadCrumb
        url={"/bpo-service"}
        pageTitle={"Bpo Services"}
        currentPage={"Healthcare"}
      />

      {/*-------------------------BPO "understand the process" start--------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={serviceTitleDes} innerServices={serviceData} />

      {/* -----------------BPO service health page "why You should work with us" start------------------*/}
      <section id="health-why-work" className="health-why-work">
        <div className="container">
          <div className="what-we-heading">
            <h2
              className="bpo-sub-section-heading"
              dangerouslySetInnerHTML={{
                __html: he.decode(statisticsTitleDes.Heading),
              }}
            ></h2>
          </div>
          <p
            className="bpo-sub-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(statisticsTitleDes.description),
            }}
          ></p>

          <div class="row helth-roww">
            <div class="col-lg-1 helth-iconn" data-aos="flip-up">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="53"
                  height="58"
                  viewBox="0 0 53 58"
                  fill="none"
                >
                  <path d="M25.0087 57.6561C25.8721 58.1166 26.9558 58.1136 27.8193 57.6532C51.3493 45.0306 53.0204 20.3691 52.9998 13.3569C52.9976 12.7933 52.8308 12.2426 52.5199 11.7722C52.209 11.3018 51.7675 10.9322 51.2495 10.7086L27.6078 0.2504C27.2316 0.0847855 26.8249 -0.000502805 26.4137 2.22986e-06C26.0025 0.000507265 25.596 0.0867943 25.2202 0.253332L1.743 10.7115C1.23257 10.9353 0.797298 11.3008 0.489049 11.7646C0.180799 12.2284 0.0125646 12.7709 0.0043881 13.3275C-0.0954648 20.3046 1.31129 45.0189 25.0087 57.6561ZM16.7444 24.0438L23.4786 30.7686L36.0865 18.1783L40.2392 22.3252L23.4786 39.0625L12.5917 28.1907L16.7444 24.0438Z" />
                </svg>
              </p>
            </div>
            <div class="col-lg-5 helth-para-1" data-aos="fade-down">
              <p>{statistics[0]?.title}</p>
            </div>
            <div class="col-lg-6 helth-para-2" data-aos="fade-left">
              <p>
                {statistics[0]?.prefix}
                {statistics[0]?.number}
                {statistics[0]?.suffix}
              </p>
            </div>
          </div>

          <div class="row helth-roww">
            <div class="col-lg-1 helth-iconn" data-aos="flip-up">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="53"
                  height="58"
                  viewBox="0 0 53 58"
                  fill="none"
                >
                  <path d="M25.0087 57.6561C25.8721 58.1166 26.9558 58.1136 27.8193 57.6532C51.3493 45.0306 53.0204 20.3691 52.9998 13.3569C52.9976 12.7933 52.8308 12.2426 52.5199 11.7722C52.209 11.3018 51.7675 10.9322 51.2495 10.7086L27.6078 0.2504C27.2316 0.0847855 26.8249 -0.000502805 26.4137 2.22986e-06C26.0025 0.000507265 25.596 0.0867943 25.2202 0.253332L1.743 10.7115C1.23257 10.9353 0.797298 11.3008 0.489049 11.7646C0.180799 12.2284 0.0125646 12.7709 0.0043881 13.3275C-0.0954648 20.3046 1.31129 45.0189 25.0087 57.6561ZM16.7444 24.0438L23.4786 30.7686L36.0865 18.1783L40.2392 22.3252L23.4786 39.0625L12.5917 28.1907L16.7444 24.0438Z" />
                </svg>
              </p>
            </div>
            <div class="col-lg-6 helth-para-1" data-aos="fade-down">
              <p>{statistics[1]?.title}</p>
            </div>
            <div class="col-lg-5 helth-para-2" data-aos="fade-left">
              <p>
                {statistics[1]?.prefix}
                {statistics[1]?.number}
                {statistics[1]?.suffix}
              </p>
            </div>
          </div>

          <div class="row helth-roww">
            <div class="col-lg-1 helth-iconn" data-aos="flip-up">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="53"
                  height="58"
                  viewBox="0 0 53 58"
                  fill="none"
                >
                  <path d="M25.0087 57.6561C25.8721 58.1166 26.9558 58.1136 27.8193 57.6532C51.3493 45.0306 53.0204 20.3691 52.9998 13.3569C52.9976 12.7933 52.8308 12.2426 52.5199 11.7722C52.209 11.3018 51.7675 10.9322 51.2495 10.7086L27.6078 0.2504C27.2316 0.0847855 26.8249 -0.000502805 26.4137 2.22986e-06C26.0025 0.000507265 25.596 0.0867943 25.2202 0.253332L1.743 10.7115C1.23257 10.9353 0.797298 11.3008 0.489049 11.7646C0.180799 12.2284 0.0125646 12.7709 0.0043881 13.3275C-0.0954648 20.3046 1.31129 45.0189 25.0087 57.6561ZM16.7444 24.0438L23.4786 30.7686L36.0865 18.1783L40.2392 22.3252L23.4786 39.0625L12.5917 28.1907L16.7444 24.0438Z" />
                </svg>
              </p>
            </div>
            <div class="col-lg-7 helth-para-1" data-aos="fade-down">
              <p>{statistics[2]?.title}</p>
            </div>
            <div class="col-lg-4 helth-para-2" data-aos="fade-left">
              <p>
                {statistics[2]?.prefix}
                {statistics[2]?.number}
                {statistics[2]?.suffix}
              </p>
            </div>
          </div>

          <div class="row helth-roww">
            <div class="col-lg-1 helth-iconn" data-aos="flip-up">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="53"
                  height="58"
                  viewBox="0 0 53 58"
                  fill="none"
                >
                  <path d="M25.0087 57.6561C25.8721 58.1166 26.9558 58.1136 27.8193 57.6532C51.3493 45.0306 53.0204 20.3691 52.9998 13.3569C52.9976 12.7933 52.8308 12.2426 52.5199 11.7722C52.209 11.3018 51.7675 10.9322 51.2495 10.7086L27.6078 0.2504C27.2316 0.0847855 26.8249 -0.000502805 26.4137 2.22986e-06C26.0025 0.000507265 25.596 0.0867943 25.2202 0.253332L1.743 10.7115C1.23257 10.9353 0.797298 11.3008 0.489049 11.7646C0.180799 12.2284 0.0125646 12.7709 0.0043881 13.3275C-0.0954648 20.3046 1.31129 45.0189 25.0087 57.6561ZM16.7444 24.0438L23.4786 30.7686L36.0865 18.1783L40.2392 22.3252L23.4786 39.0625L12.5917 28.1907L16.7444 24.0438Z" />
                </svg>
              </p>
            </div>
            <div class="col-lg-8 helth-para-1" data-aos="fade-down">
              <p>{statistics[3]?.title}.</p>
            </div>
            <div class="col-lg-3 helth-para-2" data-aos="fade-left">
              <p>
                {statistics[3]?.prefix}
                {statistics[3]?.number}
                {statistics[3]?.suffix}
              </p>
            </div>
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};
export default BpoHealth;

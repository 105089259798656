import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/Loader";
import BlogContext from "../../../context/blogContext";
import MainAbout from "../../../components/MainAbout";
import IndusBenoProvides from "../../../components/IndusBenoProvides";
import he from "he";
const RetailMain = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [current, setCurrent] = useState("");

  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".retail-main-indus-page-index"
    );
    const stickyDiv = "sticky-ites-indus";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const {
    bannerData,
    loading,
    mainAboutData,
    mainServicesData,
    pagesData,
    benefitsData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 18 && i.page_id === 65),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 18 && i.page_id === 65),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 50),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 18 && i.page_id === 65),
    [mainServicesData]
  );

  const capabilityTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 51),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 18 && i.page_id === 65),
    [benefitsData]
  );

  const segmentTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 220),
    [pagesData]
  );

  const segment = useMemo(
    () => segmentData.filter((i) => i.section_id === 220 && i.page_id === 65),
    [segmentData]
  );
  if (!capabilityTitleDes || !segmentTitleDes) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/*-------------- banner section --------------*/}

      {first ? (
        <section
          id="retail-indus-main-banner"
          className="retail-indus-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="retail-indus-main-bet-content retail-indus-main-banner-content">
              <h3
                data-aos="zoom-in"
                className="retail-indus-main-banner-head text-white"
              >
                {first.title}
              </h3>
            </div>
            <div className="indus-retail-banner-main-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-ind-retail">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}

      {/* ------------ retail industry page indexing section -------------- */}
      <section className="retail-main-indus-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="retail-main-indus-index-nav">
                <ul>
                  <li>
                    <a
                      className={`index-retail-main-indus-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-retail-main-indus-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-retail-main-indus-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-retail-main-indus-item ${
                        current === "overview" ? "active" : ""
                      }`}
                      href="#overview"
                      aria-label="overview?"
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-retail-main-indus-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/retail"}> Retail </Link>
        </div>
      </section>

      {/* -------- about section ----------------- */}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* -------- retail industry provides section -------- */}
      <IndusBenoProvides
        indusHeadDesc={serviceTitleDes}
        benoProvides={serviceData}
      />

      {/* -------- our capabilities section start -------- */}
      <section id="services" className="retail-main-ind-capabilities-sec">
        <div className="container">
          <div>
            <h2
              className="indus-main-retail-section-heading text-center retail-main-ind-capab-head-bg"
              dangerouslySetInnerHTML={{
                __html: he.decode(capabilityTitleDes.Heading),
              }}
            ></h2>
            <p
              className="retail-main-about-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(capabilityTitleDes.description),
              }}
            ></p>
          </div>
          <div className="row mt-4">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div
                    id="retail-ind-capabilities-main"
                    data-aos="fade-up-left"
                    className="col-lg-4 col-md-4 col-sm-12"
                    key={i.id}
                  >
                    <div className="retail-ind-inner-area-customer-main">
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* -===============================  retail  whats new  section =============================- */}
      <section id="overview" className="retail-main-whats-new-sec">
        <div className="container">
          <h2
            className="indus-main-retail-section-heading main-retail-indus-whats-new-eff"
            dangerouslySetInnerHTML={{
              __html: he.decode(segmentTitleDes.Heading),
            }}
          ></h2>
          <div className="row" id="retail-ind-main-grid-row">
            {segment && segment.length > 0
              ? segment.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12"
                    id="retail-ind-frid-first-whats-new-sec"
                    data-aos="flip-up"
                    key={i.id}
                  >
                    <div className="card retail-ind-whats-new-grid-first-card">
                      <img
                        src={i.image}
                        className="card-img-top retail-ind-whats-new-grid-first-card-img"
                        alt={i.image_alt}
                      />
                      <div className="card-body">
                        <h6 className="retail-ind-whats-new-grid-first-card-category">
                          {i.pre_heading}
                        </h6>
                        <a className="retail-ind-whats-new-grid-first-card-link">
                          <h5 className="card-title retail-ind-whats-new-grid-first-card-title">
                            {i.title}
                          </h5>
                        </a>
                        <p
                          className="card-text retail-ind-whats-new-grid-first-card-para"
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
      {/* ---------------- Bpo service latest one blog section  ----------- */}

      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Latest retail blog trends: innovation, technology, customer
            experience, strategy.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.RetailBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                5 Ways Retail Technologies Are Shaping Future Labor Models
              </h2>
              <p className="recent-blogs-slider-desc">
                In the dynamic landscape of retail, emerging technologies are
                not only transforming customer experiences but also
                revolutionizing the way retail businesses manage their
                workforce. This blog explores five innovative ways in which
                retail technologies are reshaping labor models, from AI-driven
                scheduling and remote work solutions to augmented reality
                training and predictive analytics for talent management.
                Discover how these advancements are paving the way for a more
                agile, efficient, and adaptable workforce in the retail
                industry.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Gen AI and the Retail Revolution: Exploring the
                            Impact of AI-Powered Consumer Trends
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore how Generation AI's preferences are reshaping
                          retail experiences, with personalized recommendations
                          and immersive shopping environments driving
                          innovation.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            How Retailers Embrace New Technology to Leverage
                            Cloud Infrastructure
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Retailers embrace cloud technology for growth,
                          efficiency, and scalability in the digital era of
                          commerce
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Pitfalls to Avoid: 5 Common Product
                            Recommendation Errors in E-commerce
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Avoid critical product recommendation errors in
                          ecommerce for enhanced customer engagement and
                          increased sales
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Gen AI and the Retail Revolution: Exploring the
                            Impact of AI-Powered Consumer Trends
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore how Generation AI's preferences are reshaping
                          retail experiences, with personalized recommendations
                          and immersive shopping environments driving
                          innovation.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            How Retailers Embrace New Technology to Leverage
                            Cloud Infrastructure
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Retailers embrace cloud technology for growth,
                          efficiency, and scalability in the digital era of
                          commerce
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RetailMain;

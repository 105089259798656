import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BlogContext from "../../../context/blogContext";
import he from "he";
import BreadCrumb from "../../../components/BreadCrumb";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const HRTalentAquacious = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    statisticsData,
    mainServicesData,
    pagesData,
    benefitsData,
    segmentData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 12 && i.page_id === 44),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 12 && i.page_id === 44),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 12 && i.page_id === 44),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const statistics = useMemo(
    () =>
      statisticsData.filter((i) => i.category_id === 12 && i.page_id === 44),
    [statisticsData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 118),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 12 && i.page_id === 44),
    [mainServicesData]
  );

  const benefitsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 119),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 12 && i.page_id === 44),
    [benefitsData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.section_id === 118 && i.page_id === 44),
    [segmentData]
  );
  if (!benefitsTitleDes) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/* ---------------- banner section --------------- */}

      {/* {first && (
        <section
          className="hr-talent-banner-main hr-talent-banner-main-acquacious hr-talent-banner-overlay"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12">
                <div className="hr-talent-div-banner-title">
                  <h2 className="hr-talent-banner-heading" data-aos="fade-down">
                    {first.title}
                  </h2>
                  <p
                    className="hr-talent-banner-para"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                  <div className="hr-talent-aquacious-banner-bg-btn">
                    <a href="#talent-hr-crime-sec">{first.btn_first}</a>
                    <a
                      href="tel:+91-892-988-4560"
                      class="hr-talent-aquacious-banner-bg-btn-num"
                    >
                      {first.btn_second}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner
        serviceBanner={first}
        indexId={"#talent-hr-crime-sec"}
        blackTitle
      />

      <BreadCrumb
        url={"/hr-talent-services"}
        pageTitle={"HR Talent  Services"}
        currentPage={"HR Process Outsourcing"}
      />

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* ---------------- circles --------------- */}
      <section id="hr-talent-circle" className="hr-talent-circle-aquaccious">
        <div className="container">
          <div className="row circle-sec-hr-talent">
            {statistics && statistics.length > 0
              ? statistics.map((i) => (
                  <div
                    className="col-md-4 hr-talent-aquacious-circle"
                    key={i.id}
                  >
                    <h3 className="hr-talent-acqacious-head">
                      {i.prefix}
                      {i.number}
                      {i.suffix}
                    </h3>
                    <p className="hr-talent-acqacious-para">{i.title}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* -------- cyber crime section ------------- */}
      <section className="talent-hr-crime-sec" id="talent-hr-crime-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="talent-hr-crime-head">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: he.decode(benefitsTitleDes.Heading),
                  }}
                ></h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(benefitsTitleDes.description),
                  }}
                ></p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="talent-hr-crime-content">
                {benefits
                  ? benefits.slice(0, 2).map((i) => (
                      <div className="intelligence-driven-crime" key={i.id}>
                        <h6>{i.title}</h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    ))
                  : null}
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="talent-hr-crime-content">
                {benefits
                  ? benefits.slice(2, 4).map((i) => (
                      <div className="intelligence-driven-crime" key={i.id}>
                        <h6>{i.title}</h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ------ section What we Think ----------------- */}

      {segment ? (
        <section id="hr-talent-what-we-think">
          <div class="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                <h2
                  className="hr-talent-head-main-title"
                  id="app-datamig-hr-talent-about-effect"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.title),
                  }}
                ></h2>
                <p
                  class="hr-talent-head-main-para"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div
                  className="hr-talent-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${segment.image})`,
                  }}
                ></div>
                <span className="hr-talent-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default HRTalentAquacious;

import React, { useContext, useMemo } from "react";

import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const CustomerExperience = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    mainAboutData,
    pagesData,
    mainServicesData,
    solutionData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 7 && i.page_id === 15),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtBlogs = useMemo(
    () => blogData.filter((i) => i.category_id === 7 && i.page_id === 15),
    [blogData]
  );
  const [firstItem, ...otherItems] = dgtBlogs;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 7 && i.page_id === 15),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 75),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 7 && i.page_id === 15),
    [mainServicesData]
  );

  const solutionTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 217),
    [pagesData]
  );

  const solution = useMemo(
    () => solutionData.filter((i) => i.section_id === 217 && i.page_id === 15),
    [solutionData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.section_id === 217 && i.page_id === 15),
    [segmentData]
  );

  if (!solutionTitleDes) return null;
  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#customer-experience"} />

      <BreadCrumb
        url={"/digital-transformation"}
        pageTitle={"Digital Transformation"}
        currentPage={"Customer Experience Transformation"}
      />

      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={serviceTitleDes} innerServices={serviceData} />

      {/* -------------------------- customer Experience ---------------------- */}
      <section id="customer-experience">
        <div className="container">
          <h6 className="customer-experience-pre-head">
            {solutionTitleDes.pre_heading}
          </h6>
          <div className="customer-experience-head-div">
            <h2
              className="dgt-sub-section-heading-spec"
              dangerouslySetInnerHTML={{
                __html: he.decode(solutionTitleDes.Heading),
              }}
            ></h2>
          </div>
          <div
            className="customer-experience-accordion-main accordion accordion-flush"
            id="accordionFlushExample"
          >
            {solution && solution.length > 0
              ? solution.map((i, index) => (
                  <div className="accordion-item" key={index}>
                    <h2
                      className="accordion-header customer-experience-accordion-quest"
                      id={`flush-heading${index}`}
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        <span>0{index + 1}.</span> {i.title}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`flush-heading${index}`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div
                            className="col-lg-5 col-md-5 col-sm-12"
                            id="dgt-col-first-tabs"
                          >
                            <div
                              className="dgt-col-first-img-tabs"
                              style={{
                                backgroundImage: `url(${i.image})`,
                              }}
                            ></div>
                          </div>
                          <div
                            className="col-lg-7 col-md-7"
                            id="dgt-col-second-tabs"
                          >
                            <div className="dgt-tabs-accor-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: he.decode(i.description),
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* -------------------section What we Think start------------------------------ */}
      {segment ? (
        <section id="customer-what-we-think">
          <div class="container">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-up-right"
              >
                <h2
                  class="dgt-sub-section-heading"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.title),
                  }}
                ></h2>
                <p
                  class="dgt-sub-section-sub-heading"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-up-left"
              >
                <div
                  className="dgt-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${segment.image})`,
                  }}
                ></div>
                <span className="dgt-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ---------------- Bpo service latest one blog section  ----------- */}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default CustomerExperience;

import React, { useEffect, useState, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import he from "he";
import {
  faArrowRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BlogContext from "../../../context/blogContext";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Loader from "../../../components/Loader";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import IndustryServe from "../../../components/IndustryServe";

const HRTalent = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".nag-page-index-hr-talent");
    const stickyDiv = "sticky-hr-talent";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    serviceIndustryData,
    pagesData,
    ourExpertiseData,
    mainServicesData,
    benefitsData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 12),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 12 && i.page_id === 42),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 12 && i.page_id === 42),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const industryWeServe = useMemo(
    () => pagesData.filter((i) => i.id === 17),
    [pagesData]
  );

  const [industryWeServeData] = industryWeServe;

  const serviceIndustry = useMemo(
    () =>
      serviceIndustryData.filter(
        (i) => i.category_id === 12 && i.page_id === 42
      ),
    [serviceIndustryData]
  );

  const ourExpTop = useMemo(
    () => pagesData.filter((i) => i.id === 22),
    [pagesData]
  );

  const [ourExpTopHead] = ourExpTop;
  const ourExpertiseFilter = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 12 && i.page_id === 42),
    [ourExpertiseData]
  );

  const ourApproachTop = useMemo(
    () => pagesData.filter((i) => i.id === 41),
    [pagesData]
  );

  const [ourApproachTopData] = ourApproachTop;

  const whatWeDoTop = useMemo(
    () => pagesData.filter((i) => i.id === 42),
    [pagesData]
  );

  const [whatWeDoTopData] = whatWeDoTop;

  const filteredServices = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 12 && i.page_id === 42),
    [mainServicesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 12 && i.page_id === 42),
    [benefitsData]
  );

  if (
    !ourExpTopHead ||
    !industryWeServeData ||
    !ourExpertiseFilter ||
    !ourApproachTopData ||
    !whatWeDoTopData ||
    !benefits
  )
    return null;

  if (loading) return <Loader />;
  return (
    <>
      {/* ---------------- banner section --------------- */}
      {first && (
        <section
          className="hr-talent-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="hr-talent-div-banner-title">
                  <h2
                    className="hr-talent-banner-heading text-white"
                    data-aos="fade-down"
                  >
                    {first.title}
                  </h2>
                  <p
                    className="hr-talent-banner-para text-white"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>

                <div className="row mt-4" id="play-button-hr-talent">
                  <div className="col-lg-9  col-sm-12 hr-talent-know-how-btn">
                    <div className="hr-talent-banner-know-how">
                      <a href="#hr-talent-approach">
                        {first.btn_first}{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* --------------- page index section ------------- */}
      <section className="nag-page-index-hr-talent">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar-index-hr-talent">
                <ul className="nav-index-hr-talent">
                  <li>
                    <a
                      className={`nav-link-index-hr-talent ${
                        current === "hr-talent-about" ? "active" : ""
                      }`}
                      href="#hr-talent-about"
                      aria-label="About"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-hr-talent ${
                        current === "hr-talen-industry" ? "active" : ""
                      }`}
                      href="#hr-talen-industry"
                      aria-label="Industry"
                    >
                      Industry
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-hr-talent ${
                        current === "hr-talent-approach" ? "active" : ""
                      }`}
                      href="#hr-talent-approach"
                      aria-label="Approach"
                    >
                      Approach
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-hr-talent ${
                        current === "hr-talent-services" ? "active" : ""
                      }`}
                      href="#hr-talent-services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-hr-talent ${
                        current === "hr-talent-benefits" ? "active" : ""
                      }`}
                      href="#hr-talent-benefits"
                      aria-label="Benefits"
                    >
                      Benefits
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-hr-talent ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link className="text-black" to="/">
            Home{" "}
          </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link className="text-black" to={"/hr-talent-services"}>
            {" "}
            HR & Talent Services
          </Link>
        </div>
      </section>

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* --------- industries  section ----------- */}
      <IndustryServe
        industryWeServeData={industryWeServeData}
        serviceIndustry={serviceIndustry}
      />

      {/* --------- Approach section ----------- */}
      <section id="hr-talent-approach" className="hr-talent-approach-sec">
        <div className="container">
          <h3
            className="hr-talent-approach-main-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(ourApproachTopData.Heading),
            }}
          ></h3>
          <p
            className="hr-talent-head-main-para"
            dangerouslySetInnerHTML={{
              __html: he.decode(ourApproachTopData.description),
            }}
          ></p>

          <div className="row" id="hr-talent-approach-row-main-content">
            {benefits &&
              benefits.length > 0 &&
              benefits.map((i) => (
                <div className="col-lg-3 col-md-3 col-sm-12 hr-talent-main-approach-box">
                  <h2 data-aos="fade-down">{i.title}</h2>
                </div>
              ))}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ----------- service section ------------------ */}
      <section id="hr-talent-services">
        <div className="container">
          <h2
            className="hr-talent-head-main-title text-center"
            id="hr-talent-service-head-effect"
          >
            {whatWeDoTopData.Heading}
          </h2>
          <p
            className="hr-talent-head-main-para text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(whatWeDoTopData.description),
            }}
          ></p>
          <div className="row">
            {filteredServices &&
              filteredServices.length > 0 &&
              filteredServices.map((i) => (
                <div
                  className="col-lg-4 col-md-4 col-sm-12 hr-talent-services-div-main"
                  data-aos="fade-up"
                  key={i.id}
                >
                  <div className="card hr-talent-service-crd-main">
                    <div className="hr-talent-service-crd-img-main">
                      <Link to={i.page_link}>
                        <img
                          src={i.service_img}
                          alt={i.service_img_alt}
                          className="card-img-top"
                        />
                      </Link>
                    </div>
                    <div className="card-body">
                      <Link to={i.page_link}>
                        <h3>{i.title}</h3>
                      </Link>
                      <p
                        className="card-text"
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                      <div className="hr-talent-service-small-box-down">
                        <Link to={i.page_link}>
                          <FontAwesomeIcon icon={faArrowRightLong} />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      {/* -------------- Benefits section ------------ */}
      <section id="hr-talent-benefits" className="hr-talent-benefits-sec">
        <div className="container">
          <h2
            className="hr-talent-head-main-title text-center"
            id="hr-talent-benefits-head-effect"
            dangerouslySetInnerHTML={{
              __html: he.decode(ourExpTopHead.Heading),
            }}
          ></h2>
          <p
            className="hr-talent-head-main-para text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(ourExpTopHead.description),
            }}
          ></p>

          <div className="row">
            {ourExpertiseFilter &&
              ourExpertiseFilter.length > 0 &&
              ourExpertiseFilter.map((i) => (
                <div
                  className="col-lg-6 col-md-6 col-sm-12 hr-talent-benefits-main"
                  data-aos="fade-up"
                  key={i.id}
                >
                  <div className="hr-talent-benefits-main-head">
                    <h2>
                      <img src={i.expertise_img} alt={i.expertise_img_alt} />
                      <span
                        dangerouslySetInnerHTML={{ __html: he.decode(i.title) }}
                      ></span>
                    </h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default HRTalent;

import React, { useEffect, useState, useMemo, useContext } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import IndusBenoProvides from "../../../components/IndusBenoProvides";
import he from "he";
const ItesService = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".ites-indus-page-index");
    const stickyDiv = "sticky-ites-indus";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    mainServicesData,
    ourExpertiseData,
    benefitsData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 17 && i.page_id === 59),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 17 && i.page_id === 59),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 31),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 17 && i.page_id === 59),
    [mainServicesData]
  );

  const effortlesslyTop = useMemo(
    () => pagesData.find((i) => i.id === 48),
    [pagesData]
  );

  const expertiseData = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 17 && i.page_id === 59),
    [ourExpertiseData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 17 && i.page_id === 59),
    [benefitsData]
  );

  const benefitsTop = useMemo(
    () => pagesData.find((i) => i.id === 49),
    [pagesData]
  );

  if (!effortlesslyTop) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/*-------------- banner section --------------*/}
      {first ? (
        <section
          id="ites-indus-main-banner"
          className="ites-indus-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="ites-indus-bet-content ites-indus-banner-content">
              <h3 data-aos="zoom-in" className="ites-indus-main-banner-head">
                {first.title}
              </h3>
            </div>
            <div className="indus-ites-banner-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-ind-ites">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}

      {/* ------------ ITES industry page indexing section -------------- */}
      <section className="ites-indus-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="ites-indus-index-nav">
                <ul>
                  <li>
                    <a
                      className={`index-ites-indus-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-ites-indus-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-ites-indus-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-ites-indus-item ${
                        current === "overview" ? "active" : ""
                      }`}
                      href="#overview"
                      aria-label="overview?"
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-ites-indus-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/ites"}> ITES</Link>
        </div>
      </section>

      {/* -------- about section ----------------- */}

      <MainAbout mainAboutData={dgtMainAboutArray} />

      <IndusBenoProvides
        indusHeadDesc={serviceTitleDes}
        benoProvides={serviceData}
      />

      {/* ----------we effortlessly handle it all section start----------- */}
      <section id="services">
        <div className="container">
          <div>
            <h2
              className="indus-ites-section-heading text-center ites-indus-effort-head-bg"
              dangerouslySetInnerHTML={{
                __html: he.decode(effortlesslyTop.Heading),
              }}
            ></h2>
            <p
              className="ites-indus-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(effortlesslyTop.description),
              }}
            ></p>
          </div>

          {/* service area cards */}
          <div className="row">
            {expertiseData && expertiseData.length > 0
              ? expertiseData.map((i) => (
                  <div className="col-lg-4 col-md-4 col-sm-12 ites-indus-service-area-cards ">
                    <h2 data-aos="fade-left">{i.title}</h2>
                    <p
                      data-aos="fade-left"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* benifits to work with us */}
      <section id="overview">
        <div className="container">
          <h2
            className="indus-ites-section-heading ites-indus-benifits-eff"
            dangerouslySetInnerHTML={{ __html: he.decode(benefitsTop.Heading) }}
          ></h2>
          <p
            className="ites-indus-section-sub-heading "
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTop.description),
            }}
          ></p>
          <div className="row">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 ites-indus-work-box-content"
                    key={i.id}
                  >
                    <h2 data-aos="fade-down">{i.title}</h2>
                    <p
                      data-aos="fade-down"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}

      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends and insights shaping the ITES sector in
            our recent blog posts.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.ItesBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Customer-Centricity in ITES: Enhancing Digital Experiences for
                End Users.
              </h2>
              <p className="recent-blogs-slider-desc">
                In today's competitive ITES landscape, customer-centricity is
                paramount. This blog delves into the importance of prioritizing
                the digital experience for end users. From intuitive interfaces
                to personalized interactions, we explore strategies for
                enhancing customer satisfaction and loyalty. By understanding
                and meeting the evolving needs of customers, ITES companies can
                differentiate themselves, drive growth, and forge lasting
                relationships in the digital era.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Unlocking the Potential: AI and Automation Trends in
                            ITES
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Exploring the latest advancements and applications of
                          AI and automation technologies in the ITES sector.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Data Security in the Digital Age: Strategies for
                            ITES Companies
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discussing the importance of robust data security
                          measures and best practices for ITES organizations.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Rise of Remote Work: Transforming ITES
                            Operations in a Post-Pandemic World
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Analyzing the impact of remote work on ITES businesses
                          and strategies for successful remote operations.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Unlocking the Potential: AI and Automation Trends in
                            ITES
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Exploring the latest advancements and applications of
                          AI and automation technologies in the ITES sector.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Data Security in the Digital Age: Strategies for
                            ITES Companies
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discussing the importance of robust data security
                          measures and best practices for ITES organizations.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItesService;

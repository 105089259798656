import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import MainAbout from "../../../components/MainAbout";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const DataOperationsInsights = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    mainAboutData,
    blogData,
    mainServicesData,
    pagesData,
    statisticsData,
    benefitsData,
  } = useContext(BlogContext);
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 6 && i.page_id === 20),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 6 && i.page_id === 20),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 6 && i.page_id === 20),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 28),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 6 && i.page_id === 20),
    [mainServicesData]
  );

  const statistics = useMemo(
    () => statisticsData.filter((i) => i.category_id === 6 && i.page_id === 20),
    [statisticsData]
  );

  const benefitsTitleDesc = useMemo(
    () => pagesData.find((i) => i.id === 74),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 6 && i.page_id === 20),
    [benefitsData]
  );

  if (!benefitsTitleDesc) return null;

  return (
    <>
      {/*-----------BPO Data Operations and Insights page banner --------------*/}
      {/* {first && (
        <section
          id="bpo-sub-banner-doi"
          className="bpo-sub-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container" id="bpo-sub-banner-main-cont">
            <div className="row">
              <div className="col-lg-6 col-md-8 col-sm-12">
                <h1 className="bpo-sub-banner-Heading" data-aos="fade-left">
                  {first.title}
                </h1>
                <p
                  className="bpo-sub-banner-para"
                  data-aos="fade-right"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>

                <div className="bpo-inner-banner-bg-btn">
                  <a href="#doi-benefits">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="bpo-inner-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#doi-benefits"} />

      <BreadCrumb
        url={"/bpo-service"}
        pageTitle={"Bpo Services"}
        currentPage={"Data Operations & Insight"}
      />

      <InnerServices headDesc={serviceTitleDes} innerServices={serviceData} />

      {/*-----------BPO Data Operations and Insights What-we-do ------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <CtaStripe />

      {/* ----------Data Operations and Insights service area section start----------- */}
      <section id="doi-benefits">
        <div className="container">
          {/* service area heading */}
          <div className="service-area-head">
            <h2
              className="bpo-sub-section-heading"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTitleDesc.Heading),
              }}
            ></h2>
          </div>

          <p
            className="bpo-sub-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitleDesc.description),
            }}
          ></p>

          {/* service area cards */}
          <div className="row sales-service-inner-sec">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 doi-service-area-cards"
                    key={i.id}
                  >
                    <h2 data-aos="fade-left">{i.title}</h2>
                    <p
                      data-aos="fade-left"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>

          {/* counter area Data Operations and Insights */}
          <div className="row" id="doi-data-counter-row">
            {statistics && statistics.length > 0
              ? statistics.map((i) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 bpo-sub-why-box-content"
                    key={i.id}
                  >
                    <h2 data-aos="fade-down">
                      {i.prefix}
                      {i.number}
                      {i.suffix}
                    </h2>
                    <p data-aos="fade-down">{i.title}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default DataOperationsInsights;

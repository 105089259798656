import React, { useContext, useMemo } from "react";
import { CtaStripe } from "./Services/ApplicationService/ApplicationServices";
import BlogContext from "../context/blogContext";
import he from "he";
import Loader from "../components/Loader";

const TermsCondition = () => {
  const { faqData, bannerData, loading } = useContext(BlogContext);
  const privacyPolicyTopContent = useMemo(
    () =>
      faqData.find((i) => i.page_id === 113 && i.sec_name === "main-content"),
    [faqData]
  );

  const privacyPolicyFaq = useMemo(
    () => faqData.filter((i) => i.page_id === 113 && i.sec_name === "faqs"),
    [faqData]
  );

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 30 && i.page_id === 113),
    [bannerData]
  );
  const [first] = bpoBanner;

  if (!privacyPolicyTopContent || !privacyPolicyFaq) return null;
  return (
    <>
      {loading ? <Loader /> : null}
      {first ? (
        <section
          className="contact-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="banner-content-contact terms-condition-content">
              <h2>{first.title}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(first.description),
                }}
              ></p>
            </div>
          </div>
        </section>
      ) : null}

      <section className="privacy-terms-content-sec">
        <div className="container">
          <h2>{privacyPolicyTopContent.title}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: he.decode(privacyPolicyTopContent.description),
            }}
          ></p>
        </div>
      </section>

      {/* -------- terms & condition faq's ----------- */}
      <section>
        <div className="container">
          {/* faq's accordion code */}
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {/* first accordion- */}

            {privacyPolicyFaq &&
              privacyPolicyFaq.length > 0 &&
              privacyPolicyFaq.map((item, index) => (
                <div className="accordion-item-policy-faqs" key={item.id}>
                  <h2
                    className="accordion-header policy-accordion-faqs"
                    id={`flush-heading${index}`}
                  >
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#privacy-notice-${index}`}
                      aria-expanded="false"
                      aria-controls={`flush-collapse${index}`}
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`privacy-notice-${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`flush-heading${index}`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body" id="accordion-message-faqs">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(item.description),
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <CtaStripe />
    </>
  );
};

export default TermsCondition;

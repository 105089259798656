import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import BlogContext from "../../../context/blogContext";
import he from "he";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import IndustryServe from "../../../components/IndustryServe";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";

const DigitalExperience = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".nag-page-index-dgt-experience"
    );
    const stickyDiv = "sticky-dgt-experience";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);
  const [areCardsVisibleDigitalExp, setareCardsVisibleDigitalExp] =
    useState(true);
  const toggleCardsDigitalExp = () => {
    setareCardsVisibleDigitalExp(!areCardsVisibleDigitalExp);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [visibleCount, setVisibleCount] = useState(3);
  const [
    areCardsVisibleServiceExperience,
    setAreCardsVisibleServiceExperience,
  ] = useState(false);

  const toggleCardsServiceExperience = () => {
    setAreCardsVisibleServiceExperience(!areCardsVisibleServiceExperience);
    setVisibleCount(
      areCardsVisibleServiceExperience ? 3 : filteredServices.length
    );
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    serviceIndustryData,
    pagesData,
    blogData,
    mainServicesData,
    solutionData,
  } = useContext(BlogContext);

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 13),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 13 && i.page_id === 46),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 13 && i.page_id === 46),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const industryWeServe = useMemo(
    () => pagesData.filter((i) => i.id === 18),
    [pagesData]
  );

  const [industryWeServeData] = industryWeServe;

  const serviceIndustry = useMemo(
    () =>
      serviceIndustryData.filter(
        (i) => i.category_id === 13 && i.page_id === 46
      ),
    [serviceIndustryData]
  );

  const whatWeDoTop = useMemo(
    () => pagesData.filter((i) => i.id === 43),
    [pagesData]
  );

  const [whatWeDoTopData] = whatWeDoTop;

  const filteredServices = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 13 && i.page_id === 46),
    [mainServicesData]
  );

  const benefits = useMemo(
    () => pagesData.filter((i) => i.id === 33),
    [pagesData]
  );

  const [benefitsData] = benefits;

  const solutionTitle = useMemo(
    () => pagesData.find((i) => i.id === 218),
    [pagesData]
  );

  const solution = useMemo(
    () => solutionData.filter((i) => i.section_id === 218 && i.page_id === 46),
    [solutionData]
  );
  if (!whatWeDoTopData || !benefitsData || !solutionTitle) return null;
  if (loading) return <Loader />;

  return (
    <>
      {/* ---------------- banner section --------------- */}

      {first && (
        <section
          className="dgt-experience-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-9 col-sm-12">
                <div className="dgt-experience-div-banner-title">
                  <h2
                    className="dgt-experience-banner-heading text-white"
                    data-aos="fade-down"
                  >
                    {first.title}
                  </h2>
                  <p
                    className="dgt-experience-banner-para text-white"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>

                <div className="row" id="play-button-dgt-experience">
                  <div className="col-lg-9  col-sm-12 dgt-experience-know-how-btn">
                    <div className="dgt-experience-banner-know-how mt-3">
                      <a href="#experience-solutions">
                        {first.btn_first}{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* --------------- page index section ------------- */}
      <section className="nag-page-index-dgt-experience">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar-index-dgt-experience">
                <ul className="nav-index-dgt-experience">
                  <li>
                    <a
                      className={`nav-link-index-dgt-experience ${
                        current === "digital-exp-about" ? "active" : ""
                      }`}
                      href="#digital-exp-about"
                      aria-label="About"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-dgt-experience ${
                        current === "experience-industry" ? "active" : ""
                      }`}
                      href="#experience-industry"
                      aria-label="Industry"
                    >
                      Industry
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-dgt-experience ${
                        current === "experience-services" ? "active" : ""
                      }`}
                      href="#experience-services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-dgt-experience ${
                        current === "experience-solutions" ? "active" : ""
                      }`}
                      href="#experience-solutions"
                      aria-label="Services"
                    >
                      Solutions
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-dgt-experience ${
                        current === "experience-benefits" ? "active" : ""
                      }`}
                      href="#experience-benefits"
                      aria-label="BENEFITS"
                    >
                      Benefits
                    </a>
                  </li>

                  <li>
                    <a
                      className={`nav-link-index-dgt-experience ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link className="text-black" to="/">
            Home{" "}
          </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link className="text-black" to={"/digital-experience"}>
            {" "}
            Digital Experience
          </Link>
        </div>
      </section>

      {/*-------------about section --------------------*/}

      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* --------- industries  section ----------- */}
      <IndustryServe
        industryWeServeData={industryWeServeData}
        serviceIndustry={serviceIndustry}
      />

      {/* ----------- what we do  section ------------------ */}
      <section id="experience-services">
        <div className="container">
          <h2
            className="dgt-experience-head-main-title text-center"
            id="experience-security-service-head-effect"
          >
            {whatWeDoTopData.Heading}
          </h2>
          <p
            className="dgt-experience-head-main-para text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(whatWeDoTopData.description),
            }}
          ></p>
          <div className="row d-flex justify-content-center">
            {filteredServices.slice(0, visibleCount).map((i) => (
              <div
                className="col-lg-4 col-md-4 col-sm-12 experience-services-div-main"
                data-aos="fade-up"
                key={i.id}
              >
                <div className="card dgt-experience-service-crd-main">
                  <div className="dgt-experience-service-crd-img-main">
                    <Link to={i.page_link}>
                      <img
                        src={i.service_img}
                        alt={i.service_img_alt}
                        className="card-img-top"
                      />
                    </Link>
                  </div>
                  <div className="card-body">
                    <Link to={i.page_link}>
                      <h3>{i.title}</h3>
                    </Link>
                    <p
                      className="card-text"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                    <div className="dgt-experience-service-small-box-down">
                      <Link to={i.page_link}>
                        <FontAwesomeIcon icon={faArrowRightLong} />{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {filteredServices.length > 3 && (
            <div className="text-center btn-div-dgt-experience-it-main">
              <button
                onClick={toggleCardsServiceExperience}
                className="load-more-dgt-experience-it-btn"
              >
                {!areCardsVisibleServiceExperience ? "Load More" : "Load Less"}
              </button>
            </div>
          )}
        </div>
      </section>

      {/* -------------PRODUCTS AND SOLUTIONS section ------------- */}
      <section id="experience-solutions" className="experience-benefits-sec">
        <div className="container">
          <h5>{solutionTitle.pre_heading}</h5>
          <h2
            dangerouslySetInnerHTML={{
              __html: he.decode(solutionTitle.Heading),
            }}
          ></h2>
          <div
            className="dgtexp-solutions-accordion-main accordion accordion-flush"
            id="accordionFlushExample"
          >
            {solution && solution.length > 0
              ? solution.map((i, index) => (
                  <div className="accordion-item" key={index}>
                    <h3
                      className="accordion-header dgtexp-solutions-accordion-quest"
                      id={`flush-heading${index}`}
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        <span>0{index + 1}.</span> {i.title}
                      </button>
                    </h3>
                    <div
                      id={`flush-collapse${index}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby={`flush-heading${index}`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* -------------BENEFITS section ------------- */}
      <section id="experience-benefits" className="experience-benefits-sec">
        <span className="shadow-img-segments-right">
          <img src={IMG.Bg4} alt="img" />
        </span>
        <span className="shadow-img-segments-left">
          <img src={IMG.Bg4} alt="img" />
        </span>
        <div className="container">
          <h5>{benefitsData.pre_heading}</h5>
          <h2
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsData.Heading),
            }}
          ></h2>
          <div className="experience-benefits-content">
            <ul
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsData.description),
              }}
            ></ul>
          </div>
        </div>
      </section>

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default DigitalExperience;

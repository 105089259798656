import React, { Fragment, useContext, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";
const AppDataMigrationServices = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    loading,
    bannerData,
    mainAboutData,
    mainServicesData,
    pagesData,
    ourExpertiseData,
    featureImages,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 9 && i.page_id === 32),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 9 && i.page_id === 32),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 96),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 9 && i.page_id === 32),
    [mainServicesData]
  );

  const expertiseTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 97),
    [pagesData]
  );

  const expertise = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 9 && i.page_id === 32),
    [ourExpertiseData]
  );

  const expertiseSingleImg = useMemo(
    () => featureImages.find((i) => i.page_id === 32 && i.section_id === 97),
    [featureImages]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.page_id === 32 && i.section_id === 96),
    [segmentData]
  );
  if (!expertiseTitleDes) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/* {first && (
        <section
          className="cloud-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="cloud-div-banner-title">
                  <h2
                    className="cloud-banner-heading cloud-banner-heading-app-data text-white"
                    data-aos="fade-down"
                  >
                    {first.title}
                  </h2>
                  <p
                    className="cloud-banner-para text-white"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>
                <div className="cloud-inner-banner-bg-btn cloud-inner-banner-bg-btn-app-data">
                  <a href="#migration">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="cloud-inner-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#migration"} />

      <BreadCrumb
        url={"/cloud-services"}
        pageTitle={"Cloud Services"}
        currentPage={"App & Data Migration Services"}
      />

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* -------- migration cloud section ------------- */}
      <section className="migration-cloud-service-sec" id="migration">
        <div className="container">
          <h2
            className="cloud-head-main-title"
            id="app-datamig-cloud-about-effect"
            dangerouslySetInnerHTML={{
              __html: he.decode(expertiseTitleDes.Heading),
            }}
          ></h2>
          <p
            className="cloud-head-main-para"
            dangerouslySetInnerHTML={{
              __html: he.decode(expertiseTitleDes.description),
            }}
          ></p>
          <div className="row mt-4">
            <div
              className="col-lg-7 col-md-6 col-sm-12 migration-cloud-data-intent"
              data-aos="fade-left"
            >
              <div className="migration-experts-cloud-content">
                <div className="row">
                  {expertise && expertise.length > 0
                    ? expertise.map((i) => (
                        <Fragment key={i.id}>
                          <div className="col-lg-2 col-md-1 col-sm-12 ">
                            <div className="migration-experts-cloud-content-first">
                              <img src={i.expertise_img} alt="img" />
                            </div>
                          </div>
                          <div className="col-lg-10 col-md-5 col-sm-12 ">
                            <div>
                              <h5>{i.title}</h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: he.decode(i.description),
                                }}
                              ></p>
                            </div>
                          </div>
                        </Fragment>
                      ))
                    : null}
                </div>
              </div>
            </div>
            {expertiseSingleImg && (
              <div
                className="col-lg-5 col-md-6 col-sm-12"
                id="migration-experts-cloud-img-container"
                data-aos="fade-right"
              >
                <div className="migration-experts-cloud-img">
                  <img
                    src={expertiseSingleImg.single_pic}
                    alt={expertiseSingleImg.pic_alt}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* ------ section What we Think ----------------- */}
      {segment ? (
        <section id="cloud-what-we-think">
          <div class="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                <h2
                  className="cloud-head-main-title"
                  id="app-datamig-cloud-about-effect"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.title),
                  }}
                ></h2>
                <p
                  class="cloud-head-main-para"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div
                  className="cloud-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${segment.image})`,
                  }}
                ></div>
                <span className="cloud-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ---------  latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends in business process automation with our
            dynamic insights. Learn about the most recent developments in the
            field, as well as successful stories and best practices, to enable
            your company to prosper in the digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.BpoBlogLatest}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Business Outsourcing Makes Businesses More Resilient for
                Long-Term Growth
              </h2>
              <p className="recent-blogs-slider-desc">
                Businesses that are resilient can navigate setbacks adeptly,
                preserving operations and reputations through swift adaptation.
                The key lies in agile responses, with digital tools playing an
                increasingly crucial role in fostering flexibility. Despite
                economic uncertainty, most IT budgets are on the rise, as
                indicated by 80% planning increased spending, while only 11%
                anticipate cuts. This resilience is attributed to the strategic
                use of resources and technology, reinforcing the vital
                connection between adaptability, digitalization, and sustained
                business success.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Data Governance Matters: How to Keep Your Data Clean
                            and Accurate?
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Data governance enhances data quality, reliability,
                          and security, ensuring accurate decision-making and
                          service delivery...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppDataMigrationServices;

import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const MultiCulturalMarketing = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    blogData,
    mainServicesData,
    pagesData,
    solutionData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 13 && i.page_id === 49),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 13 && i.page_id === 49),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 13 && i.page_id === 49),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 124),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 13 && i.page_id === 49),
    [mainServicesData]
  );

  const solution = useMemo(
    () => solutionData.find((i) => i.section_id === 124 && i.page_id === 49),
    [solutionData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.section_id === 124 && i.page_id === 49),
    [segmentData]
  );
  if (loading) return <Loader />;

  return (
    <>
      {/* ---------------- banner section --------------- */}

      {/* {first && (
        <section
          className="dgt-experience-banner-main dgt-experience-banner-overlay "
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="dgt-experience-div-banner-title">
                  <h2
                    className="dgt-experience-banner-heading text-white"
                    data-aos="fade-down"
                  >
                    {first.title}
                  </h2>
                  <p
                    className="dgt-experience-banner-para text-white"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>
                <div className="content_marketing-dge-banner-bg-btn">
                  <a href="#services">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="content_marketing-dge-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/digital-experience"}
        pageTitle={"Digital Experiences"}
        currentPage={"Multi-Cultural Marketing Strategies"}
      />

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/*--------------------------- dge result driven section----------------------- */}

      {solution ? (
        <section className="dge-driven-sec">
          <div className="container">
            <div className="row dge-driven-section">
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 dge-driven-img">
                <img src={solution.image} alt={solution.image_alt} />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div className="dge-driven-content">
                  <h2
                    className="dgt-experience-head-main-title user-expr-head-fosize span"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(solution.title),
                    }}
                  ></h2>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: he.decode(solution.description),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <CtaStripe />

      {/* -------------------section What we Think start------------------------------ */}
      {segment ? (
        <section id="dge-cm-what-we-think">
          <div class="container">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-up-right"
              >
                <h2
                  className="dgt-experience-head-main-title "
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.title),
                  }}
                ></h2>
                <p
                  class="dgt-experience-head-main-para "
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-up-left"
              >
                <div
                  className="cyber-security-sub-under-img-right dge-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${segment.image})`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default MultiCulturalMarketing;

import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import he from "he";
import BlogContext from "../../context/blogContext";

const Career = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [jobs, setJobs] = useState([]);
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    experience: "",
    emp_type: "",
    resume: null,
  });

  const [ourbenefits, setOurbenefits] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(
          "https://admin.benosupport.com/api/job-list",
          {
            signal: controller.signal,
          }
        );
        const result = response.data.data;
        setJobs(result);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching blog data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const { name, email, phone, experience, emp_type, resume } = formdata;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormdata((prevState) => ({
      ...prevState,
      file: event.target.files[0],
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!formdata.file) {
      setError(true);
      return;
    }

    const data = new FormData();
    data.append("name", formdata.name);
    data.append("email", formdata.email);
    data.append("phone", formdata.phone);
    data.append("experience", formdata.experience);
    data.append("emp_type", formdata.emp_type);
    data.append("resume", formdata.file);

    try {
      setError(false);
      setLoading(true);
      const res = await axios.post(
        "https://admin.benosupport.com/api/career-send",
        data
      );

      setLoading(false);
      setFormdata({
        name: "",
        email: "",
        phone: "",
        experience: "",
        emp_type: "",
        resume: null,
      });
      setMsg("Thank you for Applying. Our team will reply to you soon");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("Failed to send message. Please try again later.");
      setLoading(false);
      setError(true);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  const isNameValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(name);
  };
  const isEmailValid = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isMobileNumberValid = () => {
    const phonePattern = /^\d{1,14}$/;
    return phonePattern.test(phone);
  };

  const isRoleValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(emp_type);
  };

  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");
  const [searchResult, setSearchResult] = useState(jobs);

  useEffect(() => {
    setSearchResult(jobs);
  }, [jobs]);

  const findJob = (e) => {
    e.preventDefault();
    const filteredJob = jobs.filter((job) => {
      const matchesKeyword =
        !keyword ||
        job.job_title.toLowerCase().includes(keyword.toLowerCase()) ||
        job.job_cat.toLowerCase().includes(keyword.toLowerCase());
      const matchesLocation =
        !location || job.address.toLowerCase().includes(location.toLowerCase());
      return matchesKeyword && matchesLocation;
    });
    setSearchResult(filteredJob);
  };
  const clearFilter = () => {
    setLocation("");
    setKeyword("");
    setSearchResult(jobs);
  };
  const { bannerData } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 29 && i.page_id === 112),
    [bannerData]
  );
  const [first] = bpoBanner;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          "https://admin.benosupport.com/api/cap-benefits"
        );

        const result = res.data.data;
        setOurbenefits(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const ourbenefitsFilter = useMemo(
    () => ourbenefits.filter((i) => i.category_id === 29 && i.page_id === 112),
    [ourbenefits]
  );

  return (
    <>
      {/*-------------- banner section --------------*/}
      {first ? (
        <section
          id="career-banner"
          className="career-main-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="Career-bet-content">
              <h3 data-aos="zoom-in" className="career-main-banner-head">
                {first.title}
              </h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(first.description),
                }}
              ></p>
            </div>
          </div>
        </section>
      ) : null}

      {/*======================= career page fing job section=================== */}
      <section id="find-job-career">
        <div className="container text-center">
          <div className="career-find-job-head">
            <h3> Let’s search for your preferred job opportunity!</h3>
          </div>
          <div>
            <form className="career-find-job" onSubmit={findJob}>
              <div className=" career-search-form">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                  <input
                    type="text"
                    className="form-control mb-2 form-field-height keyword-search-career"
                    id="searchKeyword"
                    placeholder="Search for job keywords..."
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                  <input
                    type="text"
                    className="form-control form-field-height mb-2"
                    id="searchLocation"
                    placeholder="Search for location (city)"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <button type="submit" className="btn  career-search-form-btn">
                    Find Job
                  </button>
                  <button
                    className="career-clear-form-btn"
                    onClick={clearFilter}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section id="job-sec">
        <div className="container">
          <div className="career-main-head">
            <h3 className="text-center">Latest Job Openings</h3>
          </div>
          {searchResult.length > 0 ? (
            searchResult.map((job) => (
              <div key={job.id} className="career-job-opening-sec">
                <div className="row career-opening-content-box">
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 career-opening-img">
                    <Link to={`/career-detail/${job.job_id}`}>
                      <img
                        className="career-opening-sec-img"
                        src={job.job_img}
                        alt={job.job_img}
                      />
                    </Link>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 career-opening-all-content">
                    <div className="career-opening-upper-content row">
                      <h4 className="col-6">{job.job_cat}</h4>
                      <p className="col-6">Status : {job.job_type}</p>
                    </div>
                    <div className="career-opening-content">
                      <Link to={`/career-detail/${job.job_id}`}>
                        <h3>{job.job_title}</h3>
                      </Link>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(job.short_desc),
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h2 className="text-center">Jobs Not Found</h2>
          )}
        </div>
      </section>

      {/*=========== Find jobs as per skills ============*/}

      <section id="skills-sec">
        <div className="container">
          <div className="career-main-head">
            <h3 className="text-center">Find Jobs as per Skills</h3>
          </div>
          <div className="row d-flex justify-content-center">
            {jobs.slice(0, 8).map((i) => (
              <div
                key={i.id}
                className="col-lg-3 col-md-3 col-sm-12 career-main-skills-box-content"
              >
                <Link to={`/career-detail/${i.job_id}`}>
                  <h2 data-aos="fade-down">{i.job_title}</h2>
                </Link>
                <p data-aos="fade-down">
                  {i.created_at.split(":")[0].slice(0, 10)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* ----------career page our benifits section----------- */}
      <section id="Career-benefits">
        <div className="container">
          {/* service area heading */}
          <div className="career-main-head">
            <h3 className="text-center">Our Benefits</h3>
          </div>

          {/* service area cards */}
          <div className="row">
            {ourbenefitsFilter && ourbenefitsFilter.length > 0
              ? ourbenefitsFilter.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 career-main-benifit-content"
                    key={i.id}
                  >
                    <h2 data-aos="fade-left">{i.title}</h2>
                    <p
                      data-aos="fade-left"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ------------------------- career page post resume section --------------------- */}
      <section
        id="career-post-resume"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            "/assets/img/main-site/world-map-effect.png"
          })`,
        }}
      >
        <div className="container">
          <div className="career-main-head">
            <h3 className="text-center">Post Your Resume</h3>
          </div>
          <form onSubmit={submitHandler}>
            <div className="d-flex align-items-center career-post-resume-form row">
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control mb-2 form-field-height keyword-search-career"
                  id="career-resume-name"
                  placeholder="Full Name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  required
                />
                {name.trim().length > 0 && !isNameValid() && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Name must contain only letters.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="email"
                  className="form-control form-field-height mb-2"
                  id="career-resume-email"
                  placeholder="E-Mail"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  required
                />
                {email.trim().length > 0 && !isEmailValid() && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Please enter a valid email address.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control form-field-height mb-2"
                  id="career-resume-contact"
                  placeholder="Mobile Number"
                  name="phone"
                  onChange={handleChange}
                  value={phone}
                  required
                />
                {phone.trim().length > 0 && !isMobileNumberValid() && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Mobile number must be at least 10 characters.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="number"
                  className="form-control form-field-height mb-2"
                  id="career-resume-experience"
                  placeholder="Experience in Years"
                  name="experience"
                  value={experience}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control form-field-height mb-2"
                  id="career-resume-user-type"
                  placeholder="Designation"
                  name="emp_type"
                  value={emp_type}
                  onChange={handleChange}
                  required
                />
                {emp_type.trim().length > 0 && !isRoleValid() && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Designation must contain only Alphabets.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="file"
                  className="form-control form-field-height mb-2"
                  id="career-resume-upload"
                  placeholder="Upload Resume"
                  value={resume}
                  name="Upload Resume"
                  onChange={handleFileChange}
                  accept=".pdf, .doc, .docx"
                  required
                />
              </div>
            </div>
            <div className="text-center career-resume-submit-btn">
              <button
                type="submit"
                className="btn  career-post-resume-form-btn "
              >
                {loading ? "Sending" : "Send Data"}
              </button>
            </div>
          </form>
        </div>
        <div className="home-notification-msg">
          {msg && <span className="text-center">{msg}</span>}
        </div>
      </section>
    </>
  );
};

export default Career;

import React, { useState } from "react";
import he from "he";
import LazyLoad from "react-lazyload";

const MainAbout = ({ mainAboutData }) => {
  const charLimit = 600;
  const decodedDescription = mainAboutData?.description
    ? he.decode(mainAboutData.description)
    : "";
  const [isReadMore, setIsReadMore] = useState(false);
  const shouldTruncate = decodedDescription.length > charLimit;
  const displayedDescription = isReadMore
    ? decodedDescription
    : decodedDescription.substring(0, charLimit) +
      (shouldTruncate ? "..." : "");

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (!mainAboutData) return null;

  return (
    <section id="ai-business" className="dgt-whats-possible">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="dgt-whats-possible-head">
              <h2
                className="dgt-sub-section-heading dgt-sub-section-heading-main"
                id="digital-trans-effect"
                dangerouslySetInnerHTML={{
                  __html: he.decode(mainAboutData.title),
                }}
              ></h2>

              <p
                className="dgt-sub-section-sub-heading"
                dangerouslySetInnerHTML={{ __html: displayedDescription }}
              ></p>
              {shouldTruncate && (
                <button onClick={toggleReadMore} id="travel-read-btn-about">
                  {isReadMore ? "Read Less" : "Read More"}
                </button>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="under-img-right-dgt">
              <LazyLoad height={200} offset={100}>
                <img
                  src={mainAboutData.about_img}
                  className="under-img-right-dgt"
                  alt={mainAboutData.about_img_alt}
                />
              </LazyLoad>
            </div>

            <span className="dgt-triangle-down"></span>
            <div className="under-img-lower-txt-dgt">
              <div className="dgt-ai-front-txt" data-aos="fade-up">
                <h6>{mainAboutData.pre_head_fisrt}</h6>
                <h4>{mainAboutData.heading_fisrt}</h4>
                <p>{mainAboutData.paragraph_fisrt}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainAbout;

import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import IndustyAbout from "../../../components/IndustyAbout";
import IndusBenoProvides from "../../../components/IndusBenoProvides";
import he from "he";

const TravelTourism = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".travel-serv-page-index");
    const stickyDiv = "sticky-travel";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    mainServicesData,
    benefitsData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 25 && i.page_id === 101),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () =>
      mainAboutData.filter((i) => i.category_id === 25 && i.page_id === 101),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 67),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 25 && i.page_id === 101),
    [mainServicesData]
  );

  const capabilitiesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 68),
    [pagesData]
  );

  const capabilitiesData = useMemo(
    () => benefitsData.filter((i) => i.category_id === 25 && i.page_id === 101),
    [benefitsData]
  );

  const growthTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 221),
    [pagesData]
  );
  const segment = useMemo(
    () => segmentData.find((i) => i.section_id === 221 && i.page_id === 101),
    [segmentData]
  );
  if (!capabilitiesTitleDes || !growthTitleDes || !segment) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/*-------------- banner section --------------*/}
      {first ? (
        <section
          id="travel-ind-main-banner"
          className="travel-ind-banner travel-ind-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="travel-ind-bet-content travel-advisory-banner-content travel-advisory-banner-content-main">
              <h3 data-aos="zoom-in" className="travel-ind-main-banner-head ">
                {first.title}
              </h3>
              <p></p>
            </div>

            <div className="travel-ind-banner-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-ind-travel">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}

      {/* ------------ travel service page indexing section -------------- */}
      <section className="travel-serv-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="travel-serv-index-nav">
                <ul>
                  <li>
                    <a
                      className={`travel-serv-ind-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`travel-serv-ind-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`travel-serv-ind-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`travel-serv-ind-item ${
                        current === "overview" ? "active" : ""
                      }`}
                      href="#overview"
                      aria-label="overview?"
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className={`travel-serv-ind-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/travel-tourism"}> Travel and Tourism </Link>
        </div>
      </section>

      <IndustyAbout industryAboutData={dgtMainAboutArray} />

      <IndusBenoProvides
        indusHeadDesc={serviceTitleDes}
        benoProvides={serviceData}
      />

      {/* -------- our capabilities section start -------- */}
      <section id="services" className="travel-ind-capabilities-sec">
        <div className="container">
          <div>
            <h2
              className="travel-section-heading text-center travel-effortslessly-effect"
              dangerouslySetInnerHTML={{
                __html: he.decode(capabilitiesTitleDes.Heading),
              }}
            ></h2>
            <p
              className="travel-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(capabilitiesTitleDes.description),
              }}
            ></p>
          </div>
          <div className="row mt-4">
            {capabilitiesData && capabilitiesData.length > 0
              ? capabilitiesData.map((i) => (
                  <div
                    id="travel-ind-business-areas-main"
                    data-aos="fade-up-left"
                    className="col-lg-4 col-md-4 col-sm-12"
                    key={i.id}
                  >
                    <div className="travel-ind-inner-area-customer-main">
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/*-----------------sustainable growth section----------  */}
      <section class="travel-tourism-establishing" id="overview">
        <div class="container">
          <h2
            class="tourism-establishing-section-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(growthTitleDes.Heading),
            }}
          ></h2>
          <p
            class="travel-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(growthTitleDes.description),
            }}
          ></p>
          <div class="row">
            <div class="col-lg-5 col-md-6 ">
              <ul
                class="inner-travel-tourism-establishing"
                dangerouslySetInnerHTML={{
                  __html: he.decode(segment.description),
                }}
              ></ul>
            </div>
            <div
              class="col-lg-7 col-md-6 col-sm-12 aos-init"
              id="ind-travel-we-think-second"
              data-aos="fade-left"
            >
              <img
                src={segment.image}
                class="ind-travel-we-think-img"
                alt={segment.image_alt}
              />
              <span class="ind-travel-sub-triangle-down"></span>
            </div>
          </div>
        </div>
      </section>
      {/* --------- Bpo service latest one blog section  ----------- */}

      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Dive into this blog to discover why outsourcing travel services to a
            reliable BPO partner can transform your business. Explore recent
            insights!
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.BpoBlogLatest}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                How the BPO industry enhances your travel and tourism services
              </h2>
              <p className="recent-blogs-slider-desc">
                Dive into this blog to discover why outsourcing your travel and
                tourism services to a reliable BPO partner is a game-changer.
                Experience cost savings, heightened efficiency, and superior
                customer service. With seasoned professionals at the helm,
                optimize processes, cut costs, and streamline operations. This
                strategic move not only enhances the customer experience but
                also allows you to refocus efforts on core business aspects,
                driving growth and success in the competitive travel industry.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Revolutionizing Travel: Exploring the Role of
                            Application Services in Tourism
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how application services are reshaping the
                          travel industry, from mobile apps for booking to
                          AI-driven travel planning tools.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            AI in Travel: Transforming the Future of Tourism
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how artificial intelligence is
                          revolutionizing the travel industry, from personalized
                          trip recommendations to chatbots for instant customer
                          support.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Optimizing Operations: The Impact of Application
                            Services on Travel and Tourism
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Delve into the impact of application services on
                          operational efficiency in the travel industry.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Revolutionizing Travel: Exploring the Role of
                            Application Services in Tourism
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how application services are reshaping the
                          travel industry, from mobile apps for booking to
                          AI-driven travel planning tools.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            AI in Travel: Transforming the Future of Tourism
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how artificial intelligence is
                          revolutionizing the travel industry, from personalized
                          trip recommendations to chatbots for instant customer
                          support.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TravelTourism;

import React, { useContext, useMemo, useState } from "react";
import { CtaStripe } from "../pages/Services/ApplicationService/ApplicationServices";
import BlogContext from "../context/blogContext";
import Loader from "../components/Loader";
import IndustryBanner from "../components/IndustryBanner";
import IndustyAbout from "../components/IndustyAbout";
import he from "he";

const Fintech = () => {
  const { bannerData, loading, mainAboutData, pagesData } =
    useContext(BlogContext);

  const fintechBanner = useMemo(
    () => bannerData.find((i) => i.category_id === 14 && i.page_id === 120),
    [bannerData]
  );

  const fintechAbout = useMemo(
    () => mainAboutData.find((i) => i.category_id === 14 && i.page_id === 120),
    [mainAboutData]
  );

  const fintechTitle1 = useMemo(
    () => pagesData.find((i) => i.id === 222),
    [pagesData]
  );

  const fintechTitle2 = useMemo(
    () => pagesData.find((i) => i.id === 223),
    [pagesData]
  );

  if (!fintechTitle1 || !fintechTitle2) return null;

  return (
    <>
      {loading && <Loader />}
      {/*-------------- banner section --------------*/}
      {/* <section
        id="travel-ind-main-banner"
        className="travel-ind-banner travel-ind-banner-unique"
        style={{
          backgroundImage: `url(${IMG.FintechBanner})`,
        }}
      >
        <div className="container">
          <div className="travel-ind-bet-content bpo-travel-banner-content">
            <h3 data-aos="zoom-in" className="travel-ind-main-banner-head">
              Fintech Reshaping Banking
            </h3>
            <p>
              Financial Technology is reshaping the landscape of banking and
              financial services, driving innovation, efficiency, and
              customer-centric solutions.
            </p>
          </div>
          <div className="travel-ind-banner-btn">
            <a href="#">Learn More</a>
            <Link to="tel: +91-892-988-4560" className="bnr-nbtn-ind-travel">
              892-988-4560
            </Link>
          </div>
        </div>
      </section> */}

      <IndustryBanner
        industyBanner={fintechBanner}
        indexId={"#insights"}
        blackTitle
      />

      <IndustyAbout industryAboutData={fintechAbout} />

      {/* ---------bpo travel at its best section */}
      <section className="bpo-travel-best" id="insights">
        <div className="container">
          <div className="row">
            <div className="bpo-travel-best-inner col-sm-12 col-lg-12 col-md-12">
              <h2
                dangerouslySetInnerHTML={{
                  __html: he.decode(fintechTitle1.Heading),
                }}
              ></h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(fintechTitle1.description),
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>
      {/* ---------bpo travel back front */}
      <section className="bpo-travel-back-front">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12  bpo-travel-best-under">
              <h2
                dangerouslySetInnerHTML={{
                  __html: he.decode(fintechTitle2.Heading),
                }}
              ></h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(fintechTitle2.description),
                }}
              ></p>
            </div>
          </div>
        </div>
      </section>

      <CtaStripe />
    </>
  );
};

export default Fintech;

import React, { useContext, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import IMG from "../../../layouts/ImagesOld2";
import { CtaStripe } from "./ApplicationServices";
import he from "he";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const ApplicationLifecycle = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    mainServicesData,
    segmentData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 11 && i.page_id === 41),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 11 && i.page_id === 41),
    [bannerData]
  );
  const [first, rest] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 11 && i.page_id === 41),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 114),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 11 && i.page_id === 41),
    [mainServicesData]
  );

  const singleSegment = useMemo(
    () =>
      segmentData.find(
        (i) =>
          i.page_id === 41 &&
          i.section_id === 114 &&
          i.segment_type === "single-segment"
      ),
    [segmentData]
  );

  const multiSegment = useMemo(
    () =>
      segmentData.filter(
        (i) =>
          i.page_id === 41 &&
          i.section_id === 114 &&
          i.segment_type === "multi-segment"
      ),
    [segmentData]
  );

  if (loading) return <Loader />;
  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/software-services"}
        pageTitle={"Software Services"}
        currentPage={"Application Lifecycle"}
      />

      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {singleSegment ? (
        <section
          id="sales-what-we-do"
          className="What-we-do"
          style={{
            backgroundImage: `url(${IMG.BpoUnderBack})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="what-we-heading">
                  <h2
                    className="application-services-sub-section-heading"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(singleSegment.title),
                    }}
                  ></h2>
                  <p
                    className="application-services-sub-section-sub-heading"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(singleSegment.description),
                    }}
                  ></p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="application-services-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${singleSegment.image})`,
                  }}
                ></div>

                <span className="application-services-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <CtaStripe />

      {/* ------------ aplication lifecycle  what we think second-------------*/}
      <section id="aps-transformation-quality-enng">
        <div className="container">
          <div className="row" id="aps-trans-row">
            {multiSegment && multiSegment.length > 0
              ? multiSegment.map((i) => (
                  <div
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
                    data-aos="flip-up"
                    key={i.id}
                  >
                    <div className="card aps-trans-card">
                      <img
                        src={i.image}
                        className="aps-trans-think-card-img"
                        alt={i.image_alt}
                      />
                      <span className="aps-trans-triangle-down"></span>
                      <div className="card-body">
                        <p
                          className="aps-trans-under-txt"
                          id="lifecycle-app-think-cat"
                        >
                          {i.pre_heading}
                        </p>
                        <h5 className="card-title aps-trans-under-head">
                          {i.title}
                        </h5>
                        <p
                          className="card-text aps-trans-under-para"
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ----------------  latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default ApplicationLifecycle;

import React, { useContext, useMemo, Fragment } from "react";
import { CtaStripe } from "./ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const WebAppDevelopment = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    ourExpertiseData,
    pagesData,
    mainServicesData,
    benefitsData,
    solutionData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 11 && i.page_id === 36),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 11 && i.page_id === 36),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 11 && i.page_id === 36),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const expertiseTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 101),
    [pagesData]
  );

  const expertise = useMemo(
    () =>
      ourExpertiseData.find((i) => i.category_id === 11 && i.page_id === 36),
    [ourExpertiseData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 100),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 11 && i.page_id === 36),
    [mainServicesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 11 && i.page_id === 36),
    [benefitsData]
  );
  const benefitsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 102),
    [pagesData]
  );

  const solutionTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 115),
    [pagesData]
  );

  const solution = useMemo(
    () => solutionData.filter((i) => i.section_id === 115 && i.page_id === 36),
    [solutionData]
  );
  if (
    !expertise ||
    !expertiseTitleDes ||
    !benefitsTitleDes ||
    !solutionTitleDes
  )
    return null;
  if (loading) return <Loader />;

  return (
    <>
      <ServiceBanner
        serviceBanner={first}
        indexId={"#why-choose-web-app"}
        blackTitle
      />

      <BreadCrumb
        url={"/software-services"}
        pageTitle={"Software Services"}
        currentPage={"Web Application Development"}
      />

      {/*------------------ About Section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/*-------------- why choose Section ----------------*/}
      <section className="why-choose-web-app" id="why-choose-web-app">
        <div className="container">
          <h2
            className="application-sub-section-heading-spec text-center"
            id="app-srvice-head-bg"
            dangerouslySetInnerHTML={{
              __html: he.decode(expertiseTitleDes.Heading),
            }}
          ></h2>

          <div className="row mt-lg-4 mt-md-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="why-choose-web-devlopment-app-content-fst">
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(expertiseTitleDes.description),
                  }}
                ></p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="why-choose-web-devlopment-app-content-snd">
                <ul
                  dangerouslySetInnerHTML={{
                    __html: he.decode(expertise.description),
                  }}
                ></ul>
              </div>
            </div>
          </div>
          <h6
            dangerouslySetInnerHTML={{
              __html: he.decode(expertise.title),
            }}
          ></h6>
        </div>
      </section>

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      <CtaStripe />

      {/* ----------- Service section ---------- */}
      <section className="how-we-develop-sec">
        <div className="container">
          <h2
            className="application-services-head-main-title"
            id="app-datamig-application-services-about-effect"
            dangerouslySetInnerHTML={{
              __html: he.decode(solutionTitleDes.Heading),
            }}
          ></h2>
          <p
            className="application-services-sub-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(solutionTitleDes.description),
            }}
          ></p>
          <div className="row develop-web-app-how">
            {solution && solution.length > 0
              ? solution.map((i, index) => (
                  <Fragment key={i.id}>
                    <div className="col-lg-1 col-md-2 col-sm-12 wap-data-number">
                      <div>
                        <h3>
                          {index + 1}/{solution.length}
                        </h3>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-8 col-sm-12 wap-data-title">
                      <div>
                        <h2>{i.title}</h2>
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12 wap-data-desc">
                      <div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </Fragment>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ----------- Custom web app section ---------- */}
      <section className="how-we-develop-sec">
        <div className="container">
          <h2
            className="application-services-head-main-title"
            id="app-datamig-application-services-about-effect"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitleDes.Heading),
            }}
          ></h2>
          <div className="row custom-web-app-how">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div className="col-lg-4 col-md-6 col-sm-12" key={i.id}>
                    <div className="custom-webapp-content">
                      <h3>{i.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default WebAppDevelopment;

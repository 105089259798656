import React, { useContext, useMemo } from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faLongArrowAltRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import IMG from "../../../layouts/ImagesOld2";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import BlogContext from "../../../context/blogContext";
import he from "he";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";

const ItMain = () => {
  const [visibleItems, setVisibleItems] = useState(4);
  const [visibleItems1, setVisibleItems1] = useState(4);
  const [visibleItems2, setVisibleItems2] = useState(4);

  const toggleCardsApp = () => {
    if (visibleItems === 4) {
      setVisibleItems(engServices.length);
    } else {
      setVisibleItems(4);
    }
  };

  const toggleCardsApp1 = () => {
    if (visibleItems1 === 4) {
      setVisibleItems1(dgtServices.length);
    } else {
      setVisibleItems1(4);
    }
  };
  const toggleCardsApp2 = () => {
    if (visibleItems2 === 4) {
      setVisibleItems2(dgeServices.length);
    } else {
      setVisibleItems2(4);
    }
  };

  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".it-main-indus-page-index");
    const stickyDiv = "sticky-it";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    ourExpertiseData,
    techLogoData,
    mainServicesData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 16 && i.page_id === 58),
    [bannerData]
  );
  const [first] = bpoBanner;
  const [techtab, setTechtab] = useState(0);
  const handleTechTab = (index) => {
    setTechtab(index);
  };

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 16 && i.page_id === 58),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const benoProvidesTop = useMemo(
    () => pagesData.find((i) => i.id === 45),
    [pagesData]
  );

  const expertiseTop = useMemo(
    () => pagesData.find((i) => i.id === 46),
    [pagesData]
  );

  const expertiseData = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 16 && i.page_id === 58),
    [ourExpertiseData]
  );

  const techStackTop = useMemo(
    () => pagesData.find((i) => i.id === 47),
    [pagesData]
  );

  const platformTechnologyFilter = useMemo(
    () =>
      mainServicesData.filter((i) => i.tcategory === "platforms-technologies"),
    [mainServicesData]
  );

  const services = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 16 && i.page_id === 58),
    [mainServicesData]
  );

  const engServices = useMemo(
    () => services.filter((i) => i.page_link === "engineering"),
    [services]
  );

  const dgtServices = useMemo(
    () => services.filter((i) => i.page_link === "digital-transformation"),
    [services]
  );

  const dgeServices = useMemo(
    () => services.filter((i) => i.page_link === "digital-experience"),
    [services]
  );

  if (
    !benoProvidesTop ||
    !expertiseTop ||
    !techStackTop ||
    !platformTechnologyFilter
  )
    return null;

  if (loading) return <Loader />;
  return (
    <>
      {/*-------------- banner section --------------*/}
      {first && (
        <section
          id="it-indus-main-banner"
          className="it-main-indus-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="it-indus-main-bet-content it-indus-main-banner-content">
              <h3 data-aos="zoom-in" className="it-indus-main-banner-head">
                {first.title}
              </h3>
            </div>
            <div className="indus-it-banner-main-btn">
              <a href="#technology">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-indus-it">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      )}

      {/* ------------ indexing section -------------- */}
      <section className="it-main-indus-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="it-main-indus-index-nav">
                <ul>
                  <li>
                    <a
                      className={`index-it-main-indus-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-it-main-indus-item ${
                        current === "service" ? "active" : ""
                      }`}
                      href="#service"
                      aria-label="service"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-it-main-indus-item ${
                        current === "expertise" ? "active" : ""
                      }`}
                      href="#expertise"
                      aria-label="Expertise"
                    >
                      Expertise
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-it-main-indus-item ${
                        current === "technology" ? "active" : ""
                      }`}
                      href="#technology"
                      aria-label="Technology"
                    >
                      Technology
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-it-main-indus-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/it"}> IT</Link>
        </div>
      </section>

      {/* -------- about section ----------------- */}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* -------- service section ----------------- */}
      <section className="it-main-service-sec" id="service">
        <div className="container">
          <h2
            className="it-main-section-heading text-center"
            id="it-main-service-sec-effect"
            dangerouslySetInnerHTML={{
              __html: he.decode(benoProvidesTop.Heading),
            }}
          ></h2>
          <p
            className="it-main-section-sub-heading text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(benoProvidesTop.description),
            }}
          ></p>
          <div className="it-main-service-head-add">
            <h3>Engineering & Technology Services</h3>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="row d-flex justify-content-center">
              {engServices && engServices.length > 0
                ? engServices.slice(0, visibleItems).map((i, index) => (
                    <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                      <div className="it-main-service-content">
                        <h4>{i.title}</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                        <div className="it-main-service-content-small-box-down">
                          <a href="#">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              {engServices.length > 4 && (
                <div className="text-center btn-div-segments">
                  <button
                    onClick={toggleCardsApp}
                    className="load-more-indus-applic-btn"
                  >
                    {visibleItems === 4 ? "Load More" : "Load Less"}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="it-main-service-head-add">
            <h3>Digital Transformation</h3>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="row d-flex justify-content-center">
              {dgtServices && dgtServices.length > 0
                ? dgtServices.slice(0, visibleItems1).map((i, index) => (
                    <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                      <div className="it-main-service-content">
                        <h4>{i.title}</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                        <div className="it-main-service-content-small-box-down">
                          <a href="#">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              {dgtServices.length > 4 && (
                <div className="text-center btn-div-segments">
                  <button
                    onClick={toggleCardsApp1}
                    className="load-more-indus-applic-btn"
                  >
                    {visibleItems1 === 4 ? "Load More" : "Load Less"}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="it-main-service-head-add">
            <h3>Digital Experience</h3>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="row d-flex justify-content-center">
              {dgeServices && dgeServices.length > 0
                ? dgeServices.slice(0, visibleItems2).map((i, index) => (
                    <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                      <div className="it-main-service-content">
                        <h4>{i.title}</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                        <div className="it-main-service-content-small-box-down">
                          <a href="#">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              {dgeServices.length > 4 && (
                <div className="text-center btn-div-segments">
                  <button
                    onClick={toggleCardsApp2}
                    className="load-more-indus-applic-btn"
                  >
                    {visibleItems2 === 4 ? "Load More" : "Load Less"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* ---------- Industries cater section ----------- */}
      <section id="expertise" className="it-main-indus-cater-sec">
        <div className="container">
          <div>
            <h2
              className="it-main-section-heading text-center"
              id="it-main-indus-cater-sec-effect"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiseTop.Heading),
              }}
            ></h2>
            <p
              className="it-main-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiseTop.description),
              }}
            ></p>
          </div>

          <div className="row">
            {expertiseData &&
              expertiseData.length > 0 &&
              expertiseData.map((i) => (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="it-main-indus-expertise">
                    <h2 data-aos="fade-left">{i.title}</h2>
                    <p
                      data-aos="fade-left"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <section id="technology" className="it-main-technology-sec">
        <div className="container">
          <h2
            className="it-main-section-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(techStackTop.Heading),
            }}
          ></h2>
          <p
            className="it-main-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(techStackTop.description),
            }}
          ></p>

          <div className="technplogy-tab-container">
            <button>Front End Design</button>
            <button>Databases</button>
            <button>Ecommerce</button>
            <button>Mobile APPs</button>
            <button>Backend</button>
            <button>Cloud</button>
          </div>

          <div class="platform-tech-marquee-container mt-4">
            <div class="platform-tech-marquee">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(0, 4).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>

            {/* shadow */}
            <div class="platform-tech-marquee">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(0, 4).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>
            {/* shadow */}
            <div class="platform-tech-marquee">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(0, 4).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div class="platform-tech-marquee-container mt-4">
            <div class="platform-tech-marquee2">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(4, 8).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>

            {/* shadow  */}
            <div class="platform-tech-marquee2">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(4, 8).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>
            {/* shadow  */}
            <div class="platform-tech-marquee2">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(4, 8).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div class="platform-tech-marquee-container mt-4">
            <div class="platform-tech-marquee">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(8, 12).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>

            {/* shadow  */}
            <div class="platform-tech-marquee">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(8, 12).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>
            {/* shadow  */}
            <div class="platform-tech-marquee">
              {platformTechnologyFilter &&
                platformTechnologyFilter.length > 0 &&
                platformTechnologyFilter.slice(8, 12).map((i) => (
                  <div className="ptm-inr" key={i.id}>
                    <img src={i.tlogo} alt={i.tlogo_alt} />
                    <div>
                      <h5>{i.tname}</h5>
                      <p>{i.special_txt}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>

      {/* ---------------- latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Ut sem odio, interdum eu fermentum et, porta vitae nisi. Vivamus
            tincidunt mattis pharetra. Nulla condimentum in nisl in gravida.
            Donec sed mauris non lacus blandit placerat.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.ItBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Cloud Services: Accelerating Startup Incubation and Growth
              </h2>
              <p className="recent-blogs-slider-desc">
                In today's rapidly evolving business landscape, cloud services
                are proving to be a game-changer for startups, offering
                unparalleled scalability, flexibility, and cost-efficiency. By
                leveraging cloud solutions, startups can accelerate their
                incubation process and foster rapid growth. Whether it's
                streamlining operations, enhancing collaboration, or accessing
                powerful computing resources, the cloud provides a robust
                foundation for innovation. This blog explores how startups can
                harness the potential of cloud services to propel their journey
                from ideation to market success with agility and resilience.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Blockchain Integration boosts E Commerce Security
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Ensuring transparent and secure transactions,
                          enhancing customer trust and data integrity.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            DevOps Strategies streamline Enterprise Solutions
                            Delivery
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Fostering collaboration, automation, and continuous
                          integration for efficient and agile development
                          processes.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Mobile App Development Trends emphasize UX Design
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Prioritizing user-centric experiences to drive
                          engagement, retention, and overall satisfaction.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Blockchain Integration boosts E Commerce Security
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Ensuring transparent and secure transactions,
                          enhancing customer trust and data integrity.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            DevOps Strategies streamline Enterprise Solutions
                            Delivery
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Fostering collaboration, automation, and continuous
                          integration for efficient and agile development
                          processes.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItMain;

import React from "react";
import he from "he";

const IndustryBanner = ({ industyBanner, indexId, blackTitle }) => {
  if (!industyBanner) return null;
  return (
    <section
      className="industy-banner"
      style={{ backgroundImage: `url(${industyBanner.banner_img})` }}
    >
      <div className="industy-banner-content">
        <h1
          data-aos="fade-left"
          className={blackTitle ? "head-text-black" : "text-white"}
          dangerouslySetInnerHTML={{ __html: he.decode(industyBanner.title) }}
        ></h1>
        {industyBanner.description && (
          <p
            className={blackTitle ? "para-text-black" : "text-white"}
            data-aos="fade-right"
            dangerouslySetInnerHTML={{
              __html: he.decode(industyBanner.description),
            }}
          />
        )}
      </div>
      <div className="industy-banner-button">
        {industyBanner.btn_first && (
          <a href={`${indexId || ""}`}>{industyBanner.btn_first}</a>
        )}
        {industyBanner.btn_second && (
          <a href={`tel:${industyBanner.btn_second}`}>
            {industyBanner.btn_second}
          </a>
        )}
      </div>
    </section>
  );
};

export default IndustryBanner;

import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import IndusBenoProvides from "../../../components/IndusBenoProvides";
import he from "he";

const Manufacturing = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".manufacturing-indus-page-index"
    );
    const stickyDiv = "sticky-manufacturing";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const [areCardsVisible, setAreCardsVisible] = useState(true);
  const toggleCards = () => {
    setAreCardsVisible(!areCardsVisible);
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    mainServicesData,
    ourExpertiseData,
    solutionData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 24 && i.page_id === 95),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 24 && i.page_id === 95),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 64),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 24 && i.page_id === 95),
    [mainServicesData]
  );

  const expertiesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 65),
    [pagesData]
  );

  const expertiseData = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 24 && i.page_id === 95),
    [ourExpertiseData]
  );

  const solutionTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 219),
    [pagesData]
  );

  const solution = useMemo(
    () => solutionData.filter((i) => i.section_id === 219 && i.page_id === 95),
    [solutionData]
  );

  if (!expertiesTitleDes || !solutionTitleDes) return null;
  return (
    <>
      {loading && <Loader />}

      {/*-------------- banner section --------------*/}
      {first ? (
        <section
          id="manufacturing-indus-main-banner"
          className="manufacturing-indus-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="manufacturing-indus-content">
              <h3 data-aos="zoom-in">{first.title}</h3>
            </div>
            <div className="manufacturing-indus-banner-btn">
              <a href="#capabilities">{first.btn_first}</a>
              <Link
                to="tel:+91-892-988-4560"
                className="nbtn-manufacturing-indus"
              >
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}

      {/* ------------ page indexing section -------------- */}
      <section className="manufacturing-indus-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="manufacturing-indus-index-nav">
                <ul>
                  <li>
                    <a
                      className={`manufacturing-indus-index-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`manufacturing-indus-index-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`manufacturing-indus-index-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`manufacturing-indus-index-item ${
                        current === "capabilities" ? "active" : ""
                      }`}
                      href="#capabilities"
                      aria-label="overview"
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className={`manufacturing-indus-index-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/manufacturing"}> Manufacturing </Link>
        </div>
      </section>
      {/* -------- about section ----------------- */}

      <MainAbout mainAboutData={dgtMainAboutArray} />
      <IndusBenoProvides
        indusHeadDesc={serviceTitleDes}
        benoProvides={serviceData}
      />

      {/* ---------- Industries cater section ----------- */}
      <section id="services" className="manufacturing-indus-cater-sec">
        <div className="container">
          <div>
            <h2
              className="manufacturing-section-heading text-center industries-cater-head-effect-manufact"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiesTitleDes.Heading),
              }}
            ></h2>
            <p
              className="manufacturing-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiesTitleDes.description),
              }}
            ></p>
          </div>

          {/* service area cards */}
          <div className="row">
            {expertiseData && expertiseData.length > 0
              ? expertiseData.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 manufact-main-indus-cater"
                    key={i.id}
                  >
                    <h2 data-aos="fade-left">{i.title}</h2>
                    <p
                      data-aos="fade-left"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* -------- Engineering Capabilities ----------- */}
      <section id="capabilities" className="engineering-capabilities-manufact">
        <div className="container">
          <h6 className="engineering-capabilities-pre-head">
            {solutionTitleDes.pre_heading}
          </h6>
          <div className="engineering-capabilities-head-div">
            <h2
              className="egg-manufact-sub-section-heading-spec"
              dangerouslySetInnerHTML={{
                __html: he.decode(solutionTitleDes.Heading),
              }}
            ></h2>
          </div>
          {/* customer accordion code */}
          <div
            className="egg-manufac-accordion-main accordion accordion-flush"
            id="accordionFlushExample"
          >
            {solution && solution.length > 0
              ? solution.map((i, index) => {
                  const headingId = `flush-heading-${index}`;
                  const collapseId = `flush-collapse-${index}`;
                  return (
                    <div className="accordion-item" key={index}>
                      <h2
                        className="accordion-header egg-manufac-accordion-quest"
                        id={headingId}
                      >
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${collapseId}`}
                          aria-expanded="false"
                          aria-controls={collapseId}
                          data-aos="fade-up"
                        >
                          <span>0{index + 1}.</span> {i.title}
                        </button>
                      </h2>
                      <div
                        id={collapseId}
                        className="accordion-collapse collapse"
                        aria-labelledby={headingId}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div
                              className="col-lg-5 col-md-5 col-sm-12"
                              id="egg-manufac-accordion-tabs"
                            >
                              <div
                                className="egg-manufac-accordion-img-tabs"
                                style={{
                                  backgroundImage: `url(${i.image})`,
                                }}
                              ></div>
                            </div>
                            <div
                              className="col-lg-7 col-md-7 d-flex align-items-center"
                              id="egg-manufac-col-second-tabs"
                            >
                              <div className="egg-manufac-tabs-accor-content">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: he.decode(i.description),
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </section>

      {/* ---------------- latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends in digital transformation for
            manufacturing and how technology is reshaping the industry in our
            recent blog.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.ManufacturingBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                The Digital Factory: How Technology is Revolutionizing
                Manufacturing Operations
              </h2>
              <p className="recent-blogs-slider-desc">
                Explore how technology is reshaping manufacturing in our blog,
                "The Digital Factory." Discover the latest innovations in
                automation, IoT, and AI, and learn how they're optimizing
                operations, enhancing productivity, and driving efficiency in
                factories worldwide. From smart machines to predictive
                maintenance, we delve into the transformative power of digital
                technologies, offering insights into the future of manufacturing
                and the strategies companies can employ to stay competitive in
                this rapidly evolving landscape
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Smart Manufacturing: Leveraging IoT and AI for
                            Digital Transformation
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how IoT and AI are revolutionizing
                          manufacturing in our blog. Explore how smart
                          technologies optimize processes, improve efficiency...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            From Data to Decisions: Harnessing Analytics for
                            Manufacturing Excellence
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Unlock the power of data analytics in manufacturing
                          with our insightful blog. Learn how data-driven
                          insights empower informed decision-making...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Building a Future-Ready Supply Chain: Digital
                            Transformation Strategies for Manufacturers
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Delve into digital transformation strategies for
                          supply chains in our blog. Explore how advanced
                          technologies and innovative approaches optimize supply
                          chain...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Smart Manufacturing: Leveraging IoT and AI for
                            Digital Transformation
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how IoT and AI are revolutionizing
                          manufacturing in our blog. Explore how smart
                          technologies optimize processes, improve efficiency...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            From Data to Decisions: Harnessing Analytics for
                            Manufacturing Excellence
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Unlock the power of data analytics in manufacturing
                          with our insightful blog. Learn how data-driven
                          insights empower informed decision-making...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Manufacturing;

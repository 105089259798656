import React, { useContext, useMemo } from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IMG from "../../../layouts/ImagesOld2";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { CtaStripe } from "../../Services/ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import Loader from "../../../components/Loader";
import BlogContext from "../../../context/blogContext";
import IndustyAbout from "../../../components/IndustyAbout";
import IndustryBanner from "../../../components/IndustryBanner";
const EdAppService = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [tab, setTab] = useState(1);

  const tabChangehandler = (index) => {
    setTab(index);
  };

  const { bannerData, loading, mainAboutData } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 19 && i.page_id === 73),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 19 && i.page_id === 73),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;
  if (loading) return <Loader />;
  return (
    <>
      <IndustryBanner industyBanner={first} indexId={"#capabilities"} />

      <BreadCrumb
        url={"/education"}
        pageTitle={"Education"}
        currentPage={"Application Service"}
      />

      <IndustyAbout industryAboutData={dgtMainAboutArray} />

      {/* -------- cloud Highlights section ----------------- */}
      <section className="education-could-highlights-sec">
        <div className="container">
          <h2 className="education-section-heading">
            Empowering
            <span> Education</span>
          </h2>
          <div className="education-could-highlights-content">
            <ul>
              <li>
                Applications in education enhance learning through interactive
                content and personalized experiences.
              </li>
              <li>
                {" "}
                They enable access to educational resources anytime, anywhere,
                fostering flexible learning.
              </li>
              <li>
                Communication tools in applications facilitate seamless
                interaction among students, teachers, and parents.
              </li>
              <li>
                Administrative tasks are streamlined with automated features,
                saving time and reducing paperwork.
              </li>
              <li>
                Data analytics provide valuable insights for personalized
                instruction and intervention.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <CtaStripe />

      {/* -------- Engineering Capabilities ----------- */}
      <section id="capabilities" className="engineering-capabilities-education">
        <div className="container">
          <h6 className="engineering-capabilities-pre-head">
            PRODUCTS AND SOLUTIONS
          </h6>
          <div className="engineering-capabilities-head-div">
            <h2 className="egg-education-sub-section-heading-spec">
              Spotlight <span>Our New Engineering</span> Capabilities
            </h2>
          </div>
          {/* customer accordion code */}
          <div className="egg-education-accordion-main accordion accordion-flush">
            {/* first accordion- */}
            <div class="accordion-item">
              <h2
                class="accordion-header egg-education-accordion-quest"
                id="flush-headingOne"
              >
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#engineering-serv"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                  data-aos="fade-up"
                >
                  <span>01.</span> Tailored Solutions
                </button>
              </h2>
              <div
                id="engineering-serv"
                class="accordion-collapse collapse show"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div class="row">
                    <div
                      class="col-lg-5 col-md-5 col-sm-12"
                      id="egg-education-accordion-tabs"
                    >
                      <div
                        class="egg-education-accordion-img-tabs"
                        style={{
                          backgroundImage: `url(${IMG.EducationApsFaq1})`,
                        }}
                      ></div>
                    </div>
                    <div
                      class="col-lg-7 col-md-7"
                      id="egg-education-col-second-tabs"
                    >
                      <div class="egg-education-tabs-accor-content">
                        <p>
                          In the rapidly evolving education industry, new
                          engineering capabilities in application services are
                          essential for fostering innovative learning
                          environments and enhancing operational efficiency.
                          Beno Support offers tailored solutions that leverage
                          cutting-edge technologies to meet the unique needs of
                          educational institutions. Our expertise includes the
                          development of interactive learning platforms that
                          facilitate engagement and personalized learning paths,
                          ensuring each student can learn at their own pace and
                          style.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* second accordion- */}
            <div class="accordion-item">
              <h2
                class="accordion-header egg-education-accordion-quest"
                id="flush-headingOne"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#engineering-consulting
                  
                  "
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                  data-aos="fade-up"
                >
                  <span>02.</span> Comprehensive Suite
                </button>
              </h2>
              <div
                id="engineering-consulting"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div class="row">
                    <div
                      class="col-lg-5 col-md-5 col-sm-12"
                      id="egg-education-accordion-tabs"
                    >
                      <div
                        class="egg-education-accordion-img-tabs"
                        style={{
                          backgroundImage: `url(${IMG.EducationApsFaq2})`,
                        }}
                      ></div>
                    </div>
                    <div
                      class="col-lg-7 col-md-7"
                      id="egg-education-col-second-tabs"
                    >
                      <div class="egg-education-tabs-accor-content">
                        <p>
                          The education industry is undergoing a transformative
                          shift, driven by the need for innovative, tech-enabled
                          learning solutions. Beno Support is at the forefront
                          of this transformation, offering a comprehensive suite
                          of new engineering capabilities in application
                          services tailored specifically for educational
                          institutions. Our interactive learning platforms
                          foster engagement through multimedia content,
                          interactive exercises, and gamified learning modules,
                          catering to various learning styles and preferences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* third accordion- */}
            <div class="accordion-item">
              <h2
                class="accordion-header egg-education-accordion-quest"
                id="flush-headingOne"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#software-integrat"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                  data-aos="fade-up"
                >
                  <span>03.</span> Innovative Technology
                </button>
              </h2>
              <div
                id="software-integrat"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div class="row">
                    <div
                      class="col-lg-5 col-md-5 col-sm-12"
                      id="egg-education-accordion-tabs"
                    >
                      <div
                        class="egg-education-accordion-img-tabs"
                        style={{
                          backgroundImage: `url(${IMG.EducationApsFaq3})`,
                        }}
                      ></div>
                    </div>
                    <div
                      class="col-lg-7 col-md-7"
                      id="egg-education-col-second-tabs"
                    >
                      <div class="egg-education-tabs-accor-content">
                        <p>
                          The education industry is undergoing a transformative
                          shift, driven by the need for innovative, tech-enabled
                          learning solutions. Beno Support is at the forefront
                          of this transformation, offering a comprehensive suite
                          of new engineering capabilities in application
                          services tailored specifically for educational
                          institutions. Our interactive learning platforms
                          foster engagement through multimedia content,
                          interactive exercises, and gamified learning modules,
                          catering to various learning styles and preferences.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* fourth accordion- */}
            <div class="accordion-item">
              <h2
                class="accordion-header egg-education-accordion-quest"
                id="flush-headingOne"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#technical-solutions"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                  data-aos="fade-up"
                >
                  <span>04.</span> User-Friendly Interfaces
                </button>
              </h2>
              <div
                id="technical-solutions"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <div class="row">
                    <div
                      class="col-lg-5 col-md-5 col-sm-12"
                      id="egg-education-accordion-tabs"
                    >
                      <div
                        class="egg-education-accordion-img-tabs"
                        style={{
                          backgroundImage: `url(${IMG.EducationApsFaq4})`,
                        }}
                      ></div>
                    </div>
                    <div
                      class="col-lg-7 col-md-7"
                      id="egg-education-col-second-tabs"
                    >
                      <div class="egg-education-tabs-accor-content">
                        <p>
                          In the education industry, the development of new
                          engineering capabilities has led to the creation of
                          user-friendly interfaces in application services,
                          significantly enhancing the learning experience for
                          students and educators alike. These interfaces are
                          designed with intuitive navigation and accessibility
                          in mind, ensuring that users of all ages and technical
                          proficiencies can easily interact with educational
                          content and tools. Features such as customizable
                          dashboards, drag-and-drop functionalities, and
                          real-time feedback mechanisms streamline the learning
                          process, making it more engaging and effective.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -------- about section ----------------- */}
      <section className="education-about-sec" id="about">
        <div className="container education-cloud-agnostic">
          <h2 className="education-section-heading">
            Application software
            <span> in education</span>
          </h2>
          <div className="row mt-4">
            <div className="col-lg-5 col-md-6 col-sm-12 challenges-left-li bfsi-serve-exp-desc-right bfsi-serve-exp-desc-right-unique">
              <p
                onClick={() => tabChangehandler(1)}
                style={tab === 1 ? { display: "none" } : {}}
              >
                Interactive Learning{" "}
              </p>
              <div
                className={`challenges-left-arrow challenges-left-arrow-unique  ${
                  tab === 1 ? "show" : ""
                }`}
              >
                <h3>Interactive Learning</h3>
                <p>
                  Engages students through multimedia content, simulations, and
                  interactive activities, making learning more engaging and
                  effective.
                </p>
              </div>

              <p
                onClick={() => tabChangehandler(2)}
                style={tab === 2 ? { display: "none" } : {}}
              >
                Personalized Paths
              </p>
              <div
                className={`challenges-left-arrow challenges-left-arrow-unique ${
                  tab === 2 ? "show" : ""
                }`}
              >
                <h3>Personalized Paths</h3>
                <p>
                  Customizes the learning journey for each student based on
                  their performance, preferences, and learning pace, ensuring
                  that they receive the support and challenges they need.
                </p>
              </div>

              <p
                onClick={() => tabChangehandler(3)}
                style={tab === 3 ? { display: "none" } : {}}
              >
                Instant Assessment
              </p>
              <div
                className={`challenges-left-arrow challenges-left-arrow-unique ${
                  tab === 3 ? "show" : ""
                }`}
              >
                <h3>Instant Assessment</h3>
                <p>
                  Provides real-time feedback and automated grading, enabling
                  students to understand their progress immediately and allowing
                  teachers to identify areas needing attention.
                </p>
              </div>

              <p
                onClick={() => tabChangehandler(4)}
                style={tab === 4 ? { display: "none" } : {}}
              >
                Collaboration Tools
              </p>
              <div
                className={`challenges-left-arrow challenges-left-arrow-unique ${
                  tab === 4 ? "show" : ""
                }`}
              >
                <h3>Collaboration Tools</h3>
                <p>
                  Facilitates communication and collaboration among students and
                  teachers through discussion forums, group projects, and shared
                  resources, promoting a collaborative learning environment.
                </p>
              </div>

              <p
                onClick={() => tabChangehandler(5)}
                style={tab === 5 ? { display: "none" } : {}}
              >
                Analytics Insights
              </p>
              <div
                className={`challenges-left-arrow challenges-left-arrow-unique ${
                  tab === 5 ? "show" : ""
                }`}
              >
                <h3>Analytics Insights</h3>
                <p>
                  Offers detailed analytics on student performance and
                  engagement, helping educators make data-driven decisions to
                  improve teaching strategies and enhance learning outcomes.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
              <div>
                <img
                  className="education-sub-img-about education-sub-img-about-ed-aps"
                  src={IMG.EducationApsAbout2}
                  alt="img"
                />
                <span className="education-sub-triangle-down-right"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------------  service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Ut sem odio, interdum eu fermentum et, porta vitae nisi. Vivamus
            tincidunt mattis pharetra. Nulla condimentum in nisl in gravida.
            Donec sed mauris non lacus blandit placerat.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.BpoBlogLatest}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Era of generative AI for everyone
              </h2>
              <p className="recent-blogs-slider-desc">
                Maecenas tincidunt enim quis lectus congue, eget pretium neque
                sagittis. Phasellus id consectetur nibh. Etiam in placerat
                lectus. Donec luctus risus risus. Nam vel sagittis nunc. Quisque
                condimentum facilisis mattis. Nam non porta tortor. Aliquam non
                est vehicula, aliquet libero sit amet, bibendum mi. Etiam et
                magna quis dui commodo ullamcorper. Sed vitae vehicula est,
                luctus mattis arcu. Pellentesque congue lacus sed nulla rutrum
                interdum. Maecenas scelerisque feugiat quam et malesuada. In
                ipsum lectus, porta at tempus eu, consectetur ut mauris.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Data Governance Matters: How to Keep Your Data Clean
                            and Accurate?
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Data governance enhances data quality, reliability,
                          and security, ensuring accurate decision-making and
                          service delivery...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EdAppService;

import React from "react";
import { Link } from "react-router-dom";
import he from "he";
import LazyLoad from "react-lazyload";

const FirstBlog = ({ firstItem }) => {
  if (!firstItem) return null;

  return (
    <section id="our-blog">
      <div className="container">
        <h2 className="recent-blogs-title-main">Recent Blogs</h2>
        <p className="recent-blogs-desc-main"></p>
        <div className="row">
          <div
            className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
            data-aos="fade-down-right"
          >
            <LazyLoad height={200} offset={100}>
              <img
                src={firstItem.blog_img}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </LazyLoad>
          </div>
          <div
            className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
            data-aos="fade-down-left"
          >
            <h2 className="recent-blogs-slider-title">{firstItem.title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: he.decode(
                  firstItem.description.split(" ").slice(0, 100).join(" ")
                ),
              }}
            ></p>
            <div className="row mt-4">
              <div className="col-4">
                <Link
                  to={`/blog/${firstItem.blog_slug}`}
                  className="recent-blogs-slider-btn px-4"
                >
                  Learn More
                </Link>
              </div>
              <div className="col-8 recent-blogs-slider-date">
                <div className="dgt_blog_meta">
                  <a href="#">{firstItem.blog_date}</a>
                  <span className="meta-date pl-3">
                    {firstItem.blog_category}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstBlog;

import React, { useContext, useMemo } from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import IMG from "../../../layouts/ImagesOld2";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { CtaStripe } from "../../Services/ApplicationService/ApplicationServices";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Loader from "../../../components/Loader";
import BlogContext from "../../../context/blogContext";
import MainAbout from "../../../components/MainAbout";
import he from "he";

const EducationMain = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".education-main-indus-page-index"
    );
    const stickyDiv = "sticky-education";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [areReadVisible, setAreReadVisible] = useState(true);
  const toggleRead = () => {
    setAreReadVisible(!areReadVisible);
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    ourExpertiseData,
    pagesData,
    solutionData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 19 && i.page_id === 72),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 19 && i.page_id === 72),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const effortlesslyTop = useMemo(
    () => pagesData.find((i) => i.id === 52),
    [pagesData]
  );

  const expertiseData = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 19 && i.page_id === 72),
    [ourExpertiseData]
  );

  const benefitsTop = useMemo(
    () => pagesData.find((i) => i.id === 53),
    [pagesData]
  );

  const solutionFaq = useMemo(
    () => solutionData.filter((i) => i.section_id === 162 && i.page_id === 72),
    [solutionData]
  );

  if (!effortlesslyTop || !benefitsTop) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/*-------------- banner section --------------*/}
      {first ? (
        <section
          id="education-indus-main-banner"
          className="education-indus-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="education-indus-main-bet-content education-indus-main-banner-content">
              <h3
                data-aos="zoom-in"
                className="education-indus-main-banner-head text-white"
              >
                {first.title}
              </h3>
            </div>
            <div className="indus-education-banner-main-btn">
              <a href="#services">{first.btn_first}</a>
              <Link
                to="tel:+91-892-988-4560"
                className="bnr-nbtn-ind-education"
              >
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}
      {/* ------------ ITES industry page indexing section -------------- */}
      <section className="education-main-indus-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="education-main-indus-index-nav">
                <ul>
                  <li>
                    <a
                      className={`index-education-main-indus-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-education-main-indus-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-education-main-indus-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-education-main-indus-item ${
                        current === "solutions" ? "active" : ""
                      }`}
                      href="#solutions"
                      aria-label="Solutions"
                    >
                      Solutions
                    </a>
                  </li>
                  <li>
                    <a
                      className={`index-education-main-indus-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/education"}> Education </Link>
        </div>
      </section>

      <MainAbout mainAboutData={dgtMainAboutArray} />

      <CtaStripe />

      {/* ---------- Industries cater section ----------- */}
      <section id="services" className="education-indus-cater-sec">
        <div className="container">
          <div>
            <h2
              className="education-section-heading text-center"
              id="education-effort-sec-efft"
              dangerouslySetInnerHTML={{
                __html: he.decode(effortlesslyTop.Heading),
              }}
            ></h2>
            <p
              className="education-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(effortlesslyTop.description),
              }}
            ></p>
          </div>

          {/* service area cards */}
          <div className="row">
            {expertiseData && expertiseData.length > 0
              ? expertiseData.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 education-main-indus-cater"
                    key={i.id}
                  >
                    <h2 data-aos="fade-left">{i.title}</h2>
                    <p
                      data-aos="fade-left"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* -------- Engineering Capabilities ----------- */}
      <section id="solutions" className="engineering-capabilities-education">
        <div className="container">
          <h6 className="engineering-capabilities-pre-head">
            {benefitsTop.pre_heading}
          </h6>
          <div className="engineering-capabilities-head-div">
            <h2
              className="egg-education-sub-section-heading-spec"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTop.Heading),
              }}
            ></h2>
          </div>
          {/* customer accordion code */}
          <div
            className="egg-education-accordion-main accordion accordion-flush"
            id="accordionFlushExample"
          >
            {solutionFaq && solutionFaq.length > 0
              ? solutionFaq.map((i, index) => (
                  <div className="accordion-item" key={index}>
                    <h2
                      className="accordion-header egg-education-accordion-quest"
                      id={`flush-heading${index}`}
                    >
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        <span>0{index + 1}.</span> {i.title}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`flush-heading${index}`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div
                            className="col-lg-5 col-md-5 col-sm-12"
                            id="egg-education-accordion-tabs"
                          >
                            <div
                              className="egg-education-accordion-img-tabs"
                              style={{
                                backgroundImage: `url(${i.image})`,
                              }}
                            ></div>
                          </div>
                          <div
                            className="col-lg-7 col-md-7"
                            id="egg-education-col-second-tabs"
                          >
                            <div className="egg-education-tabs-accor-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: he.decode(i.description),
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ---------------- latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover dynamic insights into the latest trends in business process
            automation within the education sector. Uncover recent developments,
            success stories, and best practices for navigating the digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.EducationBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                The Rise of AI in Education: Transforming Learning Experiences
              </h2>
              <p className="recent-blogs-slider-desc">
                Discover how AI is revolutionizing education, reshaping the
                landscape of learning experiences. From personalized tutoring
                systems to automated grading, AI technologies are redefining how
                students learn and educators teach. This blog explores the
                impact of AI on education, delving into its potential to enhance
                student outcomes, streamline administrative tasks, and foster
                innovation in the classroom. Join us as we delve into the
                transformative power of AI in shaping the future of education.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Navigating Hybrid Learning: Strategies for Success
                            in the New Normal
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore effective strategies for navigating hybrid
                          learning in today's educational landscape. Adapt,
                          engage, and thrive.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Unlocking the Potential of Virtual Reality in
                            Education
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore how virtual reality is reshaping education,
                          creating immersive learning environments and enhancing
                          student engagement.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Future of Skills: Preparing Students for the
                            Jobs of Tomorrow
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore strategies for equipping students with
                          essential skills to thrive in rapidly evolving job
                          markets.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Navigating Hybrid Learning: Strategies for Success
                            in the New Normal
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore effective strategies for navigating hybrid
                          learning in today's educational landscape. Adapt,
                          engage, and thrive.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Unlocking the Potential of Virtual Reality in
                            Education
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore how virtual reality is reshaping education,
                          creating immersive learning environments and enhancing
                          student engagement.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EducationMain;

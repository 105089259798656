import React, { useState, useEffect } from "react";
import IMG from "../../layouts/ImagesOld2";
import { useParams } from "react-router-dom";
import axios from "axios";
import he from "he";
import Loader from "../../components/Loader";

const CareerDetail = () => {
  const [jobs, setJobs] = useState(null);

  const { jobId } = useParams();

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const result = await axios.get(
          "https://admin.benosupport.com/api/job-list",
          {
            signal: controller.signal,
          }
        );
        const fetcheddata = result.data.data;
        const singleJob = fetcheddata.find((i) => i.job_id == jobId);
        setJobs(singleJob);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching blog data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [jobId]);

  console.log(jobs);

  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    experience: "",
    emp_type: "",
    resume: null,
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const [newsemail, setNewsemail] = useState("");
  const { name, email, phone, experience, emp_type, resume } = formdata;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setFormdata((prevState) => ({
      ...prevState,
      file: event.target.files[0],
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!formdata.file) {
      setError(true);
      return;
    }

    const data = new FormData();
    data.append("name", formdata.name);
    data.append("email", formdata.email);
    data.append("phone", formdata.phone);
    data.append("experience", formdata.experience);
    data.append("emp_type", formdata.emp_type);
    data.append("resume", formdata.file);

    try {
      setError(false);
      setLoading(true);
      const res = await axios.post(
        "https://admin.benosupport.com/api/career-send",
        data
      );

      setLoading(false);
      setFormdata({
        name: "",
        email: "",
        phone: "",
        experience: "",
        emp_type: "",
        resume: null,
      });
      setMsg("Thank you for Applying. Our team will reply to you soon");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("Failed to send message. Please try again later.");
      setLoading(false);
      setError(true);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  const isNameValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(name);
  };
  const isEmailValid = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isMobileNumberValid = () => {
    const phonePattern = /^\d{1,14}$/;
    return phonePattern.test(phone);
  };

  const isRoleValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(emp_type);
  };

  const handleNewsSubmit = async (e) => {
    e.preventDefault();
    setError(false);

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newsemail)) {
      setMsg("Please enter a valid email address.");
      setError(true);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

    // Get the subscribed emails from local storage
    const subscribedEmails =
      JSON.parse(localStorage.getItem("subscribedEmails")) || [];

    // Check if the email is already subscribed
    if (subscribedEmails.includes(newsemail)) {
      setMsg("You are already subscribed with this email.");
      setError(true);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

    try {
      const res = await axios.post(
        "https://admin.benosupport.com/api/newsletter-save",
        {
          email: newsemail,
        }
      );

      // Update local storage with the new email
      subscribedEmails.push(newsemail);
      localStorage.setItem(
        "subscribedEmails",
        JSON.stringify(subscribedEmails)
      );

      setNewsemail("");
      setMsg("Thank you for subscribing.");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again later.";

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      }

      setMsg(errorMessage);
      setError(true);
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  return (
    <>
      {/*-------------- banner section --------------*/}
      {loading && <Loader />}

      {jobs && (
        <>
          <section
            id="career-banner"
            className="career-main-banner"
            style={{
              backgroundImage: `url(${IMG.CareersDetailsBanner})`,
            }}
          >
            <div className="container">
              <div className="Career-bet-content">
                <h3 data-aos="zoom-in" className="career-main-banner-head">
                  {jobs.job_title}
                </h3>
                <p>
                  Address: {jobs.address}
                  <span>Job Type: {jobs.job_type}</span>
                </p>
                <div className="career-detail-banner-btn">
                  <a href="#career-post-resume">Apply Now</a>
                </div>
              </div>
            </div>
          </section>

          <section id="career-detail-job-detail">
            <div className="container">
              <div className="career-detail-job-detail-head">
                <h3>Job Details:</h3>
              </div>

              <div className="career-det-job-detail-content">
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(jobs.job_detail),
                  }}
                ></p>
              </div>
            </div>
          </section>

          <section id="career-detail-job-detail">
            <div className="container">
              <div className="career-detail-job-detail-head">
                <h3>Additional Details:</h3>
              </div>

              <div className="career-det-job-detail-content">
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(jobs.additional_detail),
                  }}
                ></p>
              </div>
            </div>
          </section>
        </>
      )}

      {/*======================= career page fing job section=================== */}

      <section id="find-job-career">
        <div className="container text-center">
          <div className="career-find-job-head">
            <h3>Get notified for similar jobs</h3>
            <p>Sign up to receive job alerts</p>
          </div>
          <div>
            <form className="row career-find-job" onSubmit={handleNewsSubmit}>
              <div className="row text-center">
                <div
                  className="col-sm-12 col-md-12 col-lg-8"
                  id="subcribe-content-data-details"
                >
                  <div className="row career-search-form">
                    <div className="col-sm-12 col-md-8 col-lg-10">
                      <input
                        type="email"
                        className="form-control mb-2 form-field-height keyword-search-career"
                        id="searchKeyword"
                        placeholder="Enter your email address"
                        value={newsemail}
                        onChange={(e) => setNewsemail(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-2">
                      <input
                        type="submit"
                        className="btn  career-search-form-btn"
                        value={"Subscribe"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {msg && <span className="text-center text-white h5">{msg}</span>}
          </div>
        </div>
      </section>
      {/* ----------career page our benifits section----------- */}
      <section id="Career-benefits">
        <div className="container">
          {/* service area heading */}
          <div className="career-main-head">
            <h3 className="text-center">Similar Jobs</h3>
          </div>

          {/* service area cards */}
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 career-main-benifit-content ">
              <h2 data-aos="fade-left">Java + Python</h2>
              <p data-aos="fade-left">
                Lorem ipsum dolor sit amet, consecte adipisi elit. Ipsa libero
                voluptatibus itaque in voluptas techno
              </p>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 career-main-benifit-content">
              <h2 data-aos="fade-left">C++ Engineer</h2>
              <p data-aos="fade-left">
                Lorem ipsum dolor sit amet, consecte adipisi elit. Ipsa libero
                voluptatibus itaque in voluptas techno
              </p>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 career-main-benifit-content">
              <h2 data-aos="fade-left">Database Developer</h2>
              <p data-aos="fade-left">
                Lorem ipsum dolor sit amet, consecte adipisi elit. Ipsa libero
                voluptatibus itaque in voluptas techno
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* ------------------------- career page post resume section --------------------- */}
      <section
        id="career-post-resume"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            "/assets/img/main-site/world-map-effect.png"
          })`,
        }}
      >
        <div className="container">
          <div className="career-main-head">
            <h3 className="text-center">Post Your Resume</h3>
          </div>
          <form onSubmit={submitHandler}>
            <div className="d-flex align-items-center career-post-resume-form row">
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control mb-2 form-field-height keyword-search-career"
                  id="career-resume-name"
                  placeholder="Full Name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  required
                />
                {name.trim().length > 0 && !isNameValid() && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Name must contain only letters.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="email"
                  className="form-control form-field-height mb-2"
                  id="career-resume-email"
                  placeholder="E-Mail"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  required
                />
                {email.trim().length > 0 && !isEmailValid() && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Please enter a valid email address.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control form-field-height mb-2"
                  id="career-resume-contact"
                  placeholder="Mobile Number"
                  name="phone"
                  onChange={handleChange}
                  value={phone}
                  required
                />
                {phone.trim().length > 0 && !isMobileNumberValid() && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Mobile number must be at least 10 characters.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="number"
                  className="form-control form-field-height mb-2"
                  id="career-resume-experience"
                  placeholder="Experience in Years"
                  name="experience"
                  value={experience}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control form-field-height mb-2"
                  id="career-resume-user-type"
                  placeholder="Designation"
                  name="emp_type"
                  value={emp_type}
                  onChange={handleChange}
                  required
                />
                {emp_type.trim().length > 0 && !isRoleValid() && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    Designation must contain only Alphabets.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <input
                  type="file"
                  className="form-control form-field-height mb-2"
                  id="career-resume-upload"
                  placeholder="Upload Resume"
                  value={resume}
                  name="Upload Resume"
                  onChange={handleFileChange}
                  accept=".pdf, .doc, .docx"
                  required
                />
              </div>
            </div>
            <div className="text-center career-resume-submit-btn">
              <button
                type="submit"
                className="btn  career-post-resume-form-btn "
              >
                {loading ? "Loading" : "Submit"}
              </button>
            </div>
          </form>
        </div>
        <div className="home-notification-msg">
          {msg && <span className="text-center">{msg}</span>}
        </div>
      </section>
    </>
  );
};

export default CareerDetail;

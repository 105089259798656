import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const ContentMarketingDge = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    blogData,
    ourExpertiseData,
    mainServicesData,
    pagesData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 13 && i.page_id === 47),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 13 && i.page_id === 47),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 13 && i.page_id === 47),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const expertise = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 13 && i.page_id === 47),
    [ourExpertiseData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 122),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 13 && i.page_id === 47),
    [mainServicesData]
  );

  const multiSegment = useMemo(
    () =>
      segmentData.filter(
        (i) =>
          i.section_id === 122 &&
          i.page_id === 47 &&
          i.segment_type === "multi-segment"
      ),
    [segmentData]
  );
  const singleSegment = useMemo(
    () =>
      segmentData.find(
        (i) =>
          i.section_id === 122 &&
          i.page_id === 47 &&
          i.segment_type === "single-segment"
      ),
    [segmentData]
  );

  if (loading) return <Loader />;

  return (
    <>
      {/* ---------------- banner section --------------- */}

      {/* {first && (
        <section
          className="dgt-experience-banner-main dgt-experience-banner-overlay "
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-md-12 col-sm-12">
                <div className="dgt-experience-div-banner-title">
                  <h2
                    className="dgt-experience-banner-heading"
                    data-aos="fade-down"
                  >
                    {first.title}
                  </h2>
                  <p
                    className="dgt-experience-banner-para"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>
                <div className="content_marketing-dge-banner-bg-btn">
                  <a href="#services">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="content_marketing-dge-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#services"} blackTitle />

      <BreadCrumb
        url={"/digital-experience"}
        pageTitle={"Digital Experiences"}
        currentPage={"Digital & Marketing Services"}
      />

      {/*-------------------- content marketing counter section---------------- */}
      <section id="dge-content-marketing-counter">
        <div className="container">
          <div className="row">
            {expertise && expertise.length > 0
              ? expertise.map((i) => (
                  <div
                    className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 d-flex dge-cm-counter"
                    key={i.id}
                  >
                    <div className="col-3 dge-counter-sec-img">
                      <img src={i.expertise_img} alt={i.expertise_img_alt} />
                    </div>
                    <div className="col-9 dge-counter-sec-content">
                      <h3>{i.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/*--------------------------- dge result driven section----------------------- */}

      <section className="dge-driven-sec">
        <div className="container">
          {multiSegment && multiSegment.length > 0
            ? multiSegment.map((i, index) => (
                <div className="row dge-driven-section" key={i.id}>
                  <div
                    className={`col-sm-12 col-md-12 col-lg-4 col-xl-4 dge-driven-img ${
                      index % 2 === 0 ? "order-md-1" : "order-md-2"
                    }`}
                  >
                    <img src={i.image} alt={i.image_alt} />
                  </div>

                  <div
                    className={`col-sm-12 col-md-12 col-lg-8 col-xl-8 ${
                      index % 2 === 0 ? "order-md-2" : "order-md-1"
                    }`}
                  >
                    <div className="dge-driven-content">
                      <h2 className="dgt-experience-head-main-title user-expr-head-fosize span">
                        <span>{i.title}</span>
                      </h2>

                      <p
                        className="dgt-experience-head-main-para"
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </section>

      <CtaStripe />
      {/* -------------------section What we Think start------------------------------ */}
      {singleSegment ? (
        <section id="dge-cm-what-we-think">
          <div class="container">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-up-right"
              >
                <h2
                  className="dgt-experience-head-main-title"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(singleSegment.title),
                  }}
                ></h2>
                <p
                  class="dgt-experience-head-main-para"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(singleSegment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-up-left"
              >
                <div
                  className="cyber-security-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${singleSegment.image})`,
                  }}
                ></div>
                <span className="cyber-security-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ----------  content marketing think ------------- */}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default ContentMarketingDge;

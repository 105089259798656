import React, { useContext, useMemo } from "react";
import { CtaStripe } from "./ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const MobileAppDevelopment = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    statisticsData,
    featureImages,
    mainServicesData,
    benefitsData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 11 && i.page_id === 37),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 11 && i.page_id === 37),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 11 && i.page_id === 37),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const counterTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 103),
    [pagesData]
  );

  const counter = useMemo(
    () =>
      statisticsData.filter((i) => i.category_id === 11 && i.page_id === 37),
    [statisticsData]
  );

  const featureSingleImage = useMemo(
    () => featureImages.find((i) => i.page_id === 37 && i.section_id === 103),
    [featureImages]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 104),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 11 && i.page_id === 37),
    [mainServicesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 11 && i.page_id === 37),
    [benefitsData]
  );
  const benefitsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 105),
    [pagesData]
  );

  if (!counterTitleDes || !featureSingleImage || !benefitsTitleDes) return null;
  if (loading) return <Loader />;

  return (
    <>
      {/* {first && (
        <section
          id="application-sub-banner-ds"
          className="ads-sub-banner ads-sub-banner-mobile-app"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container" id="ads-sub-banner-main-cont">
            <div className="row">
              <div className="col-xl-7 col-lg-8 col-md-12 col-sm-12">
                <h1
                  className="ads-sub-banner-Heading ads-sub-banner-Heading-mobile-app text-white"
                  data-aos="fade-left"
                >
                  {first.title}
                </h1>
                <p
                  className="ads-sub-banner-para text-white"
                  data-aos="fade-right"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>

                <div className="app-service-inner-banner-bg-btn">
                  <a href="#why-beno-mobile-app">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="app-service-inner-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#why-beno-mobile-app"} />

      <BreadCrumb
        url={"/software-services"}
        pageTitle={"Software Services"}
        currentPage={"Mobile Application Development"}
      />

      {/*------------------ About Section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/*-------------- About App Dev for beno -------------*/}
      <section className="app-dev-about-beno">
        <div className="container">
          <h2
            className="application-sub-section-heading-spec text-center"
            id="app-srvice-head-bg"
            dangerouslySetInnerHTML={{
              __html: he.decode(counterTitleDes.Heading),
            }}
          ></h2>
          <p
            className="application-services-sub-section-sub-heading text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(counterTitleDes.description),
            }}
          ></p>

          <div className="row app-dev-about-beno-row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                {counter && counter.length > 0
                  ? counter.map((i) => (
                      <div className="col-lg-6 col-md-6 col-sm-12" key={i.id}>
                        <div className="app-dev-about-beno-content">
                          <h3>
                            {i.prefix}
                            {i.number}
                            {i.suffix}
                          </h3>
                          <p>{i.title}</p>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="app-dev-about-beno-image">
                <img
                  src={featureSingleImage.single_pic}
                  alt={featureSingleImage.pic_alt}
                />
              </div>
            </div>
          </div>
          <h6
            dangerouslySetInnerHTML={{
              __html: he.decode(featureSingleImage.name),
            }}
          ></h6>
        </div>
      </section>

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      <CtaStripe />

      {/* ----------- why choose beno section ---------- */}
      <section className="how-we-develop-sec" id="why-beno-mobile-app">
        <div className="container">
          <h2
            className="application-services-head-main-title"
            id="app-datamig-application-services-about-effect"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitleDes.Heading),
            }}
          ></h2>
          <div className="row custom-web-app-how">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div className="col-lg-4 col-md-6 col-sm-12" key={i.id}>
                    <div className="custom-webapp-content">
                      <h3>{i.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default MobileAppDevelopment;

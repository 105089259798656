import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/css/layout/Home.css";
import { ToastContainer } from "react-toastify";
import "./assets/css/layout/ExploreCareer.css";
import "./assets/css/service/BpoServices.css";
import "./assets/css/service/DigitalTransformation.css";
import "./assets/css/service/AppServices.css";
import "./assets/css/service/ItSupport.css";
import "./assets/css/service/CyberSecurity.css";
import "./assets/css/service/Cloud.css";
import "./assets/css/service/HRTalent.css";
import "./assets/css/service/DigitalExperience.css";
import "./assets/css/BlogSingle.css";

import "./assets/css/Industry/BfsiIndustry.css";
import "./assets/css/Industry/HealthCare.css";
import "./assets/css/Industry/ItesIndustry.css";
import "./assets/css/Industry/EcommerceInd.css";
import "./assets/css/Industry/Retail.css";
import "./assets/css/Industry/Manufacturing.css";
import "./assets/css/Industry/LogisticMain.css";
import "./assets/css/Industry/Education.css";

import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import TermsCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Sitemap from "./pages/Sitemap";
import About from "./pages/AboutBeno";

import BpoService from "./pages/Services/Bpo/BpoService";
import BpoSales from "./pages/Services/Bpo/Sales";
import Insurance from "./pages/Services/Bpo/Insurance";
import BpoFinace from "./pages/Services/Bpo/BpoFinance";
import BpoHealth from "./pages/Services/Bpo/BpoHealth";
import BpoLegelProcess from "./pages/Services/Bpo/BpoLegalProcess";
import DataOperationsInsights from "./pages/Services/Bpo/DataOperationsInsights";

import CustomerExperience from "./pages/Services/Dgt/CustomerExperience";
import DigitalTransformation from "./pages/Services/Dgt/DigitalTransformation";
import DigitalOperation from "./pages/Services/Dgt/DigitalOperation";
import DigitalConsulting from "./pages/Services/Dgt/DigitalConsulting";
import DigitalWorkforce from "./pages/Services/Dgt/DigitalWorkforce";

import ApplicationServices from "./pages/Services/ApplicationService/ApplicationServices";
import DesignStrategy from "./pages/Services/ApplicationService/DesignStrategy";
import WebAppDevelopment from "./pages/Services/ApplicationService/WebAppDevelopment";
import MobileAppDevelopment from "./pages/Services/ApplicationService/MobileAppDevelopment";
import ApplicationTransformation from "./pages/Services/ApplicationService/ApplicationTransformation";
import QualityEngineering from "./pages/Services/ApplicationService/QualityEngineering";
import DevOps from "./pages/Services/ApplicationService/DevOps";
import ApplicationLifecycle from "./pages/Services/ApplicationService/ApplicationLifecycle";

import ItSupport from "./pages/Services/ItSupport/ItSupport";
import NetworkInfraServices from "./pages/Services/ItSupport/NetworkInfraServices";
import SecurityServices from "./pages/Services/ItSupport/SecurityServices";
import ManagedIt from "./pages/Services/ItSupport/ManagedIt";

import CyberSecurity from "./pages/Services/CyberSecurity/CyberSecurity";
import IdentityAccessManag from "./pages/Services/CyberSecurity/IdentityAccessManag";
import ThreatDetectionResponse from "./pages/Services/CyberSecurity/ThreatDetectionResponse";
import SecurityRiskAdvisory from "./pages/Services/CyberSecurity/SecurityRiskAdvisory";
import CloudInfraSecurity from "./pages/Services/CyberSecurity/CloudInfraSecurity";

import Cloud from "./pages/Services/CloudPage/Cloud";
import AppDataMigrationServices from "./pages/Services/CloudPage/AppDataMigrationServices";
import ManagedCloudServices from "./pages/Services/CloudPage/ManagedCloudServices";

import HRTalent from "./pages/Services/HRTalentPage/HRTalent";
import HRTalentAquacious from "./pages/Services/HRTalentPage/HRTalentAquacious";
import HRContingent from "./pages/Services/HRTalentPage/HRContingent";
import HRConsult from "./pages/Services/HRTalentPage/HRConsult";

import DigitalExperience from "./pages/Services/DigitalExperiencePage/DigitalExperience";
import ContentMarketingDge from "./pages/Services/DigitalExperiencePage/ContentMarketingDge";
import UserExperience from "./pages/Services/DigitalExperiencePage/UserExperience";
import MultiCulturalMarketing from "./pages/Services/DigitalExperiencePage/MultiCulturalMarketing";
import WebsiteTransformation from "./pages/Services/DigitalExperiencePage/WebsiteTransformation";

import Bfsi from "./pages/Industry/Bfsi/Bfsi";
import BpoInd from "./pages/Industry/Bfsi/BpoInd";
import CyberSecurityBfsi from "./pages/Industry/Bfsi/CyberSecurityBfsi";
import CloudBfsi from "./pages/Industry/Bfsi/CloudBfsi";
import HrTalentBfsi from "./pages/Industry/Bfsi/HrTalentBfsi";
import ManagedItBfsi from "./pages/Industry/Bfsi/ManagedItBfsi";

import TravelTourism from "./pages/Industry/TravelTourism/TravelTourism";
import BpoTravel from "./pages/Industry/TravelTourism/BpoTravel";
import DgeTravel from "./pages/Industry/TravelTourism/DgeTravel";
import ApsTravel from "./pages/Industry/TravelTourism/ApsTravel";

import HealthCare from "./pages/Industry/HealthCare/HealthCare";
import HealthCareBpo from "./pages/Industry/HealthCare/HealthCareBpo";
import HealthAppServices from "./pages/Industry/HealthCare/HealthAppServices";
import ManagedItSupportHealth from "./pages/Industry/HealthCare/ManagedItSupportHealth";
import HealthCyberSecurity from "./pages/Industry/HealthCare/HealthCyberSecurity";
import HealthCloud from "./pages/Industry/HealthCare/HealthCloud";
import HealthHrTalent from "./pages/Industry/HealthCare/HealthHrTalent";
import HealthDigitalExperience from "./pages/Industry/HealthCare/HealthDigitalExperience";

import Technology from "./pages/Industry/Technology/Technology";

import ItesService from "./pages/Industry/Ites/ItesService";
import ItesDgt from "./pages/Industry/Ites/ItesDgt";
import ItesManagItSupport from "./pages/Industry/Ites/ItesManagItSupport";
import ItesCyber from "./pages/Industry/Ites/ItesCyber";
import ItesCloud from "./pages/Industry/Ites/ItesCloud";

import EcomIndus from "./pages/Industry/ECommerce/EcomIndus";
import EcommerceBpo from "./pages/Industry/ECommerce/EcommerceBpo";
import EcommerceAppServices from "./pages/Industry/ECommerce/EcommerceAppServices";
import EcomManagedItSupport from "./pages/Industry/ECommerce/EcomManagedItSupport";
import EcomCyberSecurity from "./pages/Industry/ECommerce/EcomCyberSecurity";
import EcommerceCloud from "./pages/Industry/ECommerce/EcommerceCloud";
import EcommerceTalent from "./pages/Industry/ECommerce/EcommerceTalent";
import EcomDigExperience from "./pages/Industry/ECommerce/EcomDigExperience";

import RetailMain from "./pages/Industry/Retails/RetailMain";
import RetailBpo from "./pages/Industry/Retails/RetailBpo";
import RetailDgt from "./pages/Industry/Retails/RetailDgt";
import RetailAps from "./pages/Industry/Retails/RetailAps";
import RetailMis from "./pages/Industry/Retails/RetailMis";
import RetailCyber from "./pages/Industry/Retails/RetailCyber";
import RetailCloud from "./pages/Industry/Retails/RetailCloud";

import Manufacturing from "./pages/Industry/Manufacturing/Manufacturing";
import ManufacturingDgt from "./pages/Industry/Manufacturing/ManufacturingDgt";
import ManufacturingAps from "./pages/Industry/Manufacturing/ManufacturingAps";
import ManufacturingManage from "./pages/Industry/Manufacturing/ManufacturingManage";
import ManufacturingCyber from "./pages/Industry/Manufacturing/ManufacturingCyber";
import ManufacturingCloud from "./pages/Industry/Manufacturing/ManufacturingCloud";

import LogisticMain from "./pages/Industry/Logistic/LogisticMain";
import LogisticBpo from "./pages/Industry/Logistic/LogisticBpo";

import EducationMain from "./pages/Industry/Education/EducationMain";
import EdAppService from "./pages/Industry/Education/EdAppService";
import ItMain from "./pages/Industry/It/ItMain";

import OurCEO from "./pages/ExploreMore/OurCEO";
import TechnologyTrends from "./pages/ExploreMore/TechnologyTrends";
import Career from "./pages/Careers/Career";
import CareerDetail from "./pages/Careers/CareerDetail";

import SingleBlog from "./pages/Blogs/SingleBlog";
import ScrollToTop from "./layouts/ScrollToTop";
import ItesDge from "./pages/Industry/Ites/ItesDge";
import TechHrTalent from "./pages/Industry/Technology/TechHrTalent";
import TechBpo from "./pages/Industry/Technology/TechBpo";
import VirtualExp from "./pages/Services/DigitalExperiencePage/VirtualExp";

import EducationDigitalAge from "./IndustryTrends/EducationDigitalAge.js";
import HealthcareTech from "./IndustryTrends/HealthcareTech.js";
import RemoteWork from "./IndustryTrends/RemoteWork.js";
import ShapingFuture from "./IndustryTrends/ShapingFuture.js";
import VrEntertain from "./IndustryTrends/VrEntertain.js";
import Fintech from "./IndustryTrends/Fintech.js";
import RetailhrTalent from "./pages/Industry/Retails/RetailhrTalent.js";
import ManufacturingHr from "./pages/Industry/Manufacturing/ManufacturingHr.js";
import SingleBlog2 from "./pages/Blogs/SingleBlog2";
import PageNotFound from "./components/PageNotFound.js";
import Loader from "./components/Loader.js";
import BlogContext from "./context/blogContext.js";
import { useContext } from "react";

function App() {
  AOS.init({
    once: false,
    duration: 2000,
  });

  const { loading } = useContext(BlogContext);

  return (
    <>
      <BrowserRouter>
        {loading && <Loader />}
        <Header />
        <ScrollToTop />

        <Routes>
          <Route index element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/about-beno" element={<About />} />
          {/* ------------Explore and career pages routes ------------- */}
          <Route path="/our-ceo" element={<OurCEO />} />
          <Route path="/technology-trends" element={<TechnologyTrends />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/career-detail/:jobId" element={<CareerDetail />} />
          {/* ------------service pages routes ------------- */}
          <Route path="/bpo-service" element={<BpoService />} />
          <Route path="/bpo-service/bpo-sales" element={<BpoSales />} />
          <Route path="/bpo-service/bpo-insurance" element={<Insurance />} />
          <Route path="/bpo-service/bpo-finance" element={<BpoFinace />} />
          <Route path="/bpo-service/bpo-health-care" element={<BpoHealth />} />
          <Route
            path="/bpo-service/bpo-data-operations-insights"
            element={<DataOperationsInsights />}
          />
          <Route
            path="/bpo-service/bpo-legel-process"
            element={<BpoLegelProcess />}
          />
          <Route
            path="/digital-transformation"
            element={<DigitalTransformation />}
          />
          <Route
            path="/digital-transformation/dgt-customer-experience"
            element={<CustomerExperience />}
          />
          <Route
            path="/digital-transformation/digital-operation"
            element={<DigitalOperation />}
          />
          <Route
            path="/digital-transformation/digital-consulting"
            element={<DigitalConsulting />}
          />
          <Route
            path="/digital-transformation/digital-workforce"
            element={<DigitalWorkforce />}
          />
          <Route path="/software-services" element={<ApplicationServices />} />
          <Route
            path="/software-services/design-strategy"
            element={<DesignStrategy />}
          />
          <Route
            path="/software-services/web-application-development"
            element={<WebAppDevelopment />}
          />
          <Route
            path="/software-services/mobile-application-development"
            element={<MobileAppDevelopment />}
          />
          <Route
            path="/software-services/application-transformation"
            element={<ApplicationTransformation />}
          />
          <Route
            path="/software-services/quality-engineering"
            element={<QualityEngineering />}
          />
          <Route path="/software-services/devOps" element={<DevOps />} />
          <Route
            path="/software-services/application-lifecycle"
            element={<ApplicationLifecycle />}
          />
          <Route path="/it-consulting-support" element={<ItSupport />} />
          <Route
            path="/it-consulting-support/managed-network-infra-services"
            element={<NetworkInfraServices />}
          />
          <Route
            path="/it-consulting-support/managed-security-services"
            element={<SecurityServices />}
          />
          <Route
            path="/it-consulting-support/managed-it-support"
            element={<ManagedIt />}
          />
          <Route path="/cyber-security" element={<CyberSecurity />} />
          <Route
            path="/cyber-security/managed-security-services"
            element={<IdentityAccessManag />}
          />
          <Route
            path="/cyber-security/security-assesment-and-planning"
            element={<ThreatDetectionResponse />}
          />
          <Route
            path="/cyber-security/application-security"
            element={<SecurityRiskAdvisory />}
          />
          <Route
            path="/cyber-security/network-protection"
            element={<CloudInfraSecurity />}
          />
          <Route path="/cloud-services" element={<Cloud />} />
          <Route
            path="/cloud-services/app-data-migration-services"
            element={<AppDataMigrationServices />}
          />
          <Route
            path="/cloud-services/managed-cloud-services"
            element={<ManagedCloudServices />}
          />
          <Route path="/hr-talent-services" element={<HRTalent />} />
          <Route
            path="/hr-talent/hr-process-outsourcing"
            element={<HRTalentAquacious />}
          />
          <Route
            path="/hr-talent/staffing-services"
            element={<HRContingent />}
          />
          <Route
            path="/hr-talent/hr-talent-consulting"
            element={<HRConsult />}
          />
          <Route path="/digital-experience" element={<DigitalExperience />} />
          <Route
            path="/digital-experience/digital-marketing-services"
            element={<ContentMarketingDge />}
          />
          <Route
            path="/digital-experience/user-experience-design"
            element={<UserExperience />}
          />
          <Route
            path="/digital-experience/multi-cultural-marketing-strategies"
            element={<MultiCulturalMarketing />}
          />
          <Route
            path="/digital-experience/website-transformation-localization"
            element={<WebsiteTransformation />}
          />
          <Route
            path="digital-experience/virtual-experience-design"
            element={<VirtualExp />}
          />
          {/* ------------industry pages routes ------------- */}
          <Route path="/bfsi" element={<Bfsi />} />
          <Route path="/bfsi/bpo" element={<BpoInd />} />
          <Route path="/bfsi/managed-it-support" element={<ManagedItBfsi />} />
          <Route path="/bfsi/cyber-security" element={<CyberSecurityBfsi />} />
          <Route path="/bfsi/cloud-services" element={<CloudBfsi />} />
          <Route path="/bfsi/hr-talent-service" element={<HrTalentBfsi />} />
          <Route path="/travel-tourism" element={<TravelTourism />} />
          <Route path="/travel-tourism/bpo" element={<BpoTravel />} />
          <Route
            path="/travel-tourism/digital-experience"
            element={<DgeTravel />}
          />
          <Route
            path="/travel-tourism/application-services"
            element={<ApsTravel />}
          />
          <Route path="/health-care" element={<HealthCare />} />
          <Route path="/health-care/bpo" element={<HealthCareBpo />} />
          <Route
            path="/health-care/application-services"
            element={<HealthAppServices />}
          />
          <Route
            path="/health-care/managed-it-support"
            element={<ManagedItSupportHealth />}
          />
          <Route
            path="/health-care/cyber-security"
            element={<HealthCyberSecurity />}
          />
          <Route path="/health-care/cloud" element={<HealthCloud />} />
          <Route path="/health-care/hr-talent" element={<HealthHrTalent />} />
          <Route
            path="/health-care/digital-experience"
            element={<HealthDigitalExperience />}
          />
          <Route path="/technology" element={<Technology />} />
          <Route path="/technology/bpo" element={<TechBpo />} />
          <Route path="/technology/hr-talent" element={<TechHrTalent />} />
          <Route path="/ites" element={<ItesService />} />
          <Route path="/ites/digital-transformation" element={<ItesDgt />} />
          <Route
            path="/ites/managed-it-support"
            element={<ItesManagItSupport />}
          />
          <Route path="/ites/cyber-security" element={<ItesCyber />} />
          <Route path="/ites/cloud" element={<ItesCloud />} />
          <Route path="/ites/digital-experience" element={<ItesDge />} />
          <Route path="/e-commerce" element={<EcomIndus />} />
          <Route path="/e-commerce/bpo" element={<EcommerceBpo />} />
          <Route
            path="/e-commerce/application-service"
            element={<EcommerceAppServices />}
          />
          <Route
            path="/e-commerce/managed-it-support"
            element={<EcomManagedItSupport />}
          />
          <Route
            path="/e-commerce/cyber-security"
            element={<EcomCyberSecurity />}
          />
          <Route path="/e-commerce/cloud" element={<EcommerceCloud />} />
          <Route path="/e-commerce/hr-talent" element={<EcommerceTalent />} />
          <Route
            path="/e-commerce/digital-experience"
            element={<EcomDigExperience />}
          />
          <Route path="/retail" element={<RetailMain />} />
          <Route path="/retail/bpo" element={<RetailBpo />} />
          <Route
            path="/retail/digital-transformation"
            element={<RetailDgt />}
          />
          <Route path="/retail/application-service" element={<RetailAps />} />
          <Route path="/retail/managed-it-support" element={<RetailMis />} />
          <Route path="/retail/cyber-security" element={<RetailCyber />} />
          <Route path="/retail/cloud" element={<RetailCloud />} />
          <Route path="/retail/hr-talent" element={<RetailhrTalent />} />
          <Route path="/manufacturing" element={<Manufacturing />} />
          <Route
            path="/manufacturing/digital-transformation"
            element={<ManufacturingDgt />}
          />
          <Route
            path="/manufacturing/application-service"
            element={<ManufacturingAps />}
          />
          <Route
            path="/manufacturing/managed-it-support"
            element={<ManufacturingManage />}
          />
          <Route
            path="/manufacturing/cyber-security"
            element={<ManufacturingCyber />}
          />{" "}
          <Route
            path="/manufacturing/hr-talent"
            element={<ManufacturingHr />}
          />
          <Route path="/manufacturing/cloud" element={<ManufacturingCloud />} />
          <Route path="/logistics" element={<LogisticMain />} />
          <Route path="/logistics/bpo" element={<LogisticBpo />} />
          <Route path="/it" element={<ItMain />} />
          <Route path="/education" element={<EducationMain />} />
          <Route
            path="/education/application-service"
            element={<EdAppService />}
          />
          <Route
            path="/education-digital-age"
            element={<EducationDigitalAge />}
          />
          <Route
            path="/healthcare-tech-innovations"
            element={<HealthcareTech />}
          />
          <Route path="/remote-work-transformation" element={<RemoteWork />} />
          <Route path="/future-of-ai" element={<ShapingFuture />} />
          <Route path="/vr-entertainment" element={<VrEntertain />} />
          <Route path="/fintech-reshaping" element={<Fintech />} />
          {/* ------------blogs pages routes ------------- */}
          <Route path="/blog-details" element={<SingleBlog />} />
          <Route path="/blog/:blog_id" element={<SingleBlog2 />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import {
  faArrowRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";

const ApplicationServices = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(
      ".nag-page-index-application"
    );
    const stickyDiv = "sticky-application";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const [areCardsVisibleAppService, setAreCardsVisibleAppService] =
    useState(true);
  const toggleCardsAppService = () => {
    setAreCardsVisibleAppService(!areCardsVisibleAppService);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [itemsVisible, setItemsVisible] = useState(4);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleVisibleItems = () => {
    setIsExpanded(!isExpanded);
    setItemsVisible(isExpanded ? 4 : filteredServices.length);
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    serviceIndustryData,
    mainServicesData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 11),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 11 && i.page_id === 34),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 11 && i.page_id === 34),
    [mainAboutData]
  );

  const industryWeServe = useMemo(
    () => pagesData.filter((i) => i.id === 19),
    [pagesData]
  );
  const [industryWeServeData] = industryWeServe;

  const serviceIndustry = useMemo(
    () =>
      serviceIndustryData.filter(
        (i) => i.category_id === 11 && i.page_id === 34
      ),
    [serviceIndustryData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const [visibleItems, setVisibleItems] = useState(4);

  const loadMoreItems = () => {
    setVisibleItems(serviceIndustry.length);
  };

  const loadLessItems = () => {
    setVisibleItems(4);
  };

  const whatWeDoTop = useMemo(
    () => pagesData.filter((i) => i.id === 40),
    [pagesData]
  );
  const [whatWeDoTopData] = whatWeDoTop;

  const filteredServices = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 11 && i.page_id === 34),
    [mainServicesData]
  );

  if (loading) return <Loader />;
  if (!industryWeServeData || !whatWeDoTopData) return null;

  return (
    <>
      {/* --------- application banner section ----------- */}
      {first && (
        <section
          id="application-banner"
          className="application-service-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 application-banner-front">
                <div className="application-div-banner-title">
                  <p
                    className="application-banner-heading application-banner-heading-main"
                    data-aos="fade-down-left"
                  >
                    {first.title}
                  </p>
                </div>
                <p
                  className="application-banner-para application-banner-para-main"
                  data-aos="fade-up-left"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>
                <div className="row" id="play-button-application">
                  <div className="col-lg-9  col-sm-12 application-know-how-btn">
                    <div className="application-banner-know-how">
                      <a href="#application-sevices">
                        {first.btn_first}{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* -------- application page index section ------------- */}
      <section className="nag-page-index-application">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar-index-application">
                <ul className="nav-index-application">
                  <li>
                    <a
                      className={`nav-link-index-application ${
                        current === "application-expertise" ? "active" : ""
                      }`}
                      href="#application-expertise"
                      aria-label="application-expertise"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-application ${
                        current === "application-industries" ? "active" : ""
                      }`}
                      href="#application-industries"
                      aria-label="application-industries"
                    >
                      Industries
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-application ${
                        current === "application-sevices" ? "active" : ""
                      }`}
                      href="#application-sevices"
                      aria-label="application-sevices"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-application ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Application Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link className="text-black" to="/">
            Home{" "}
          </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link className="text-black" to={"/software-services"}>
            {" "}
            Software Services{" "}
          </Link>
        </div>
      </section>

      {/*------ application service ------------*/}

      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/*------------- application Industries --------------------*/}
      <section
        id="application-industries"
        className="application-industries-sec"
      >
        <div className="container">
          <div className="application-service-inno-head text-center">
            <h2
              className="application-sub-section-heading"
              id="smart-platforms-app"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: he.decode(industryWeServeData.Heading),
                }}
              ></span>
            </h2>
            <p
              className="application-sub-section-sub-heading"
              dangerouslySetInnerHTML={{
                __html: he.decode(industryWeServeData.description),
              }}
            ></p>
          </div>

          <div className="row">
            {serviceIndustry && serviceIndustry.length > 0
              ? serviceIndustry.slice(0, visibleItems).map((i) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-12"
                    data-aos="fade-up-left"
                    id="application-industries-smart"
                  >
                    <div className="row">
                      <div
                        className="col-lg-4 col-md-4 col-sm-12"
                        id="application-industries-smart-img"
                      >
                        <Link to={i.industry_link}>
                          <img
                            src={i.industry_img}
                            alt={i.industry_img_alt}
                            className="rounded float-start"
                          />
                        </Link>
                      </div>
                      <div
                        className="col-lg-8 col-md-8 col-sm-12"
                        id="application-industries-smart-desc"
                      >
                        <Link to={i.industry_link}>
                          <h4>{i.title}</h4>
                        </Link>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                        <div className="application-small-box-down">
                          <Link to={i.industry_link}>
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>

          {serviceIndustry.length > 4 && (
            <div className="text-center btn-div-segments">
              <button
                onClick={visibleItems === 4 ? loadMoreItems : loadLessItems}
                className="load-more-indus-applic-btn"
              >
                {visibleItems === 4 ? "Load More" : "Load Less"}
              </button>
            </div>
          )}
        </div>
      </section>

      <CtaStripe />

      {/*------------- smart sevices --------------------*/}
      <section id="application-sevices" className="application-smart-sevices">
        <div className="container">
          <h2 class="application-sub-section-heading-spec text-center">
            {whatWeDoTopData.Heading}
          </h2>
          <p
            class="application-sub-section-sub-heading text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(whatWeDoTopData.description),
            }}
          ></p>
          <div className="row d-flex justify-content-center">
            {filteredServices.slice(0, itemsVisible).map((i, index) => (
              <div
                className="col-lg-6 col-md-6 col-sm-12 app-smart-best"
                data-aos="fade-up"
                key={i.id}
              >
                <div className="app-smart-best-head">
                  <Link to={i.page_link}>
                    <h2>
                      {String(index + 1).padStart(2, "0")} &nbsp;
                      <span>{i.title}</span>
                    </h2>
                  </Link>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: he.decode(i.description),
                    }}
                  ></p>
                </div>
                <div className="app-smart-best-errow">
                  <Link to={i.page_link}>
                    <FontAwesomeIcon icon={faArrowRightLong} />{" "}
                  </Link>
                </div>
              </div>
            ))}
          </div>
          {filteredServices.length > 4 && (
            <div className="text-center btn-div-segments">
              <button
                onClick={toggleVisibleItems}
                className="load-more-indus-applic-btn"
              >
                {!isExpanded ? "Load More" : "Load Less"}
              </button>
            </div>
          )}
        </div>
      </section>

      {/* --------------- blog section ----------------- */}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};
export default ApplicationServices;

export const CtaStripe = () => {
  const { headerFooterData } = useContext(BlogContext);
  return (
    <section id="bpo-any-question" className="any-question mt-4">
      <div className="container any-question-inner">
        <p>Feel free to reach us!</p>
        <Link to={`tel:${headerFooterData.cta_number}`}>
          Schedule a Free Call
        </Link>
      </div>
    </section>
  );
};

import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faArrowRightLong,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import IndustyAbout from "../../../components/IndustyAbout";
import IndusBenoProvides from "../../../components/IndusBenoProvides";
import he from "he";

const Bfsi = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".bfsi-serv-page-index");
    const stickyDiv = "sticky-bfsi";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const {
    bannerData,
    loading,
    mainAboutData,
    mainServicesData,
    pagesData,
    benefitsData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 15 && i.page_id === 52),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 15 && i.page_id === 52),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 30),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 15 && i.page_id === 52),
    [mainServicesData]
  );

  const effortslesslyTop = useMemo(
    () => pagesData.find((i) => i.id === 44),
    [pagesData]
  );

  const effortslesslyBoxData = useMemo(
    () => benefitsData.filter((i) => i.category_id === 15 && i.page_id === 52),
    [benefitsData]
  );

  if (!effortslesslyTop || !serviceTitleDes || !effortslesslyBoxData)
    return null;

  if (loading) return <Loader />;
  return (
    <>
      {/*-------------- banner section --------------*/}
      {first && (
        <section
          id="bfsi-ind-main-banner"
          className="bfsi-ind-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="bfsi-ind-bet-content" id="bfsi-main-indus-content">
              <h3 data-aos="zoom-in" className="bfsi-ind-main-banner-head">
                {first.title}
              </h3>
            </div>
            <div className="bfsi-ind-banner-btn" id="bfsi-main-indus-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-ind-bfsi">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      )}
      {/* ------------ Bfsi service page indexing section -------------- */}
      <section className="bfsi-serv-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="bfsi-serv-index-nav">
                <ul>
                  <li>
                    <a
                      className={`bfsi-serv-ind-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`bfsi-serv-ind-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`bfsi-serv-ind-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>

                  <li>
                    <a
                      className={`bfsi-serv-ind-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/bfsi"}> BFSI</Link>
        </div>
      </section>
      {/* -------- about section ----------------- */}
      <IndustyAbout industryAboutData={dgtMainAboutArray} />

      <IndusBenoProvides
        indusHeadDesc={serviceTitleDes}
        benoProvides={serviceData}
      />
      {/* -------- busines service area Effortslessly section -------- */}
      <section id="services" className="bfsi-ind-effortslessly-sec">
        <div className="container">
          <div>
            <h2
              className="bfsi-section-heading text-center effortslessly-effect"
              dangerouslySetInnerHTML={{
                __html: he.decode(effortslesslyTop?.Heading),
              }}
            ></h2>
            <p
              className="bfsi-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(effortslesslyTop.description),
              }}
            ></p>
          </div>
          <div className="row mt-4">
            {effortslesslyBoxData &&
              effortslesslyBoxData.length > 0 &&
              effortslesslyBoxData.map((i) => (
                <div
                  id="bfsi-ind-business-areas-main"
                  data-aos="fade-up-left"
                  className="col-lg-4 col-md-4 col-sm-12"
                  key={i.id}
                >
                  <div className="bfsi-ind-inner-area-customer-main">
                    <h5>{i.title}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>

                    <div className="bfsi-indus-serve-small-box">
                      <FontAwesomeIcon icon={faArrowRightLong} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      {/* ----------------  service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Explore dynamic insights into the latest trends in business process
            automation within the BFSI sector. Uncover recent developments,
            successful stories, and best practices for navigating the digital
            era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.BfsiBLog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Navigating the Future: The Role of AI in Transforming BFSI
                Operations
              </h2>
              <p className="recent-blogs-slider-desc">
                In an era of rapid technological advancement, artificial
                intelligence (AI) stands as a transformative force in the
                Banking, Financial Services, and Insurance (BFSI) sector. This
                blog explores how AI algorithms and machine learning models are
                revolutionizing BFSI operations, from streamlining credit risk
                assessment and fraud detection to enhancing customer service
                with personalized recommendations. We delve into the latest AI
                trends, innovations, and real-world applications reshaping the
                landscape of BFSI services and driving unprecedented levels of
                efficiency and agility.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Secure Transactions: The Growing Importance of
                            Cybersecurity in BFSI
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Delve into the evolving cybersecurity landscape within
                          the BFSI sector, analyzing emerging threats, best
                          practices for data protection...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Unlocking Opportunities: Cloud Adoption Trends in
                            BFSI
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Examine the growing adoption of cloud technologies in
                          the BFSI sector, discussing the benefits, challenges,
                          and best practices for migrating...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Customer-Centric Banking: Enhancing Experiences with
                            Digital Transformation
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore the rising demand for digital banking
                          solutions, from mobile banking apps to AI-powered
                          chatbots, and discuss how financial institutions...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Secure Transactions: The Growing Importance of
                            Cybersecurity in BFSI
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Delve into the evolving cybersecurity landscape within
                          the BFSI sector, analyzing emerging threats, best
                          practices for data protection...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Unlocking Opportunities: Cloud Adoption Trends in
                            BFSI
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Examine the growing adoption of cloud technologies in
                          the BFSI sector, discussing the benefits, challenges,
                          and best practices for migrating core banking
                          systems...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Bfsi;

import React, { useState, useEffect, useContext } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

import { useParams } from "react-router-dom";
import axios from "axios";
import he from "he";
import { MdFacebook } from "react-icons/md";
import { BsInstagram, BsLinkedin, BsTwitterX, BsYoutube } from "react-icons/bs";
import BlogContext from "../../context/blogContext";
import Loader from "../../components/Loader";

const SingleBlog2 = () => {
  const [blogdata, setBlogdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { blog_id } = useParams();
  const { blogData } = useContext(BlogContext);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const result = await axios.get(
          "https://admin.benosupport.com/api/blogs",
          {
            signal: controller.signal,
          }
        );
        const fetcheddata = result.data.data;
        const singleBlogData = fetcheddata.find((i) => i.blog_slug === blog_id);
        setBlogdata(singleBlogData);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching blog data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [blog_id]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(false);
        const result = await axios.get(
          "https://admin.benosupport.com/api/category",
          {
            signal: controller.signal,
          }
        );
        const res = result.data.data;
        setCategory(res);
        setLoading(true);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching blog data:", error);
          setError(true);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {blogdata && (
        <>
          <section className="blog__area-4 pt-140 pb-140 blog-detail-margin">
            <div className="container">
              <div className="blog__wrapper-4">
                <div className="blog__details">
                  <div className="blog__details-top">
                    <div className="thumb">
                      <img
                        src={blogdata.blog_img}
                        alt="Thumbnail"
                        data-speed="0.8"
                        data-lag={0}
                        style={{
                          translate: "none",
                          rotate: "none",
                          scale: "none",
                          transform: "translate(0px, -53.0997px)",
                          willChange: "transform",
                        }}
                      />
                    </div>
                    <ul className="meta">
                      <li>
                        <a className="cxu-btn-border">
                          {blogdata.blog_category}
                        </a>
                      </li>
                      <li>
                        <span>{blogdata.blog_date}</span>
                      </li>
                    </ul>
                    <h1 className="title">{blogdata.title} </h1>
                  </div>
                  <article
                    className="blog__details-content cxu-fade"
                    data-ease="back"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px, 0px)",
                      opacity: 1,
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: he.decode(blogdata.description),
                      }}
                    ></p>
                  </article>
                </div>
                <div className="blog__sidebar">
                  <div
                    className="widget__recent cxu-fade"
                    data-ease="back"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px, 0px)",
                      opacity: 1,
                    }}
                  >
                    <h2 className="widget__title">Recent Blogs</h2>
                    <div className="cxu--posts style-5">
                      {blogData.length > 0 &&
                        blogData.slice(0, 3).map((i) => (
                          <article
                            className="cxu-post"
                            style={{
                              borderBottom: "1px solid #d1d1d1",
                              paddingBottom: "10px",
                            }}
                            key={i.id}
                          >
                            <div className="thumb">
                              <a href={`/blog/${i.blog_slug}`}>
                                <img src={i.blog_img} alt="Thumbnail" />
                              </a>
                            </div>
                            <div className="content">
                              <ul className="meta">
                                <li>
                                  <a>{i.blog_date}</a>
                                </li>
                              </ul>
                              <h4 className="title">
                                <a href={`/blog/${i.blog_slug}`}>
                                  {i.title.slice(0, 50)}
                                </a>
                              </h4>
                            </div>
                          </article>
                        ))}
                    </div>
                  </div>
                  <div
                    className="widget__category cxu-fade"
                    data-ease="back"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px, 0px)",
                      opacity: 1,
                    }}
                  >
                    <h2 className="widget__title">Categories</h2>
                    <ul>
                      {category &&
                        category.length > 0 &&
                        category.map((i) => (
                          <li key={i.id}>
                            <a href={`/technology-trends`}>
                              <MdKeyboardArrowRight />
                              {i.bcat_name}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div
                    className="widget__share cxu-fade"
                    data-ease="back"
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px, 0px)",
                      opacity: 1,
                    }}
                  >
                    <h2 className="widget__title">Follow us</h2>
                    <ul className="social-media">
                      <li>
                        <a
                          href="https://www.linkedin.com/company/bensupport-technologies/"
                          target="_blank"
                        >
                          <BsLinkedin />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/bensupport-technologies/"
                          target="_blank"
                        >
                          <BsYoutube />
                        </a>
                      </li>{" "}
                      <li>
                        <a
                          href="https://twitter.com/benosupport/"
                          target="_blank"
                        >
                          <BsTwitterX />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/benosupport/"
                          target="_blank"
                        >
                          <MdFacebook />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/beno.support/"
                          target="_blank"
                        >
                          <BsInstagram />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default SingleBlog2;

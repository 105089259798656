import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faArrowRightLong,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import he from "he";
import MainAbout from "../../../components/MainAbout";
import IndusBenoProvides from "../../../components/IndusBenoProvides";

const HealthCare = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".health-care-page-index");
    const stickyDiv = "sticky-health";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    mainServicesData,
    benefitsData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 22 && i.page_id === 79),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 22 && i.page_id === 79),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 59),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 22 && i.page_id === 79),
    [mainServicesData]
  );

  const capebelitiesTop = useMemo(
    () => pagesData.find((i) => i.id === 60),
    [pagesData]
  );

  const capebelitiesBoxData = useMemo(
    () => benefitsData.filter((i) => i.category_id === 22 && i.page_id === 79),
    [benefitsData]
  );

  if (!capebelitiesTop) return null;
  return (
    <>
      {loading ? <Loader /> : null}

      {/*-------------- banner section --------------*/}
      {first ? (
        <section
          id="health-ind-main-banner"
          className="health-ind-banner-main health-ind-banner-main-unique"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="health-indus-content health-indus-content-main">
              <h3 data-aos="zoom-in">{first.title}</h3>
            </div>
            <div className="health-indus-banner-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="nbtn-health-indus">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}

      {/* ------------ page indexing section -------------- */}
      <section className="health-care-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="health-care-index-nav">
                <ul>
                  <li>
                    <a
                      className={`health-care-indus-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`health-care-indus-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`health-care-indus-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`health-care-indus-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/health-care"}> Health Care </Link>
        </div>
      </section>

      <MainAbout mainAboutData={dgtMainAboutArray} />

      <IndusBenoProvides
        indusHeadDesc={serviceTitleDes}
        benoProvides={serviceData}
      />

      {/* -------- busines service area Effortslessly section -------- */}
      <section id="services" className="health-ind-effortslessly-sec">
        <div className="container">
          <div>
            <h2
              className="health-section-heading text-center health-effortslessly-effect"
              dangerouslySetInnerHTML={{
                __html: he.decode(capebelitiesTop.Heading),
              }}
            ></h2>
            <p
              className="bfsi-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(capebelitiesTop.description),
              }}
            ></p>
          </div>
          <div className="row mt-4">
            {capebelitiesBoxData && capebelitiesBoxData.length > 0
              ? capebelitiesBoxData.map((i) => (
                  <div
                    id="health-ind-business-areas-main"
                    data-aos="fade-up-left"
                    className="col-lg-4 col-md-4 col-sm-12"
                    key={i.id}
                  >
                    <div className="health-ind-inner-area-customer-main">
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ----------------  service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends in business process automation with our
            dynamic insights. Learn about the most recent developments in the
            field, as well as successful stories and best practices, to enable
            your company to prosper in the digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.HealthCareBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Enhancing Patient Engagement: The Role of Technology in
                Improving Health Experiences
              </h2>
              <p className="recent-blogs-slider-desc">
                Discover how technology is reshaping patient engagement and
                revolutionizing health experiences in our latest blog. From
                personalized care journeys to virtual health platforms, explore
                the innovative ways in which technology is enhancing
                patient-provider interactions and empowering individuals to take
                charge of their health. Learn how digital solutions are
                fostering stronger connections, improving adherence to treatment
                plans, and ultimately leading to better health outcomes. Click
                here to delve into the future of healthcare engagement.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Enhancing Patient Satisfaction Through Technology
                            and Communication
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how technology and improved communication
                          revolutionize healthcare, enhancing patient
                          satisfaction and personalized care.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            AI in Healthcare: Revolutionizing Clinical
                            Operations and Patient Outcomes
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore how AI transforms healthcare, optimizing
                          operations and improving patient outcomes for a
                          brighter future.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Ensuring Trust in Healthcare: Cybersecurity Measures
                            to Protect Patient Data and Privacy
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Learn about critical cybersecurity measures
                          safeguarding patient data, ensuring trust,
                          confidentiality, and privacy in healthcare.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Enhancing Patient Satisfaction Through Technology
                            and Communication
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Discover how technology and improved communication
                          revolutionize healthcare, enhancing patient
                          satisfaction and personalized care.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            AI in Healthcare: Revolutionizing Clinical
                            Operations and Patient Outcomes
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Explore how AI transforms healthcare, optimizing
                          operations and improving patient outcomes for a
                          brighter future.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HealthCare;

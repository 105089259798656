import React from "react";
import { Link } from "react-router-dom";
import he from "he";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import LazyLoad from "react-lazyload";

const OtherBlogs = ({ otherItems, settings }) => {
  if (!otherItems || otherItems.length === 0) return null;

  return (
    <section className="dgt-recent-blogs" id="dgt-recent-blogs">
      <div className="blog_area pt-85 pb-65">
        <div className="container">
          <div className="row">
            <Slider {...settings}>
              {otherItems.slice(0, 5).map((i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                  key={i.id}
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to={`/blog/${i.blog_slug}`}>
                        <LazyLoad height={200} offset={100}>
                          <img src={i.blog_img} alt="img" />
                        </LazyLoad>
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a>{i.blog_date} </a>
                        <span className="meta-date pl-3">
                          {i.blog_category}
                        </span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to={`/blog/${i.blog_slug}`}>{i.title}</Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(
                              i.description.split(" ").slice(0, 25).join(" ") +
                                "..."
                            ),
                          }}
                        ></p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to={`/blog/${i.blog_slug}`}>
                          Read More &nbsp;
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtherBlogs;

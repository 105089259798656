import React from "react";
import IMG from "../../layouts/ImagesOld2";
import { MdFacebook } from "react-icons/md";
import { BsInstagram, BsTwitterX } from "react-icons/bs";

const SingleBlog = () => {
  return (
    <>
      <section
        className="page-top-section set-bg"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "assets/img/bpo/what-wedo-4.png"
          })`,
        }}
      >
        <div className="container">
          <h2>6 Reasons You Must Have Data Backup and Recovery</h2>
        </div>
      </section>

      <section className="blog__area-4 pt-140 pb-140">
        <div className="container">
          <div className="blog__wrapper-4">
            <div className="blog__details">
              <div className="blog__details-top">
                <div className="thumb">
                  <img
                    src={IMG.AboutBfsi}
                    alt="Thumbnail"
                    data-speed="0.8"
                    data-lag={0}
                    style={{
                      translate: "none",
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px, -53.0997px)",
                      willChange: "transform",
                    }}
                  />
                </div>
                <ul className="meta">
                  <li>
                    <a className="cxu-btn-border" href="category.html">
                      UI/UX Design
                    </a>
                  </li>
                  <li>
                    <span>01 Jan 2023</span>
                  </li>
                </ul>
                <h1 className="title">
                  What you need to know for success online
                </h1>
              </div>
              <article
                className="blog__details-content cxu-fade"
                data-ease="back"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <p>
                  <span className="large">H</span> alfbeak blue shark goldfish
                  creek chub, combfish; Australian herring; lizardfish
                  trout-perch, olive flounder false moray. Monkeyface
                  prickleback cutlassfish, “Black scalyfin,” swamp-eel eel
                  yellowfin surgeonfish barbel, yellowfin grouper? Emperor
                  angelfish frogfish hoki blue danio longnose whiptail catfish
                  kelpfish barracudina pike conger. Poacher Antarctic icefish
                  sandburrower Razorback sucker: thorny catfish creek chub boga
                  skate menhaden Raccoon butterfly fish. Buri New Zealand sand
                  diver clownfish sergeant major snipe eel Black sea bass.
                </p>
                <p>
                  Ruffe tench pricklefish springfish New World rivuline
                  shortnose greeneye lionfish bigeye, shark. Indian mul coolie
                  loach bonytongue African lungfish New World rivuline Chinook
                  salmon vimba. Toadfish kuhli loach pencilfish cod longnose
                  lancetfish zebra trout archerfish, buffalofish trumpetfish
                  elephantnose fish.{" "}
                </p>
                <div className="quote">
                  <img src={IMG.AboutBfsi} alt="Icon" />
                  <blockquote>
                    I believe we are here on the planet Earth to live, grow up
                    and do what we can to make this world a better place for all
                    people to enjoy freedom."{" "}
                  </blockquote>
                  <p className="author">— Rosa Parks</p>
                </div>
                <div className="img-group">
                  <img src={IMG.AgileBanner} alt="Image" />
                  <img src={IMG.AgileBanner} alt="Image" />
                </div>
                <h2>The process of the product design</h2>
                <p>
                  he whimsically named Egg Canvas is the brainchild of Erica
                  Choi, a design director and photo grapher based in York. Why
                  the name “Egg Canvas Erica was inspired by her Korean
                  childhood nickname, which means egg, while “canvas” medium
                  with wh art is created. “Egg Canvas therefore, is her
                  life—creating beautiful things each day a blank canvas. We
                  have covered many special events such as fireworks, fairs,
                  parades, races, walks, awards ceremonies, fashion shows,
                  sporting events, and even a memorial service.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur vulputate vestibulum rhon cus, dolor eget viverra
                  pretium, dolor tellus aliquet nunc, vitae ultricies erat elit
                  eu lacus. Vestibulum non justo fun consectetur, cursus ante,
                  tincidunt sapien. Nulla quis diam sit amet turpis interd enim.
                  Vivamus fauc ex sed nibh egestas elementum.{" "}
                </p>
              </article>
              <div
                className="blog__details-btm cxu-fade"
                data-ease="back"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <div className="blog__tags">
                  <h3 className="text">tags</h3>
                  <ul className="tags">
                    <li>
                      <a className="cxu-btn-border" href="">
                        Design
                      </a>
                    </li>
                    <li>
                      <a className="cxu-btn-border" href="">
                        UX Design
                      </a>
                    </li>
                    <li>
                      <a className="cxu-btn-border" href="">
                        Branding
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="blog__sidebar">
              <div
                className="widget__recent cxu-fade"
                data-ease="back"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <h2 className="widget__title">Recent Blog</h2>
                <div className="cxu--posts style-5">
                  <article className="cxu-post">
                    <div className="thumb">
                      <a href="blog-details.html">
                        <img src="assets/imgs/blog/11.jpg" alt="Thumbnail" />
                      </a>
                    </div>
                    <div className="content">
                      <ul className="meta">
                        <li>
                          <a>01 Jan 2023</a>
                        </li>
                      </ul>
                      <h4 className="title">
                        <a href="blog-details.html">
                          Brand stories that design
                        </a>
                      </h4>
                    </div>
                  </article>
                  <article className="cxu-post">
                    <div className="thumb">
                      <a href="blog-details.html">
                        <img src="assets/imgs/blog/12.jpg" alt="Thumbnail" />
                      </a>
                    </div>
                    <div className="content">
                      <ul className="meta">
                        <li>
                          <a>01 Jan 2023</a>
                        </li>
                      </ul>
                      <h4 className="title">
                        <a href="blog-details.html">Crafting captivating</a>
                      </h4>
                    </div>
                  </article>
                  <article className="cxu-post">
                    <div className="thumb">
                      <a href="blog-details.html">
                        <img src="assets/imgs/blog/12.jpg" alt="Thumbnail" />
                      </a>
                    </div>
                    <div className="content">
                      <ul className="meta">
                        <li>
                          <a>01 Jan 2023</a>
                        </li>
                      </ul>
                      <h4 className="title">
                        <a href="blog-details.html">Visual storytelling in </a>
                      </h4>
                    </div>
                  </article>
                </div>
              </div>
              <div
                className="widget__category cxu-fade"
                data-ease="back"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <h2 className="widget__title">Categories</h2>
                <ul>
                  <li>
                    <a href="">Branding Design</a>
                  </li>
                  <li>
                    <a href="">Product Design</a>
                  </li>
                  <li>
                    <a href="">UX Design</a>
                  </li>
                  <li>
                    <a href="">Web Development</a>
                  </li>
                </ul>
              </div>

              <div
                className="widget__share cxu-fade"
                data-ease="back"
                style={{
                  translate: "none",
                  rotate: "none",
                  scale: "none",
                  transform: "translate(0px, 0px)",
                  opacity: 1,
                }}
              >
                <h2 className="widget__title">Follow us</h2>
                <ul className="social-media">
                  <li>
                    <a href="https://www.facebook.com/codexup" target="_blank">
                      <MdFacebook />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <BsTwitterX />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i className="fa-brands fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <BsInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleBlog;

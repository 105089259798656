import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/Loader";
import BlogContext from "../../../context/blogContext";
import he from "he";
import MainAbout from "../../../components/MainAbout";
import IndusBenoProvides from "../../../components/IndusBenoProvides";

const Technology = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".tech-indus-page-index");
    const stickyDiv = "sticky-techno";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    mainServicesData,
    ourExpertiseData,
    benefitsData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 21 && i.page_id === 76),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 21 && i.page_id === 76),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 56),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 21 && i.page_id === 76),
    [mainServicesData]
  );

  const expertiseTop = useMemo(
    () => pagesData.find((i) => i.id === 57),
    [pagesData]
  );

  const expertiseData = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 21 && i.page_id === 76),
    [ourExpertiseData]
  );

  const capebilitiesTop = useMemo(
    () => pagesData.find((i) => i.id === 58),
    [pagesData]
  );

  const capebilitiesBoxData = useMemo(
    () => benefitsData.filter((i) => i.category_id === 15 && i.page_id === 52),
    [benefitsData]
  );
  if (!serviceTitleDes || !expertiseTop || !capebilitiesTop) return null;
  if (loading) return <Loader />;

  return (
    <>
      {loading ? <Loader /> : null}
      {/*-------------- banner section --------------*/}

      {first ? (
        <section
          id="tech-indus-main-banner"
          className="tech-indus-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="tech-indus-content tech-indus-content-tech-main">
              <h3 data-aos="zoom-in">{first.title}</h3>
            </div>
            <div className="tech-indus-banner-btn">
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="nbtn-tech-indus">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null}

      {/* ------------ page indexing section -------------- */}
      <section className="tech-indus-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="tech-indus-index-nav">
                <ul>
                  <li>
                    <a
                      className={`tech-indus-index-item ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`tech-indus-index-item ${
                        current === "provides" ? "active" : ""
                      }`}
                      href="#provides"
                      aria-label="provides"
                    >
                      Provides
                    </a>
                  </li>
                  <li>
                    <a
                      className={`tech-indus-index-item ${
                        current === "industries" ? "active" : ""
                      }`}
                      href="#industries"
                      aria-label="industries"
                    >
                      Industries
                    </a>
                  </li>
                  <li>
                    <a
                      className={`tech-indus-index-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`tech-indus-index-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/technology"}> Technology </Link>
        </div>
      </section>
      {/* -------- about section ----------------- */}

      <MainAbout mainAboutData={dgtMainAboutArray} />

      <IndusBenoProvides
        indusHeadDesc={serviceTitleDes}
        benoProvides={serviceData}
      />

      {/* ---------- Industries cater section ----------- */}
      <section id="industries" className="industries-cater-main-sec">
        <div className="container">
          <div>
            <h2
              className="technology-section-heading text-center industries-cater-head-effect"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiseTop.Heading),
              }}
            ></h2>
            <p
              className="technology-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiseTop.description),
              }}
            ></p>
          </div>

          {/* service area cards */}
          <div className="row">
            {expertiseData && expertiseData.length > 0
              ? expertiseData.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 technology-main-indus-cater"
                    key={i.id}
                  >
                    <h2 data-aos="fade-left">{i.title}</h2>
                    <p
                      data-aos="fade-left"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* -------- busines service area Effortslessly section -------- */}
      <section id="services" className="technology-ind-effortslessly-sec">
        <div className="container">
          <div>
            <h2
              className="technology-section-heading text-center tech-effortslessly-effect"
              dangerouslySetInnerHTML={{
                __html: he.decode(capebilitiesTop.Heading),
              }}
            ></h2>
            <p
              className="bfsi-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(capebilitiesTop.description),
              }}
            ></p>
          </div>
          <div className="row mt-4">
            {capebilitiesBoxData && capebilitiesBoxData.length > 0
              ? capebilitiesBoxData.map((i) => (
                  <div
                    id="technology-ind-business-areas-main"
                    data-aos="fade-up-left"
                    className="col-lg-4 col-md-4 col-sm-12"
                    key={i.id}
                  >
                    <div className="technology-ind-inner-area-customer-main">
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ----------------  service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Explore the dynamic landscape of business process automation within
            the technology sector in our recent blog. Uncover recent
            advancements, success stories, and best practices for navigating the
            digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.technologyBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Cybersecurity in the Age of Digital Transformation: Best
                Practices for Protecting Your Business
              </h2>
              <p className="recent-blogs-slider-desc">
                In the rapidly evolving landscape of digital transformation,
                cybersecurity is paramount. This blog explores the latest
                cybersecurity threats facing businesses and provides essential
                best practices for protecting sensitive data and mitigating
                cyber risks effectively. From implementing robust security
                measures to fostering a culture of cybersecurity awareness,
                discover how to safeguard your business against evolving cyber
                threats and ensure resilience in the digital age.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Future of Remote Work: Navigating Challenges and
                            Embracing Opportunities
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Summary: Explore the evolving landscape of remote
                          work, from overcoming common challenges to harnessing
                          the benefits of remote collaboration.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Rise of Voice Commerce: How Voice Technology is
                            Transforming Online Shopping
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Dive into the world of voice commerce, exploring its
                          impact on consumer behavior, emerging trends, and
                          strategies for businesses...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Power of Influencer Marketing: Leveraging Social
                            Media Influencers to Grow Your Brand
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Delve into the realm of influencer marketing,
                          uncovering the strategies and tactics used by brands
                          to collaborate with social media influencers and
                          drive...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Future of Remote Work: Navigating Challenges and
                            Embracing Opportunities
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Summary: Explore the evolving landscape of remote
                          work, from overcoming common challenges to harnessing
                          the benefits of remote collaboration.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Rise of Voice Commerce: How Voice Technology is
                            Transforming Online Shopping
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Dive into the world of voice commerce, exploring its
                          impact on consumer behavior, emerging trends, and
                          strategies for businesses...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Technology;

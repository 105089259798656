import React, { useContext, useMemo, useState } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const Insurance = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeTab, setActiveTab] = useState(0);

  const {
    bannerData,
    blogData,
    mainAboutData,
    pagesData,
    mainServicesData,
    segmentData,
    solutionData,
  } = useContext(BlogContext);
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 6 && i.page_id === 10),
    [bannerData]
  );
  const [first] = bpoBanner;

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 6 && i.page_id === 10),
    [blogData]
  );

  const [firstItem, ...otherItems] = bpoBlog;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 6 && i.page_id === 10),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 25),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 6 && i.page_id === 10),
    [mainServicesData]
  );

  const buildingTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 70),
    [pagesData]
  );

  const letsPaln = useMemo(
    () => segmentData.filter((i) => i.page_id === 10 && i.section_id === 25),
    [segmentData]
  );

  const [firstSegment, ...restSegment] = letsPaln;

  const solution = useMemo(
    () => solutionData.filter((i) => i.page_id === 10 && i.section_id === 70),
    [solutionData]
  );

  if (!buildingTitleDes || !letsPaln || !firstSegment) return null;

  return (
    <>
      <ServiceBanner
        serviceBanner={first}
        indexId={"#insurance-experience"}
        blackTitle
      />

      <BreadCrumb
        url={"/bpo-service"}
        pageTitle={"Bpo Services"}
        currentPage={"Insurance BPO"}
      />

      {/*-------------------------BPO insurance What-we-do start--------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={serviceTitleDes} innerServices={serviceData} />

      <section id="insurance-experience">
        <div className="container">
          <h2
            className="bpo-sub-section-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(buildingTitleDes.Heading),
            }}
          ></h2>
          <p
            className="bpo-sub-section-sub-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(buildingTitleDes.description),
            }}
          ></p>

          <ul
            className="insurance-experience-tabs border-bottom border-2"
            role="tablist"
          >
            {solution && solution.length > 0
              ? solution.map((i, index) => (
                  <li className="nav-item" role="presentation" key={index}>
                    <button
                      className={`nav-link ${
                        activeTab === index ? "active" : ""
                      }`}
                      id={`tab-${index}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pane-${index}`}
                      type="button"
                      role="tab"
                      aria-controls={`pane-${index}`}
                      aria-selected={activeTab === index ? "true" : "false"}
                      onClick={() => setActiveTab(index)} // Set the clicked tab as active
                    >
                      {i.title}
                    </button>
                  </li>
                ))
              : null}
          </ul>

          <div className="tab-content" id="pills-tabContent">
            {solution && solution.length > 0
              ? solution.map((i, index) => (
                  <div
                    className={`tab-pane fade ${
                      activeTab === index ? "show active" : ""
                    }`}
                    id={`pane-${index}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${index}`}
                    key={index}
                  >
                    <div className="row">
                      <div
                        className="col-lg-5 col-md-5 col-sm-12"
                        id="insurance-col-first-tabs"
                        data-aos="fade-right"
                      >
                        <div
                          className="bussness-img-fifth insurance-col-first-img-tabs"
                          style={{
                            backgroundImage: `url(${i.image})`,
                          }}
                        ></div>
                      </div>
                      <div
                        className="col-lg-7 col-md-7"
                        id="insurance-col-second-tabs"
                        data-aos="fade-left"
                      >
                        <div className="talent-deliver-content">
                          <h4>{i.title}</h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: he.decode(i.description),
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* -===============================  insurance  what we think section =============================- */}
      <section id="insurance-we-think">
        <div className="container">
          {/* -========================= row  think single ======================- */}
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              id="insurance-we-think-first"
              data-aos="fade-right"
            >
              <h2
                className="bpo-sub-section-heading"
                dangerouslySetInnerHTML={{
                  __html: he.decode(firstSegment.title),
                }}
              ></h2>
              <p
                className="bpo-sub-section-sub-heading"
                dangerouslySetInnerHTML={{
                  __html: he.decode(firstSegment.description),
                }}
              ></p>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              id="insurance-we-think-second"
              data-aos="fade-left"
            >
              <img
                src={firstSegment.image}
                className="insurance-we-think-img"
                alt={firstSegment.image_alt}
              />
              <span className="triangle-down-insurance-think"></span>
            </div>
          </div>
          {/* -========================= row grid think miltiple ======================- */}
          <div className="row" id="insurance-we-think-grid-row">
            {restSegment && restSegment.length > 0
              ? restSegment.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12"
                    id="insurance-we-think-grid-first"
                    data-aos="flip-up"
                    key={i.id}
                  >
                    <div className="card insurance-we-think-grid-first-card">
                      <img
                        src={i.image}
                        alt={i.image_alt}
                        className="card-img-top insurance-we-think-grid-first-card-img"
                      />
                      <div className="card-body">
                        <h6 className="insurance-we-think-grid-first-card-category">
                          Future Insurance
                        </h6>
                        <a className="insurance-we-think-grid-first-card-link">
                          <h5 className="card-title insurance-we-think-grid-first-card-title">
                            {i.title}
                          </h5>
                        </a>
                        <p
                          className="card-text insurance-we-think-grid-first-card-para"
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default Insurance;

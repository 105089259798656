import React, { useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const DigitalConsulting = ({}) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    blogData,
    mainAboutData,
    pagesData,
    mainServicesData,
    solutionData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 7 && i.page_id === 18),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtBlogs = useMemo(
    () => blogData.filter((i) => i.category_id === 7 && i.page_id === 18),
    [blogData]
  );

  const [firstItem, ...otherItems] = dgtBlogs;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 7 && i.page_id === 18),
    [mainAboutData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 80),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 7 && i.page_id === 18),
    [mainServicesData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const solution = useMemo(
    () => solutionData.find((i) => i.page_id === 18 && i.section_id === 81),
    [solutionData]
  );

  const solutionTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 82),
    [pagesData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.page_id === 18 && i.section_id === 82),
    [segmentData]
  );

  if (!solution || !solutionTitleDes || !segment) return null;
  return (
    <>
      <ServiceBanner
        serviceBanner={first}
        indexId={"#dgt-dc-product-solutions"}
        blackTitle
      />

      <BreadCrumb
        url={"/digital-transformation"}
        pageTitle={"Digital Transformation"}
        currentPage={"Digitization Consulting & Support"}
      />
      {/*-------------------------digital consulting What-we-do --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/*------------------------- product and solutions section" ------------------*/}
      <section id="dgt-dc-product-solutions">
        <div className="container">
          <h6 className="customer-experience-pre-head">
            {solutionTitleDes.pre_heading}
          </h6>
          <div className="customer-experience-head-div">
            <h2
              className="dgt-sub-section-heading-spec"
              dangerouslySetInnerHTML={{
                __html: he.decode(solutionTitleDes.Heading),
              }}
            ></h2>
          </div>
          <div className="row" id="operationally-agile-main">
            <div
              class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 dgo-centered-div"
              data-aos="flip-up"
            >
              <img
                src={solution.image}
                alt={solution.image_alt}
                className="ago-algie-img dc-startup-dri"
              />
            </div>
            <div
              className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 dgo-agile-para"
              data-aos="fade-up-left"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(solution.description),
                }}
              ></p>

              <div class="pb-4 dgt-tabs-accor-content">
                <Link to="/digital-transformation">Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* -------------------section What we Think start------------------------------ */}
      <section id="customer-what-we-think">
        <div class="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              data-aos="fade-up-right"
            >
              <h2
                class="dgt-sub-section-heading"
                dangerouslySetInnerHTML={{ __html: he.decode(segment.title) }}
              ></h2>
              <p
                class="dgt-sub-section-sub-heading"
                dangerouslySetInnerHTML={{
                  __html: he.decode(segment.description),
                }}
              ></p>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              data-aos="fade-up-left"
            >
              <div
                className="dgt-sub-under-img-right"
                style={{
                  backgroundImage: `url(${segment.image})`,
                }}
              ></div>
              <span className="dgt-sub-triangle-down"></span>
            </div>
          </div>
        </div>
      </section>
      {/* ----------section HR Services for Maximum Efficiency start------------- */}

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default DigitalConsulting;

import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../layouts/ImagesOld2";
import "react-toastify/dist/ReactToastify.css";
import BlogContext from "../context/blogContext";
import he from "he";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";

const Contact = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    job_title: "",
    organization_name: "",
    enquiry: "",
    country: "",
    industry: "",
    email: "",
    phone: "",
    message: "",
  });
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const {
    first_name,
    last_name,
    email,
    job_title,
    organization_name,
    enquiry,
    country,
    phone,
    industry,
    message,
  } = formData;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "https://admin.benosupport.com/api/contact-add";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    try {
      setLoading(true);
      const response = await fetch(url, options);
      const responseData = await response.json();

      setFormData({
        first_name: "",
        last_name: "",
        job_title: "",
        organization_name: "",
        enquiry: "",
        country: "",
        industry: "",
        email: "",
        phone: "",
        message: "",
      });
      setMsg("Message Sent Successfully Thanks for Contact Us.");
      setTimeout(() => {
        setLoading(false);
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("Failed to send message. Please try again later.");
      setTimeout(() => {
        setLoading(false);
        setMsg(null);
      }, 3000);
    }
  };
  const isNameValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(first_name);
  };
  const isLastNameValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(last_name);
  };
  const isJobTitleValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(job_title);
  };

  const isOrgNameValid = () => {
    const namePattern = /^[A-Za-z][A-Za-z ]*$/;
    return namePattern.test(organization_name);
  };
  const isEmailValid = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };
  const isMobileNumberValid = () => {
    return phone.trim().length >= 10;
  };

  const { bannerData, headerFooterData } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 29 && i.page_id === 109),
    [bannerData]
  );
  const [first] = bpoBanner;

  const [branch, setBranch] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBranch = async () => {
      try {
        setError("");
        setLoading(true);
        const res = await axios.get(
          "https://admin.benosupport.com/api/our-branch"
        );
        setLoading(false);

        const result = res.data.data;
        setBranch(result);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchBranch();
  }, []);
  if (loading) return <Loader />;
  return (
    <>
      {first ? (
        <section
          className="contact-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="banner-content-contact">
              <h2>{first.title}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(first.description),
                }}
              ></p>
            </div>
          </div>
        </section>
      ) : null}

      <section className="contact-our-branch-sec">
        <div className="container">
          <h2>Our Branch Office’s</h2>
          <div className="row">
            {branch.map((i) => (
              <div
                className="col-lg-3 col-md-4 col-sm-12"
                id="contact-our-branch-content-main"
                key={i.id}
              >
                <div
                  className="contact-our-branch-content-innr"
                  style={{
                    backgroundImage: `url(${i.image})`,
                  }}
                >
                  <h4 className="front-heading-branch">{i.name}</h4>
                  <div className="branch-overlay-box-contact">
                    <h4>{i.name}</h4>
                    <p>{i.address}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="contact-details-sec">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              id="contact-details-first-col"
            >
              <div className="row contact-details-content-innr">
                <div className="col-lg-2 col-md-2">
                  <img src={IMG.ContactIconAddress} alt="img" />
                </div>
                <div className="col-lg-10 col-md-10">
                  <h4>CORPORATE OFFICE</h4>
                  <p>{headerFooterData.corporate_location}</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              id="contact-details-first-col"
            >
              <div className="row contact-details-content-innr">
                <div className="col-lg-2 col-md-2">
                  <img src={IMG.ContactIconAddress} alt="img" />
                </div>
                <div className="col-lg-10 col-md-10 ">
                  <h4>REGISTERED OFFICE</h4>
                  <p>{headerFooterData.registered_location}</p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-12"
              id="contact-details-first-col"
            >
              <div className="row contact-details-content-innr">
                <div className="col-lg-2 col-md-2">
                  <img src={IMG.ContactIconAddress} alt="img" />
                </div>
                <div className="col-lg-10 col-md-10">
                  <h4>U.S OFFICE</h4>
                  <p>{headerFooterData.us_location}</p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-12"
              id="contact-details-first-col"
            >
              <div className="row contact-details-content-innr">
                <div className="col-lg-2 col-md-2">
                  <img src={IMG.ContactIconCall} alt="img" />
                </div>
                <div className="col-lg-10 col-md-10">
                  <h4>CALL US</h4>
                  <p>
                    <Link to={`tel:${headerFooterData.mobile_number}`}>
                      {headerFooterData.mobile_number}
                    </Link>
                    <br />
                    <Link to={`tel:${headerFooterData.additional_number}`}>
                      {headerFooterData.additional_number}
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-4 col-sm-12"
              id="contact-details-first-col"
            >
              <div className="row contact-details-content-innr">
                <div className="col-lg-2 col-md-2">
                  <img src={IMG.ContactIconEmail} alt="img" />
                </div>
                <div className="col-lg-10 col-md-10">
                  <h4>EMAIL US</h4>
                  <p>
                    <Link
                      to={`mailto:${headerFooterData.email}`}
                      target="blank"
                    >
                      {headerFooterData.email}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form-sec">
        <div className="container">
          <h2>Let’s get in touch with us</h2>
          <form className="contact-form-beno-main" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    id="form-firstname"
                    name="first_name"
                    value={first_name}
                    onChange={handleChange}
                    required
                  />{" "}
                  {first_name?.trim().length > 0 && !isNameValid() && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      first name must contain only letters.
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="last_name"
                    id="form-lastname"
                    value={last_name}
                    onChange={handleChange}
                    required
                  />{" "}
                  {last_name?.trim().length > 0 && !isLastNameValid() && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      last name must contain only letters.
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="E-Mail Address"
                    name="email"
                    id="form-email"
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={handleChange}
                    required
                  />{" "}
                  {email.trim().length > 0 && !isEmailValid() && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      please enter a valid email.
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Designation"
                    name="job_title"
                    id="form-job-title"
                    value={job_title}
                    onChange={handleChange}
                    required
                  />
                  {job_title?.trim().length > 0 && !isJobTitleValid() && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      job title must contain only letters.
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="country"
                    value={country}
                    onChange={handleChange}
                    required
                  >
                    <option selected>Country</option>
                    <option value="India">India</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Australia">Australia</option>
                    <option value="Japan">Japan</option>
                    <option value="Qatar">Qatar</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Phone Number"
                    name="phone"
                    id="form-phone"
                    value={phone}
                    onChange={handleChange}
                    required
                  />
                  {phone.trim().length > 0 && !isMobileNumberValid() && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      mobile number must be at least 10 digits.
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="enquiry"
                    value={enquiry}
                    onChange={handleChange}
                    required
                  >
                    <option selected>Enquiry Type</option>
                    <option value="Request for service">
                      Request for service
                    </option>
                    <option value="Career">Career</option>
                    <option value="Investers">Investers</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Organization Name"
                    name="organization_name"
                    id="form-organization"
                    value={organization_name}
                    onChange={handleChange}
                    required
                  />{" "}
                  {organization_name?.trim().length > 0 &&
                    !isOrgNameValid() && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        organization name must contain only letters.
                      </span>
                    )}
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12">
                <div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="industry"
                    value={industry}
                    onChange={handleChange}
                    required
                  >
                    <option selected>Industry</option>
                    <option value="Technology">Technology</option>
                    <option value="Retail">Retail</option>
                    <option value="E-Commerce">E-Commerce</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <textarea
                    className="form-control"
                    placeholder="Your brief message..."
                    rows="5"
                    id="form-message"
                    name="message"
                    value={message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button type="submit">Submit</button>
            </div>
          </form>
          <div className="home-notification-msg">
            {msg && <span className="text-center">{msg}</span>}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

import React, { useContext, useMemo, useState } from "react";
import Slider from "react-slick";
import IMG from "../../../layouts/ImagesOld2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const SecurityServices = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    mainAboutData,
    bannerData,
    mainServicesData,
    pagesData,
    solutionData,
    benefitsData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 8 && i.page_id === 24),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 8 && i.page_id === 24),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 84),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 8 && i.page_id === 24),
    [mainServicesData]
  );

  const solutionTitle = useMemo(
    () => pagesData.find((i) => i.id === 85),
    [pagesData]
  );

  const solutions = useMemo(
    () => solutionData.filter((i) => i.page_id === 24 && i.section_id === 84),
    [solutionData]
  );

  const benefitsTitle = useMemo(
    () => pagesData.find((i) => i.id === 86),
    [pagesData]
  );

  const benefitsArr = useMemo(
    () => benefitsData.filter((i) => i.category_id === 8 && i.page_id === 24),
    [benefitsData]
  );

  const segment = useMemo(() =>
    segmentData.find((i) => i.page_id === 24 && i.section_id === 84)
  );

  if (!solutionTitle || !benefitsTitle) return null;
  return (
    <>
      {/* {first && (
        <section
          id="itsupport-sub-banner-ds"
          className="itsupport-sub-banner itsupport-sub-banner-infra"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container" id="itsupport-sub-banner-main-cont">
            <div className="row">
              <div
                className="col-lg-8 col-md-8 col-sm-12"
                id="agile-banner-sec"
              >
                <h1
                  className="itsupport-sub-banner-Heading"
                  data-aos="fade-left"
                >
                  {first.title}
                </h1>
                <p
                  className="itsupport-sub-banner-para"
                  data-aos="fade-right"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>

                <div className="it-support-inner-banner-bg-btn">
                  <a href="#services">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="it-support-inner-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#services"} blackTitle />

      <BreadCrumb
        url={"/it-consulting-support"}
        pageTitle={"IT Counsulting and Support"}
        currentPage={"Managed Security Services"}
      />

      {/*-------------It support intro section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* ------------- Our Approach Towards Solution -------- */}
      <section id="itsupport-approach" className="itsupport-approach-solution">
        <div className="container">
          <div className="itsopport-intro-head">
            <h6>{solutionTitle.pre_heading}</h6>
            <div>
              <h2
                className="itsopport-intro-section-heading itsopport-intro-section-heading2"
                dangerouslySetInnerHTML={{
                  __html: he.decode(solutionTitle.Heading),
                }}
              ></h2>
            </div>
            <p
              className="itsopport-intro-section-sub-heading mt-2"
              dangerouslySetInnerHTML={{
                __html: he.decode(solutionTitle.description),
              }}
            ></p>
          </div>
          <div className="row mis-wcbeno-cards">
            {solutions && solutions.length > 0
              ? solutions.map((i) => (
                  <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mis-mss-cards">
                    <img src={i.image} alt={i.image_alt} />
                    <div>
                      <h2>{i.title}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* ------- Beno Support Tailored Solution -----------*/}
      <section
        id="itsupport-tailored-solution"
        className="itsupport-tailored-solution"
      >
        <div className="container">
          <div className="itsopport-intro-head">
            <h2
              className="itsopport-intro-section-heading"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTitle.Heading),
              }}
            ></h2>
            <p
              className="itsopport-intro-section-sub-heading"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTitle.description),
              }}
            ></p>
          </div>

          {benefitsArr[0] && (
            <div class="row tailored-solution-roww mt-4 pt-4">
              <div class="col-lg-1 tailored-solution-iconn" data-aos="flip-up">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="53"
                    height="58"
                    viewBox="0 0 53 58"
                    fill="none"
                  >
                    <path d="M25.0087 57.6561C25.8721 58.1166 26.9558 58.1136 27.8193 57.6532C51.3493 45.0306 53.0204 20.3691 52.9998 13.3569C52.9976 12.7933 52.8308 12.2426 52.5199 11.7722C52.209 11.3018 51.7675 10.9322 51.2495 10.7086L27.6078 0.2504C27.2316 0.0847855 26.8249 -0.000502805 26.4137 2.22986e-06C26.0025 0.000507265 25.596 0.0867943 25.2202 0.253332L1.743 10.7115C1.23257 10.9353 0.797298 11.3008 0.489049 11.7646C0.180799 12.2284 0.0125646 12.7709 0.0043881 13.3275C-0.0954648 20.3046 1.31129 45.0189 25.0087 57.6561ZM16.7444 24.0438L23.4786 30.7686L36.0865 18.1783L40.2392 22.3252L23.4786 39.0625L12.5917 28.1907L16.7444 24.0438Z" />
                  </svg>
                </p>
              </div>
              <div
                class="col-lg-5 tailored-solution-para-first"
                data-aos="fade-down"
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(benefitsArr[0].description),
                  }}
                ></p>
              </div>
              <div
                class="col-lg-6 tailored-solution-para-second"
                data-aos="fade-left"
              >
                <p>{benefitsArr[0].title}</p>
              </div>
            </div>
          )}

          {benefitsArr[1] && (
            <div class="row tailored-solution-row">
              <div class="col-lg-1 tailored-solution-iconn" data-aos="flip-up">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="53"
                    height="58"
                    viewBox="0 0 53 58"
                    fill="none"
                  >
                    <path d="M25.0087 57.6561C25.8721 58.1166 26.9558 58.1136 27.8193 57.6532C51.3493 45.0306 53.0204 20.3691 52.9998 13.3569C52.9976 12.7933 52.8308 12.2426 52.5199 11.7722C52.209 11.3018 51.7675 10.9322 51.2495 10.7086L27.6078 0.2504C27.2316 0.0847855 26.8249 -0.000502805 26.4137 2.22986e-06C26.0025 0.000507265 25.596 0.0867943 25.2202 0.253332L1.743 10.7115C1.23257 10.9353 0.797298 11.3008 0.489049 11.7646C0.180799 12.2284 0.0125646 12.7709 0.0043881 13.3275C-0.0954648 20.3046 1.31129 45.0189 25.0087 57.6561ZM16.7444 24.0438L23.4786 30.7686L36.0865 18.1783L40.2392 22.3252L23.4786 39.0625L12.5917 28.1907L16.7444 24.0438Z" />
                  </svg>
                </p>
              </div>
              <div
                class="col-lg-6 tailored-solution-para-first"
                data-aos="fade-down"
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(benefitsArr[1].description),
                  }}
                ></p>
              </div>
              <div
                class="col-lg-5 tailored-solution-para-second"
                data-aos="fade-left"
              >
                <p>{benefitsArr[1].title}</p>
              </div>
            </div>
          )}

          {benefitsArr[2] && (
            <div class="row tailored-solution-row mt-2">
              <div class="col-lg-1 tailored-solution-iconn" data-aos="flip-up">
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="53"
                    height="58"
                    viewBox="0 0 53 58"
                    fill="none"
                  >
                    <path d="M25.0087 57.6561C25.8721 58.1166 26.9558 58.1136 27.8193 57.6532C51.3493 45.0306 53.0204 20.3691 52.9998 13.3569C52.9976 12.7933 52.8308 12.2426 52.5199 11.7722C52.209 11.3018 51.7675 10.9322 51.2495 10.7086L27.6078 0.2504C27.2316 0.0847855 26.8249 -0.000502805 26.4137 2.22986e-06C26.0025 0.000507265 25.596 0.0867943 25.2202 0.253332L1.743 10.7115C1.23257 10.9353 0.797298 11.3008 0.489049 11.7646C0.180799 12.2284 0.0125646 12.7709 0.0043881 13.3275C-0.0954648 20.3046 1.31129 45.0189 25.0087 57.6561ZM16.7444 24.0438L23.4786 30.7686L36.0865 18.1783L40.2392 22.3252L23.4786 39.0625L12.5917 28.1907L16.7444 24.0438Z" />
                  </svg>
                </p>
              </div>
              <div
                class="col-lg-7 tailored-solution-para-first"
                data-aos="fade-down"
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: he.decode(benefitsArr[2].description),
                  }}
                ></p>
              </div>
              <div
                class="col-lg-4 tailored-solution-para-second"
                data-aos="fade-left"
              >
                <p>{benefitsArr[1].title}</p>
              </div>
            </div>
          )}
        </div>
      </section>

      <CtaStripe />

      {/* ------------------- application what think first-------------------- */}
      {segment ? (
        <section id="itsupport-what-think-first">
          <div class="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                <h2
                  class="itsopport-intro-section-heading"
                  dangerouslySetInnerHTML={{ __html: he.decode(segment.title) }}
                ></h2>
                <p
                  class="itsopport-intro-section-sub-heading"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div
                  className="itsupport-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${segment.image})`,
                  }}
                ></div>
                <span className="itsupport-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends in business process automation with our
            dynamic insights. Learn about the most recent developments in the
            field, as well as successful stories and best practices, to enable
            your company to prosper in the digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.ItSecurityBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Business Outsourcing Makes Businesses More Resilient for
                Long-Term Growth
              </h2>
              <p className="recent-blogs-slider-desc">
                Businesses that are resilient can navigate setbacks adeptly,
                preserving operations and reputations through swift adaptation.
                The key lies in agile responses, with digital tools playing an
                increasingly crucial role in fostering flexibility. Despite
                economic uncertainty, most IT budgets are on the rise, as
                indicated by 80% planning increased spending, while only 11%
                anticipate cuts. This resilience is attributed to the strategic
                use of resources and technology, reinforcing the vital
                connection between adaptability, digitalization, and sustained
                business success.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Data Governance Matters: How to Keep Your Data Clean
                            and Accurate?
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Data governance enhances data quality, reliability,
                          and security, ensuring accurate decision-making and
                          service delivery...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecurityServices;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import he from "he";

import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import BlogContext from "../../../context/blogContext";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import IndustryServe from "../../../components/IndustryServe";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import IMG from "../../../layouts/ImagesOld2";

const BpoService = () => {
  const {
    blogData,
    bannerData,
    pagesData,
    mainServicesData,
    serviceIndustryData,
    ourExpertiseData,
    segmentData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 6),
    [blogData]
  );
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 6 && i.page_id === 13),
    [bannerData]
  );
  const [first] = bpoBanner;
  const [firstItem, ...otherItems] = bpoBlog;
  const [current, setCurrent] = useState("");

  const ourExp = useMemo(
    () => pagesData.filter((i) => i.id === 9),
    [pagesData]
  );

  const [ourExpData] = ourExp;

  const industryWeServe = useMemo(
    () => pagesData.filter((i) => i.id === 10),
    [pagesData]
  );

  const [industryWeServeData] = industryWeServe;

  const whatWeDo = useMemo(
    () => pagesData.filter((i) => i.id === 11),
    [pagesData]
  );

  const [whatWeDoData] = whatWeDo;

  const howWeMake = useMemo(
    () => pagesData.filter((i) => i.id === 12),
    [pagesData]
  );

  const [howWeMakeData] = howWeMake;

  const serviceIndustry = useMemo(
    () =>
      serviceIndustryData.filter(
        (i) => i.category_id === 6 && i.page_id === 13
      ),
    [serviceIndustryData]
  );

  useEffect(() => {
    const sections = document.querySelectorAll("section");

    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".bpo-serv-page-index");
    const stickyDiv = "sticky-bpo";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 700,
    autoPlay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const charLimit = 150;
  const [expandedItemId, setExpandedItemId] = useState(null);

  const filteredServices = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 6 && i.page_id === 13),
    [mainServicesData]
  );

  const toggleReadMore = (id) => {
    setExpandedItemId(expandedItemId === id ? null : id);
  };

  const ourExpertiseFilter = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 6 && i.page_id === 13),
    [ourExpertiseData]
  );

  const howHappens = useMemo(
    () => segmentData.filter((i) => i.section_id === 12 && i.page_id === 13),
    [segmentData]
  );

  if (
    !ourExpData ||
    !industryWeServeData ||
    !whatWeDoData ||
    !howWeMakeData ||
    !mainServicesData ||
    !serviceIndustry ||
    !ourExpertiseFilter
  )
    return null;
  return (
    <>
      {/* ----------------- Bpo service banner section ------------------- */}
      {first && (
        <section
          id="bpo-service-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 bpo-serv-banner-second">
                <h1 className="bpo-serv-banner-title" data-aos="fade-down-left">
                  {first.title}
                </h1>
                <p
                  className="bpo-serv-banner_desc"
                  data-aos="fade-up-left"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>

                <div className="bpo-serv-banner-work-btn">
                  <a href="#why-us">
                    {first.btn_first}{" "}
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* ------------ Bpo service page indexing section -------------- */}
      <section className="bpo-serv-page-index">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="bpo-serv-indexing-nav">
                <ul>
                  <li>
                    <a
                      className={`bpo-serv-ind-item ${
                        current === "expertise" ? "active" : ""
                      }`}
                      href="#expertise"
                      aria-label="Expertise"
                    >
                      Expertise
                    </a>
                  </li>
                  <li>
                    <a
                      className={`bpo-serv-ind-item ${
                        current === "industries" ? "active" : ""
                      }`}
                      href="#industries"
                      aria-label="Industries"
                    >
                      Industries
                    </a>
                  </li>
                  <li>
                    <a
                      className={`bpo-serv-ind-item ${
                        current === "services" ? "active" : ""
                      }`}
                      href="#services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>

                  <li>
                    <a
                      className={`bpo-serv-ind-item ${
                        current === "why-us" ? "active" : ""
                      }`}
                      href="#why-us"
                      aria-label="Why-Us?"
                    >
                      Why Us?
                    </a>
                  </li>
                  <li>
                    <a
                      className={`bpo-serv-ind-item ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link to="/">Home </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link to={"/bpo-service"}> BPO Services</Link>
        </div>
      </section>

      {/* --------- Bpo service expertise section ----------- */}
      <section className="bpo-serv-expertise" id="expertise">
        <div className="container-fluid" id="bpo-serv-expertise-cont-fluid">
          <div className="container">
            <h2 className="bpo-serv-expertise-title text-center">
              <h2
                dangerouslySetInnerHTML={{
                  __html: he.decode(ourExpData.Heading),
                }}
              ></h2>
            </h2>
            <p
              className="bpo-serv-expertise-desc text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(ourExpData.description),
              }}
            ></p>

            <div className="row" id="bpo-serv-expertise-card-row">
              {ourExpertiseFilter && ourExpertiseFilter.length > 0
                ? ourExpertiseFilter.map((i) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 bpo-serv-expertise-service_box"
                      key={i.id}
                    >
                      <div className="bpo-serv-expertise-small"></div>
                      <h6>{i.title}</h6>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                      <img src={i.expertise_img} alt={i.expertise_img_alt} />
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>
      {/* --------- Bpo service services section --------------------- */}

      <IndustryServe
        industryWeServeData={industryWeServeData}
        serviceIndustry={serviceIndustry}
      />

      {/* ----------- Bpo service industries section ------------ */}
      <section id="services">
        <span className="shadow-img-segments-right">
          <img src={IMG.Bg4} alt="img" />
        </span>
        <span className="shadow-img-segments-left">
          <img src={IMG.Bg4} alt="img" />
        </span>
        <div className="container">
          <h2
            className="bpo-serv-our-segments-heading text-center"
            id="bpo-heading-ntw"
            dangerouslySetInnerHTML={{
              __html: he.decode(whatWeDoData.Heading),
            }}
          ></h2>
          <p
            className="bpo-serv-our-segments-desc text-center mx-4"
            dangerouslySetInnerHTML={{
              __html: he.decode(whatWeDoData.description),
            }}
          ></p>

          <div className="row">
            {filteredServices.map((item) => {
              const decodedDescription = he.decode(item.description);
              const shouldTruncate = decodedDescription.length > charLimit;
              const displayedDescription =
                expandedItemId === item.id
                  ? decodedDescription
                  : decodedDescription.substring(0, charLimit) +
                    (shouldTruncate ? "..." : "");

              return (
                <div
                  key={item.id}
                  className="col-lg-4 col-md-4 col-sm-12 bpo-serv-what_wedo_box"
                  data-aos="fade-up"
                >
                  <div className="card bpo-serv-what_wedo-innr">
                    <Link to={item.page_link}>
                      <img src={item.service_img} alt="img" />
                    </Link>
                    <div className="card-body">
                      <Link to={item.page_link}>
                        <h5
                          className="card-title"
                          id="bpo-serv-wedo-card-title"
                        >
                          {item.title}
                        </h5>
                      </Link>
                      <p
                        className="card-text"
                        id="bpo-serv-wedo-card-desc"
                        dangerouslySetInnerHTML={{
                          __html: displayedDescription,
                        }}
                      ></p>
                      {shouldTruncate && (
                        <button
                          onClick={() => toggleReadMore(item.id)}
                          id="bpo-serv-wedo-mless-btn1"
                        >
                          {expandedItemId === item.id
                            ? "Read Less"
                            : "Read More"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <CtaStripe />

      {/*---------------- Bpo service why-us section  ------------ */}
      <section className="bpo-serv-happens-sec" id="why-us">
        <div className="container">
          <h2
            className="bpo-serv-our-segments-heading  text-center"
            id="bpo-heading-hpn"
            dangerouslySetInnerHTML={{
              __html: he.decode(howWeMakeData.Heading),
            }}
          ></h2>
          <p
            className="bpo-serv-our-segments-desc text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(howWeMakeData.description),
            }}
          ></p>
          {/* ------------ happens sction what we do it? ----------------*/}

          {howHappens &&
            howHappens.length > 0 &&
            howHappens.map((i, index) => (
              <div className="row" id="bpo-serv-happens-row-main" key={i.id}>
                <div
                  className={`col-lg-8 col-md-6 col-sm-12 bpo-serv-happens-box-left-col ${
                    index % 2 === 0 ? "order-md-2" : "order-md-1"
                  }`}
                  data-aos="fade-up-right"
                >
                  <h2
                    className="bpo-serv-happens-box-head"
                    dangerouslySetInnerHTML={{ __html: he.decode(i.title) }}
                  ></h2>
                  <p
                    className="bpo-serv-happens-box-desc"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(i.description),
                    }}
                  ></p>
                </div>
                <div
                  className={`col-lg-4 col-md-6 col-sm-12 bpo-serv-happens-box-right-col ${
                    index % 2 === 0 ? "order-md-1" : "order-md-2"
                  }`}
                  data-aos="fade-up-left"
                >
                  <img
                    src={i.image}
                    alt={i.image_alt}
                    className="bpo-serv-happens-box-right-img"
                  />
                </div>
              </div>
            ))}
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default BpoService;

import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import IMG from "../../../layouts/ImagesOld2";
import { CtaStripe } from "../../Services/ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import { useContext, useMemo } from "react";
import Loader from "../../../components/Loader";
import he from "he";
import InnerServices from "../../../components/InnerServices";
import IndustryBanner from "../../../components/IndustryBanner";

const EcommerceAppServices = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    faqData,
    statisticsData,
    mainServicesData,
    techLogoData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 23 && i.page_id === 89),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 23 && i.page_id === 89),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const faqTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 184),
    [pagesData]
  );

  const faq = useMemo(
    () => faqData.filter((i) => i.page_id === 89 && i.sec_name === "faqs"),
    [faqData]
  );

  const counterTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 185),
    [pagesData]
  );

  const counter = useMemo(
    () =>
      statisticsData.filter((i) => i.category_id === 23 && i.page_id === 89),
    [statisticsData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 186),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 23 && i.page_id === 89),
    [mainServicesData]
  );

  const toolsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 187),
    [pagesData]
  );

  const webTechnology = useMemo(
    () => techLogoData.filter((i) => i.tcategory === "web-technologies"),
    [techLogoData]
  );

  const appTechnology = useMemo(
    () => techLogoData.filter((i) => i.tcategory === "app-technologies"),
    [techLogoData]
  );

  if (!faqTitleDes || !counterTitleDes || !toolsTitleDes) return null;
  return (
    <>
      {loading && <Loader />}
      {/*-------------- banner section --------------*/}
      {/* {first ? (
        <section
          id="ecom-indus-main-banner"
          className="ecom-indus-banner"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="ecom-indus-main-bet-content ecom-indus-inner-banner-content">
              <h3
                data-aos="zoom-in"
                className="ecom-indus-main-banner-head text-white"
              >
                {first.title}
              </h3>
            </div>
            <div
              className="indus-ecom-banner-main-btn"
              id="ecomm-indus-inner-page-bnt"
            >
              <a href="#services">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="bnr-nbtn-ind-ecom">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null} */}

      <IndustryBanner industyBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/e-commerce"}
        pageTitle={"E-Commerce"}
        currentPage={"Application Services"}
      />

      {/* -------- Support Process section ----------------- */}
      <section
        className="ecom-indus-support-process-sec"
        id="bpo-commerce-indus-sec"
      >
        <div className="container">
          <h2
            className="indus-main-ecom-section-heading bpo-ecom-indus-about-eff text-center"
            dangerouslySetInnerHTML={{ __html: he.decode(faqTitleDes.Heading) }}
          ></h2>
          <p
            className="ecom-main-indus-section-sub-heading text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(faqTitleDes.description),
            }}
          ></p>
          <div className="dgtexp-solutions-accordion-main accordion accordion-flush">
            {faq && faq.length > 0
              ? faq.map((i, index) => (
                  <div className="accordion-item" key={index}>
                    <h3
                      className="accordion-header dgtexp-solutions-accordion-quest"
                      id={`flush-heading${index}`}
                    >
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse${index}`}
                      >
                        <span>0{index + 1}.</span> {i.title}
                      </button>
                    </h3>
                    <div
                      id={`flush-collapse${index}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby={`flush-heading${index}`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      {/* -------- eCommerce App Idea section ----------------- */}
      <section className="ecom-indus-app-idea-sec" id="bpo-commerce-indus-sec">
        <div className="container">
          <h3
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(counterTitleDes.Heading),
            }}
          ></h3>
          <div>
            <div className="row mt-2">
              {counter && counter.length > 0
                ? counter.map((i) => (
                    <div className="col-lg-4 col-md-4 col-sm-12" key={i.id}>
                      <div className="app-idea-counter-serve">
                        <h4>
                          {i.prefix}
                          {i.number}
                          {i.suffix}
                        </h4>
                        <p>{i.title}</p>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* -------- language tools section ----------------- */}
      <section id="bpo-commerce-indus-sec">
        <div className="container">
          <h2
            className="indus-main-ecom-section-heading bpo-ecom-indus-about-eff text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(toolsTitleDes.Heading),
            }}
          ></h2>
          <div className="language-tools-tabs-main">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#web-dev-tools"
                >
                  Web Technologies
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#app-dev-tools"
                >
                  App Technologies
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="web-dev-tools" className="tab-pane fade show active">
                <div className="row" id="language-tools-tabs-content-inr">
                  {webTechnology && webTechnology.length > 0
                    ? webTechnology.map((i) => (
                        <div className="col-lg-2 col-md-4 col-sm-6" key={i.id}>
                          <div>
                            <img src={i.tlogo} alt={i.tlogo_alt} />
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <div id="app-dev-tools" className="tab-pane fade">
                <div className="row" id="language-tools-tabs-content-inr">
                  {appTechnology && appTechnology.length > 0
                    ? appTechnology.map((i) => (
                        <div className="col-lg-2 col-md-4 col-sm-6" key={i.id}>
                          <div>
                            <img src={i.tlogo} alt={i.tlogo_alt} />
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CtaStripe />
      {/* ----------------latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Discover the latest trends in business process automation with our
            dynamic insights. Learn about the most recent developments in the
            field, as well as successful stories and best practices, to enable
            your company to prosper in the digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.BpoBlogLatest}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Business Outsourcing Makes Businesses More Resilient for
                Long-Term Growth
              </h2>
              <p className="recent-blogs-slider-desc">
                Businesses that are resilient can navigate setbacks adeptly,
                preserving operations and reputations through swift adaptation.
                The key lies in agile responses, with digital tools playing an
                increasingly crucial role in fostering flexibility. Despite
                economic uncertainty, most IT budgets are on the rise, as
                indicated by 80% planning increased spending, while only 11%
                anticipate cuts. This resilience is attributed to the strategic
                use of resources and technology, reinforcing the vital
                connection between adaptability, digitalization, and sustained
                business success.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Data Governance Matters: How to Keep Your Data Clean
                            and Accurate?
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Data governance enhances data quality, reliability,
                          and security, ensuring accurate decision-making and
                          service delivery...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Business Process Outsourcing: Everything You Need to
                            Know About
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Scaling a business at a reasonable cost can be
                          achieved through Business Process Outsourcing or
                          BPO...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Use of AI-Backed OCR in the Healthcare System
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          AI's diverse applications include OCR, leveraging data
                          capture and functions for rapid, 99% accurate
                          performance...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EcommerceAppServices;

import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import he from "he";

import {
  faLongArrowAltRight,
  faArrowRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BlogContext from "../../../context/blogContext";
import MainAbout from "../../../components/MainAbout";
import IndustryServe from "../../../components/IndustryServe";

const ItSupport = () => {
  const [current, setCurrent] = useState("");
  useEffect(() => {
    const sections = document.querySelectorAll("section");
    window.addEventListener("scroll", () => {
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          window.pageYOffset >= sectionTop - 130 &&
          window.pageYOffset < sectionTop + sectionHeight
        ) {
          setCurrent(section.getAttribute("id"));
        }
      });
    });
  }, []);

  useEffect(() => {
    const yourNavigation = document.querySelector(".nag-page-index-itsupport");
    const stickyDiv = "sticky-itsupport";
    const yourHeader = 620;

    if (yourNavigation) {
      const handleScroll = () => {
        var currentWidth =
          window.innerWidth || document.documentElement.clientWidth;
        if (currentWidth >= 991) {
          if (window.scrollY > yourHeader) {
            yourNavigation.classList.add(stickyDiv);
          } else {
            yourNavigation.classList.remove(stickyDiv);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    mainAboutData,
    serviceIndustryData,
    pagesData,
    ourExpertiseData,
    mainServicesData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 8 && i.page_id === 22),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 8 && i.page_id === 22),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const industryWeServe = useMemo(
    () => pagesData.filter((i) => i.id === 14),
    [pagesData]
  );

  const [industryWeServeData] = industryWeServe;

  const serviceIndustry = useMemo(
    () =>
      serviceIndustryData.filter(
        (i) => i.category_id === 8 && i.page_id === 22
      ),
    [serviceIndustryData]
  );

  const ourExpTop = useMemo(
    () => pagesData.filter((i) => i.id === 21),
    [pagesData]
  );

  const [ourExpTopHead] = ourExpTop;

  const ourExpertiseFilter = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 8 && i.page_id === 22),
    [ourExpertiseData]
  );

  const whatWeDoTop = useMemo(
    () => pagesData.filter((i) => i.id === 35),
    [pagesData]
  );

  const [whatWeDoTopHead] = whatWeDoTop;

  const filteredServices = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 8 && i.page_id === 22),
    [mainServicesData]
  );

  if (
    !ourExpTopHead ||
    !ourExpertiseFilter ||
    !whatWeDoTopHead ||
    !filteredServices
  )
    return null;
  return (
    <>
      {/* ---------------- It support banner section --------------- */}
      {first && (
        <section
          id="itsupport-banner"
          className="itsupport-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="itsupport-div-banner-title">
                  <h2 className="itsupport-banner-heading" data-aos="fade-down">
                    {first.title}
                  </h2>
                  <p
                    className="itsupport-banner-para"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>

                <div className="row" id="play-button-itsupport">
                  <div className="col-lg-9  col-sm-12 itsupport-know-how-btn mt-3">
                    <div className="itsupport-banner-know-how">
                      <a href="#itsupport-approach">
                        {first.btn_first}{" "}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* --------------- It support page index section ------------- */}
      <section className="nag-page-index-itsupport">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="navbar-index-itsupport">
                <ul className="nav-index-itsupport">
                  <li>
                    <a
                      className={`nav-link-index-itsupport ${
                        current === "about" ? "active" : ""
                      }`}
                      href="#about"
                      aria-label="about"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-itsupport ${
                        current === "industries" ? "active" : ""
                      }`}
                      href="#industries"
                      aria-label="industries"
                    >
                      Industries
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-itsupport ${
                        current === "itsupport-services" ? "active" : ""
                      }`}
                      href="#itsupport-services"
                      aria-label="Services"
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-itsupport ${
                        current === "itsupport-approach" ? "active" : ""
                      }`}
                      href="#itsupport-approach"
                      aria-label="what-we-do"
                    >
                      Approach
                    </a>
                  </li>
                  <li>
                    <a
                      className={`nav-link-index-itsupport ${
                        current === "our-blog" ? "active" : ""
                      }`}
                      href="#our-blog"
                      aria-label="Our Blog"
                    >
                      Our Blog
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "5px 2rem" }}>
          <Link className="text-black" to="/">
            Home{" "}
          </Link>{" "}
          <MdOutlineArrowForwardIos style={{ fontSize: "13px" }} />
          <Link className="text-black" to={"/it-consulting-support"}>
            {" "}
            IT Consulting & Support{" "}
          </Link>
        </div>
      </section>

      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* --------- managed it industries  section ----------- */}

      <IndustryServe
        industryWeServeData={industryWeServeData}
        serviceIndustry={serviceIndustry}
      />

      <CtaStripe />

      {/* ----------- it industries ------------------ */}
      <section id="itsupport-services">
        <div className="container">
          <div className="itsopport-intro-head">
            <h2 className="itsopport-intro-section-heading smart-solution-head-bg text-center">
              <span>{whatWeDoTopHead.Heading}</span>
            </h2>
            <p
              className="itsopport-intro-section-sub-heading text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(whatWeDoTopHead.description),
              }}
            ></p>
          </div>
          <div className="row">
            {filteredServices.map((i) => (
              <div
                className="col-lg-4 col-md-4 col-sm-12"
                data-aos="fade-up"
                key={i.id}
              >
                <div className="card itsupport-industries-crd">
                  <div className="itsupport-industries-crd-imgs">
                    <Link to={i.page_link}>
                      <img
                        src={i.service_img}
                        alt={i.service_img_alt}
                        className="card-img-top"
                      />
                    </Link>
                  </div>

                  <div className="card-body">
                    <Link to={i.page_link}>
                      <h3>{i.title}</h3>
                    </Link>
                    <p
                      className="card-text"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                    <div className="itsupport-industries-small-box-down">
                      <Link to={i.page_link}>
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* ------------- Our Approach Towards Solution -------- */}
      <section id="itsupport-approach" className="itsupport-approach-solution">
        <div className="container">
          <div className="itsopport-intro-head">
            <div className="heading-left-line-als">
              <h2 className="itsopport-intro-section-heading approach-bg-head text-center">
                <span
                  dangerouslySetInnerHTML={{
                    __html: he.decode(ourExpTopHead.Heading),
                  }}
                ></span>
              </h2>
            </div>
            <p
              className="itsopport-intro-section-sub-heading mt-2 text-center"
              dangerouslySetInnerHTML={{
                __html: he.decode(ourExpTopHead.description),
              }}
            ></p>
          </div>
          <div className="row itsupport-approach-cards-row">
            {ourExpertiseFilter.map((i) => (
              <div
                className="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 itsupport-approach-enforcement-cards"
                data-aos="flip-left"
                key={i.id}
              >
                <img src={i.expertise_img} alt={i.expertise_img_alt} />
                <div>
                  <h2>{i.title}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Explore the potential of managed IT support services through our
            informative blogs. Stay informed about industry advancements for
            streamlined business growth. Enhance your digital journey with our
            expert insights.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.ManageItBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                6 Reasons You Must Have Data Backup and Recovery
              </h2>
              <p className="recent-blogs-slider-desc">
                Data loss is unpredictable and can stem from various sources
                like computer malfunctions, theft, or human errors. Natural
                disasters such as fires or floods also pose threats. Neglecting
                backup protocols like the 3-2-1 rule and relying solely on local
                storage increases vulnerability. Implementing data recovery
                solutions is vital to mitigate risks and ensure business
                continuity. Protecting against data loss is crucial for
                safeguarding valuable information and maintaining operational
                resilience in today's digital landscape.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Top 7 Tips to Keep Personal Data Safe & Secure
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Prioritize security online, whether as an individual
                          or organization, with methods to protect data,
                          especially when sensitive or voluminous.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            11 Cybersecurity Best Practices for Healthcare
                            Organizations
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Healthcare cybersecurity defends sensitive patient
                          data, including BMS, IoMT assets, and infrastructure,
                          ensuring safe care delivery through dedicated
                          solutions.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Role and Impact of DevOps for QA Testing
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          DevOps merges development and operations teams,
                          focusing on collaboration, automation, continuous
                          integration, and agile techniques for efficient
                          software production.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            Top 7 Tips to Keep Personal Data Safe & Secure
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Prioritize security online, whether as an individual
                          or organization, with methods to protect data,
                          especially when sensitive or voluminous.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            11 Cybersecurity Best Practices for Healthcare
                            Organizations
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Healthcare cybersecurity defends sensitive patient
                          data, including BMS, IoMT assets, and infrastructure,
                          ensuring safe care delivery through dedicated
                          solutions.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItSupport;

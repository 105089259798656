import React, { useContext, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import { Link } from "react-router-dom";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const DigitalOperation = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    blogData,
    mainAboutData,
    ourExpertiseData,
    pagesData,
    segmentData,
    mainServicesData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 7 && i.page_id === 16),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 7 && i.page_id === 16),
    [blogData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 7 && i.page_id === 16),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const expertiseTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 76),
    [pagesData]
  );

  const expertise = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 7 && i.page_id === 16),
    [ourExpertiseData]
  );

  const segmentTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 77),
    [pagesData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.page_id === 16 && i.section_id === 76),
    [segmentData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 78),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 7 && i.page_id === 16),
    [mainServicesData]
  );

  if (!expertiseTitleDes || !segmentTitleDes || !segment) return null;
  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#dgt-network-services"} />
      <BreadCrumb
        url={"/digital-transformation"}
        pageTitle={"Digital Transformation"}
        currentPage={"Digital Operations"}
      />

      {/*------------------------- dgt-op What-we-do start--------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/*------------------------- OUR EXPERTIES section" ------------------*/}
      <section
        id="dgt-op-expertise"
        className="inr-Digital-Our-Expertise"
        style={{
          backgroundImage: `url(${IMG.BpoUnderBack})`,
        }}
      >
        <div className="container">
          <div className="inner-dgt-section-heading text-center">
            <h2>{expertiseTitleDes.Heading}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiseTitleDes.description),
              }}
            ></p>
          </div>

          <div className="row">
            {expertise && expertise.length > 0
              ? expertise.map((i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12"
                    data-aos="flip-left"
                    key={i.id}
                  >
                    <div className="Digital-expert-service text-center">
                      <img
                        src={IMG.TopCustomers}
                        className="top-customer"
                        alt="img"
                      />
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
      {/*------------------------- Operationally Agile section" ------------------*/}
      <section id="dgt-op-operationally-agile">
        <div className="container">
          <h6 className="customer-experience-pre-head">
            {segmentTitleDes.pre_heading}
          </h6>
          <div className="customer-experience-head-div">
            <h2
              className="dgt-sub-section-heading-spec"
              dangerouslySetInnerHTML={{
                __html: he.decode(segmentTitleDes.Heading),
              }}
            ></h2>
          </div>
          <div className="row" id="operationally-agile-main">
            <div
              className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 dgo-agile-para"
              data-aos="fade-up-right"
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(segment.description),
                }}
              ></p>

              <div class="pb-4 dgt-tabs-accor-content">
                <Link to="/digital-transformation">Learn More</Link>
              </div>
            </div>
            <div
              class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 dgo-centered-div"
              data-aos="fade-up-left"
            >
              <img
                src={segment.image}
                alt={segment.image_alt}
                className="ago-algie-img"
              />
            </div>
          </div>
        </div>
      </section>

      <CtaStripe />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />
      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default DigitalOperation;

import React, { useContext, useEffect, useMemo, useState } from "react";
import IMG from "../../layouts/ImagesOld2";
import { Link } from "react-router-dom";
import { CtaStripe } from "../Services/ApplicationService/ApplicationServices";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import he from "he";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

import "react-toastify/dist/ReactToastify.css";
import BlogContext from "../../context/blogContext";
import axios from "axios";
import Loader from "../../components/Loader";

const OurCEO = () => {
  const [loadMore, setLoadMore] = useState(false);
  const { blogData } = useContext(BlogContext);
  const [ceoData, setCeoData] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleLoadMore = () => {
    setLoadMore(!loadMore);
  };

  const homeBlog = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { bannerData } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 29 && i.page_id === 110),
    [bannerData]
  );
  const [first] = bpoBanner;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError("");
        const res = await axios.get(
          "https://admin.benosupport.com/api/our-ceo"
        );

        const result = res.data.data;
        setCeoData(result);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    })();
  }, []);

  if (!ceoData) return null;

  const description = he.decode(ceoData.description);
  const truncatedDescription = description.split(" ").slice(0, 250).join(" ");

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (loading) return <Loader />;

  return (
    <>
      {/* ----------------------- our ceo banner  ---------------------------------- */}

      {first ? (
        <section
          className="explore-banner"
          style={{
            backgroundImage: `url(${IMG.OurCeoBannerNew})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="banner-content-explore">
                  <h2>{first.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="explore-banner-sec-img">
                  <img src={first.banner_img} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ----------------------- our ceo about  ---------------------------------- */}

      <section className="our-ceo-about" id="our-ceo-about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="our-ceo-about-sub-head">
                <h2>{ceoData.title}</h2>
              </div>
              <div className="our-ceo-about-down-line"></div>
              <div className="our-ceo-inner-para-container">
                <div className="our-ceo-inner-para">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: isReadMore ? description : truncatedDescription,
                    }}
                  ></p>
                  {description.split(" ").length > 250 && (
                    <div className="our-ceo-learn-more">
                      <button onClick={toggleReadMore}>
                        {isReadMore ? "Read Less" : "Read More"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* --------------------------- our ceo contact us  ------------------------ */}
      <CtaStripe />

      <section className="services-section-h space">
        <div className="shape-bg-12">
          <img src="assets/img/shape-12-1.png" alt="img" />
        </div>
        <div className="container">
          <div className="subheading text-center">
            <h2 className="innovations-home-beno">
              <span className="heading-ani-effect-tech"></span> What We Think
            </h2>
            <p className="industries_content">
              Welcome to our tech-centric blog page, where knowledge meets
              inspiration, offering an blogData of captivating articles to
              empower your understanding of cutting-edge technology and its
              boundless possibilities.
            </p>
          </div>
          <Slider {...homeBlog}>
            {blogData &&
              blogData.length > 0 &&
              blogData.slice(0, 5).map((i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                  key={i.id}
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to={`/blog/${i.blog_slug}`}>
                        <img src={i.blog_img} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a>{i.blog_date}</a>
                        <span className="meta-date pl-3">
                          {i.blog_category}
                        </span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to={`/blog/${i.blog_slug}`}>{i.title}</Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(
                              i.description.split(" ").slice(0, 25).join(" ") +
                                "..."
                            ),
                          }}
                        ></p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to={`/blog/${i.blog_slug}`}>
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default OurCEO;

import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import IMG from "../layouts/ImagesOld2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import he from "he";
import {
  faFacebookF,
  faInstagram,
  faXTwitter,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Zoom } from "react-toastify";
import BlogContext from "../context/blogContext";
import Loader from "../components/Loader";

const AboutBeno = () => {
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [associates, setAssociates] = useState(0);
  const [globalCustomers, setGlobalCustomers] = useState(0);
  const [countries, setCountries] = useState(0);

  useEffect(() => {
    const duration = 3000;

    const animateCounter = (finalValue, setValue) => {
      let start = 0;
      const startTime = performance.now();

      const updateCounter = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        if (elapsedTime >= duration) {
          setValue(finalValue);
        } else {
          const progress = elapsedTime / duration;
          start = progress * finalValue;
          setValue(Math.ceil(start));
          requestAnimationFrame(updateCounter);
        }
      };

      requestAnimationFrame(updateCounter);
    };

    // Animate each counter
    animateCounter(20, setTotalRevenue);
    animateCounter(100, setAssociates);
    animateCounter(500, setGlobalCustomers);
    animateCounter(4, setCountries);
  }, []);

  const setting = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const leaders = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [activeslider, setActiveslider] = useState(0);

  const {
    bannerData,
    loading,
    statisticsData,
    mainAboutData,
    pagesData,
    benefitsData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 29 && i.page_id === 108),
    [bannerData]
  );
  const [first] = bpoBanner;

  const statisticsAbout = useMemo(
    () =>
      statisticsData.filter((i) => i.category_id === 29 && i.page_id === 108),
    [statisticsData]
  );

  const dgtMainAbout = useMemo(
    () =>
      mainAboutData.filter((i) => i.category_id === 29 && i.page_id === 108),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const journeyTitle = useMemo(
    () => pagesData.filter((i) => i.id === 32),
    [pagesData]
  );

  const [journeyTitleDesc] = journeyTitle;

  const sliderContent = useMemo(
    () => benefitsData.filter((i) => i.category_id === 29 && i.page_id === 108),
    [benefitsData]
  );

  if (!dgtMainAboutArray || !sliderContent || !journeyTitleDesc) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/* ---------------- banner section --------------- */}
      {first ? (
        <section
          className="about-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="about-div-banner-title"></div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/*-------------about section --------------------*/}
      <section id="about-about" className="about-sec-about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2
                className="about-head-main-title"
                dangerouslySetInnerHTML={{
                  __html: he.decode(dgtMainAboutArray.title),
                }}
              ></h2>
              <div>
                <p
                  className="about-head-main-para"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(dgtMainAboutArray.description),
                  }}
                ></p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              id="about-about-dev-img"
            >
              <div className="about-about-introimg">
                <img
                  src={dgtMainAboutArray.about_img}
                  alt={dgtMainAboutArray.about_img_alt}
                />
              </div>
              <span className="about-about-triangle-down"></span>
            </div>
          </div>
        </div>
      </section>

      <section id="about-our-journey">
        <div className="container">
          <h2
            className="about-head-main-title"
            dangerouslySetInnerHTML={{
              __html: he.decode(journeyTitleDesc.Heading),
            }}
          ></h2>
          <p
            className="about-head-main-des"
            dangerouslySetInnerHTML={{
              __html: he.decode(journeyTitleDesc.description),
            }}
          ></p>
        </div>

        <div>
          <div
            style={{ backgroundImage: `url(${IMG.AboutJourneyBanner})` }}
            className="about-single-slider mt-4"
          >
            <div className="container">
              <div className="row">
                <div className="col xl-7 col-md-7 col-sm-12">
                  {sliderContent.map(
                    (slider, index) =>
                      activeslider === index && (
                        <div
                          className="about-single-slider-content-1-parent"
                          key={index}
                        >
                          <div className="about-single-slider-content-1">
                            <h2>{slider.title}</h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: he.decode(slider.description),
                              }}
                            ></p>
                          </div>
                        </div>
                      )
                  )}
                </div>

                <div className="col-xl-5 col-md-5 col-sm-12">
                  <div className="small-slider-about">
                    <Slider {...setting}>
                      {sliderContent.map((slider, index) => (
                        <div
                          onClick={() => setActiveslider(index)}
                          className="about-single-slider-content-2-parent"
                          data-aos="fade-left"
                          key={index}
                        >
                          <div className="about-single-slider-content-2">
                            <h3>{slider.title}</h3>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ---------  leading section  ----------- */}

      <section id="about-about" className="about-sec-about about-leading-sec">
        <div className="fluid-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 about-leading">
              <div>
                <h2 className="about-head-main-title about-leading-title">
                  Leading from
                </h2>
                <h2 className="about-head-main-title about-leading-title">
                  the front
                </h2>
                <Link to="/our-ceo" className="about-leading-meet-btn">
                  MEET OUR LEADER
                  <span></span>
                </Link>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-6 col-sm-12"
              id="about-about-dev-img"
            >
              <Slider {...leaders}>
                <div className="d-flex justify-content-around">
                  <div className=" about-leading-right">
                    <div className="about-leading-founder">
                      <h3>ABHINESH SINGH</h3>
                      <p>MD Beno Support</p>
                      <div className="about-socail mt-3">
                        <div>
                          <Link to={"#"}>
                            <FontAwesomeIcon icon={faFacebookF} />
                          </Link>
                        </div>
                        <div>
                          <Link to={"#"}>
                            <FontAwesomeIcon icon={faInstagram} />
                          </Link>
                        </div>
                        <div>
                          <Link to={"https://twitter.com/abineshpratapsi"}>
                            <FontAwesomeIcon icon={faXTwitter} />
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={"https://www.linkedin.com/in/abineshpratap/"}
                          >
                            <FontAwesomeIcon icon={faLinkedinIn} />
                          </Link>
                        </div>
                        <div>
                          <Link to={"#"}>
                            <FontAwesomeIcon icon={faYoutube} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="about-about-introimg about-leading-maim-img">
                      <img src={IMG.MdSirImg} alt="img" />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className=" about-leading-right">
                    <div className="about-leading-founder">
                      <h3>PAVAN SENGAR</h3>
                      <p>CEO Beno Support</p>
                      <div className="about-socail mt-3">
                        <div>
                          <Link to={"#"}>
                            <FontAwesomeIcon icon={faFacebookF} />
                          </Link>
                        </div>
                        <div>
                          <Link to={"#"}>
                            <FontAwesomeIcon icon={faInstagram} />
                          </Link>
                        </div>
                        <div>
                          <Link to={"#"}>
                            <FontAwesomeIcon icon={faXTwitter} />
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={
                              "https://www.linkedin.com/in/pavan-sengar-9587062a/"
                            }
                            target="blank"
                          >
                            <FontAwesomeIcon icon={faLinkedinIn} />
                          </Link>
                        </div>
                        <div>
                          <Link to={"#"}>
                            <FontAwesomeIcon icon={faYoutube} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="about-about-introimg about-leading-maim-img">
                      <img src={IMG.CeoNewImg} alt="img" />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      {/* ---------------- about page counter ------------------- */}

      <section className="about-counter">
        <div className="container">
          <div className="row">
            {statisticsAbout &&
              statisticsAbout.map((i) => (
                <div
                  className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 about-counter-content"
                  key={i.id}
                >
                  <h3>
                    {i.prefix} {i.number}
                    {i.suffix}
                  </h3>
                  <p>{i.title}</p>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutBeno;

import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import he from "he";
import BlogContext from "../../../context/blogContext";
import BreadCrumb from "../../../components/BreadCrumb";
import Loader from "../../../components/Loader";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const HRConsult = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    benefitsData,
    pagesData,
    mainServicesData,
    segmentData,
    solutionData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 12 && i.page_id === 43),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 12 && i.page_id === 43),
    [blogData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 12 && i.page_id === 43),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const benefits = useMemo(
    () => benefitsData.find((i) => i.category_id === 12 && i.page_id === 43),
    [benefitsData]
  );

  const benefitsTitle = useMemo(
    () => pagesData.find((i) => i.id === 116),
    [pagesData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 117),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 12 && i.page_id === 43),
    [mainServicesData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.page_id === 43 && i.section_id === 116),
    [segmentData]
  );

  const solution = useMemo(
    () => solutionData.find((i) => i.page_id === 43 && i.section_id === 116),
    [solutionData]
  );

  if (!benefits || !benefitsTitle) return null;

  if (loading) return <Loader />;
  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/hr-talent-services"}
        pageTitle={"HR Talent  Services"}
        currentPage={"HR & Talent Consulting"}
      />

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* --------- Approach section ----------- */}
      <section id="hr-talent-approach" className="hr-talent-approach-sec">
        <div className="container">
          <h3
            className="hr-talent-head-main-title text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitle.Heading),
            }}
          ></h3>
          <p
            className="hr-talent-head-main-para text-center"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitle.description),
            }}
          ></p>

          <div
            className="row"
            id="hr-talent-approach-row-main-content"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefits.description),
            }}
          ></div>
        </div>
      </section>

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/*--------------------------- dge result driven section----------------------- */}
      {solution ? (
        <section id="hr-talent-driven-sec" className="hr-driven-section">
          <div className="container">
            <div className="row hr-driven-section">
              <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 hr-driven-content">
                <h2>{solution.title}</h2>

                <div
                  dangerouslySetInnerHTML={{
                    __html: he.decode(solution.description),
                  }}
                ></div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 hr-driven-img">
                <img src={solution.image} alt={solution.image_alt} />
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <CtaStripe />
      {/* ------ section What we Think ----------------- */}
      {segment ? (
        <section id="hr-talent-what-we-think">
          <div class="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                <h2
                  className="hr-talent-head-main-title"
                  id="app-datamig-hr-talent-about-effect"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.title),
                  }}
                ></h2>
                <p
                  class="hr-talent-head-main-para"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div
                  className="hr-talent-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${segment.image})`,
                  }}
                ></div>
                <span className="hr-talent-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default HRConsult;

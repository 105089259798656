import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const WebsiteTransformation = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    blogData,
    pagesData,
    mainServicesData,
    ourExpertiseData,
    featureImages,
    benefitsData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 13 && i.page_id === 50),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 13 && i.page_id === 50),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 13 && i.page_id === 50),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 125),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 13 && i.page_id === 50),
    [mainServicesData]
  );

  const expertise = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 13 && i.page_id === 50),
    [ourExpertiseData]
  );

  const featureImage = useMemo(
    () => featureImages.find((i) => i.section_id === 125 && i.page_id === 50),
    [featureImages]
  );

  const benefitsTitle = useMemo(
    () => pagesData.find((i) => i.id === 126),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 13 && i.page_id === 50),
    [benefitsData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.section_id === 125 && i.page_id === 50),
    [segmentData]
  );

  if (!featureImage || !benefitsTitle) return null;
  if (loading) return <Loader />;

  return (
    <>
      {/* ---------------- banner section --------------- */}

      {/* {first && (
        <section
          className="dgt-experience-banner-main dgt-experience-banner-overlay "
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-10 col-sm-12">
                <div className="dgt-experience-div-banner-title">
                  <h2
                    className="dgt-experience-banner-heading"
                    data-aos="fade-down"
                  >
                    {first.title}
                  </h2>
                  <p
                    className="dgt-experience-banner-para"
                    data-aos="fade-up-right"
                    dangerouslySetInnerHTML={{
                      __html: he.decode(first.description),
                    }}
                  ></p>
                </div>
                <div className="content_marketing-dge-banner-bg-btn">
                  <a href="#services">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="content_marketing-dge-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/digital-experience"}
        pageTitle={"Digital Experiences"}
        currentPage={"Website Transformation & Localization"}
      />

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/*--------------------------- dge result driven section----------------------- */}
      <section className="dge-driven-sec">
        <div className="container">
          <div className="row dge-driven-section">
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 dge-driven-img website-transformation-img-height">
              <img src={featureImage.single_pic} alt={featureImage.pic_alt} />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8">
              <div className="dge-driven-content website-transformation-content">
                <h2 className="dgt-experience-head-main-title">
                  <span>{featureImage.name}</span>
                </h2>
                <div className="row">
                  {expertise && expertise.length > 0
                    ? expertise.map((i) => (
                        <div className="dge-cm-counter" key={i.id}>
                          <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2 dge-counter-sec-img website-transformation-img">
                            <img
                              src={i.expertise_img}
                              alt={i.expertise_img_alt}
                            />
                          </div>
                          <div className="col-sm-12 col-md-10 col-lg-10 col-xl-10 dge-counter-sec-content">
                            <h3>{i.title}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: he.decode(i.description),
                              }}
                            ></p>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="quality-assurance">
        <div className="container">
          <h2 className="dgt-experience-head-main-title text-center">
            {benefitsTitle.Heading}
          </h2>
          <div className="row">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div
                    className="col-sm-12 col-md-6 col-lg-4 col-xl-4 website-trans-content-border"
                    key={i.id}
                  >
                    <div className="website-trans-quality-assur">
                      <h2>{i.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />

      {/* -------------------section What we Think start------------------------------ */}
      {segment ? (
        <section id="dge-cm-what-we-think">
          <div class="container">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-up-right"
              >
                <h2
                  className="dgt-experience-head-main-title"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.title),
                  }}
                ></h2>
                <p
                  class="dgt-experience-head-main-para"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-up-left"
              >
                <div
                  className="cyber-security-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${segment.image})`,
                  }}
                ></div>
                <span className="cyber-security-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default WebsiteTransformation;

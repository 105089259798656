import React, { useState, useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BlogContext from "../../../context/blogContext";
import he from "he";
import BreadCrumb from "../../../components/BreadCrumb";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const HRContingent = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    blogData,
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    benefitsData,
    mainServicesData,
    segmentData,
    solutionData,
  } = useContext(BlogContext);
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 12 && i.page_id === 45),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 12 && i.page_id === 45),
    [bannerData]
  );
  const [first, rest] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 12 && i.page_id === 45),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const benefitsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 120),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 12 && i.page_id === 45),
    [benefitsData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 121),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 12 && i.page_id === 45),
    [mainServicesData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.section_id === 120 && i.page_id === 45),
    [segmentData]
  );

  const solution = useMemo(
    () => solutionData.find((i) => i.section_id === 120 && i.page_id === 45),
    [solutionData]
  );

  if (!benefitsTitleDes) return null;

  if (loading) return <Loader />;

  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/hr-talent-services"}
        pageTitle={"HR Talent  Services"}
        currentPage={"Staffing Services"}
      />

      {/*-------------about section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* --------------------------- labour managed ----------------------------- */}

      <section id="hr-talent-labour-managed">
        <div className="container">
          <h2
            className="hr-talent-labour-managed-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitleDes.Heading),
            }}
          ></h2>
          <p
            className="hr-talent-labour-managed-main-para"
            dangerouslySetInnerHTML={{
              __html: he.decode(benefitsTitleDes.description),
            }}
          ></p>
          <div className="row hr-talent-labour-managed-main">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div
                    className="col-lg-3 col-md-6 col-xs-12 hr-talent-labour-managed-box"
                    key={i.id}
                  >
                    <h4>{i.title}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* ----------  cyber think ------------- */}
      {solution ? (
        <section id="hr-talent-stories-think">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12" data-aos="flip-up">
                <div className="hr-talent-maximum-efficiency-first hr-talent-maximum-efficiency-first-img">
                  <img src={solution.image} alt={solution.image_alt} />
                </div>
              </div>
              <div
                className="col-lg-9 col-md-9 col-sm-12"
                data-aos="fade-up-left"
              >
                <div className="hr-talent-maximum-efficiency-second hr-talent-maximum-efficiency-second-img">
                  <h2>{solution.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: he.decode(solution.description),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <CtaStripe />

      {/* ------ section What we Think ----------------- */}
      {segment ? (
        <section id="hr-talent-what-we-think">
          <div class="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                <h2
                  className="hr-talent-head-main-title"
                  id="app-datamig-hr-talent-about-effect"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.title),
                  }}
                ></h2>
                <p
                  class="hr-talent-head-main-para"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(segment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div
                  className="hr-talent-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${segment.image})`,
                  }}
                ></div>
                <span className="hr-talent-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default HRContingent;

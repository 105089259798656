import React, { Fragment, useContext, useMemo, useState } from "react";
import IMG from "../../../layouts/ImagesOld2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faArrowRightLong,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { CtaStripe } from "../../Services/ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import Loader from "../../../components/Loader";
import MainAbout from "../../../components/MainAbout";
import he from "he";
import IndustryBanner from "../../../components/IndustryBanner";
const TechBpo = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [tab, setTab] = useState(0);

  const tabChangehandler = (index) => {
    setTab(index);
  };

  const {
    bannerData,
    loading,
    mainAboutData,
    pagesData,
    benefitsData,
    solutionData,
    featureImages,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 21 && i.page_id === 77),
    [bannerData]
  );
  const [first] = bpoBanner;

  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 21 && i.page_id === 77),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;
  const benefitsTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 167),
    [pagesData]
  );

  const benefits = useMemo(
    () => benefitsData.filter((i) => i.category_id === 21 && i.page_id === 77),
    [benefitsData]
  );

  const solution = useMemo(
    () => solutionData.filter((i) => i.section_id === 167 && i.page_id === 77),
    [solutionData]
  );

  const featureImage = useMemo(
    () => featureImages.find((i) => i.section_id === 167 && i.page_id === 77),
    [featureImages]
  );

  const segmentTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 168),
    [pagesData]
  );

  const multiSegment = useMemo(
    () => segmentData.filter((i) => i.section_id === 168 && i.page_id === 77),
    [segmentData]
  );

  if (!benefitsTitleDes || !featureImage || !segmentTitleDes) return null;
  if (loading) return <Loader />;
  return (
    <>
      {/*-------------- banner section --------------*/}
      {loading ? <Loader /> : null}
      {/* {first ? (
        <section
          id="tech-indus-main-banner"
          className="tech-indus-banner-main"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container">
            <div className="tech-indus-content tech-indus-content-tech-bpo ">
              <h3 data-aos="zoom-in">{first.title}</h3>
            </div>
            <div className="tech-indus-banner-btn">
              <a href="#why-us">{first.btn_first}</a>
              <Link to="tel:+91-892-988-4560" className="nbtn-tech-indus">
                {first.btn_second}
              </Link>
            </div>
          </div>
        </section>
      ) : null} */}

      <IndustryBanner industyBanner={first} indexId={"#why-us"} />

      <BreadCrumb
        url={"/technology"}
        pageTitle={"Technology"}
        currentPage={"BPO"}
      />

      <MainAbout mainAboutData={dgtMainAboutArray} />

      <section id="services" className="logistics-bpo-advantage">
        <div className="container">
          <div>
            <h2
              className="indus-main-logistics-section-heading"
              dangerouslySetInnerHTML={{
                __html: he.decode(benefitsTitleDes.Heading),
              }}
            ></h2>
          </div>
          <div className="row mt-4">
            {benefits && benefits.length > 0
              ? benefits.map((i) => (
                  <div
                    id="logistics-ind-capabilities-main"
                    data-aos="fade-up-left"
                    className="col-lg-6 col-md-6 col-sm-12"
                    key={i.id}
                  >
                    <div className="logistics-ind-inner-area-customer-main">
                      <h5>{i.title}</h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>

                      <div className="logistics-main-indus-serve-small-box">
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
      {/* -------- about section ----------------- */}
      <section className="education-about-sec" id="about">
        <div className="container education-cloud-agnostic">
          <h2
            className="education-section-heading"
            dangerouslySetInnerHTML={{ __html: he.decode(featureImage.name) }}
          ></h2>
          <div className="row mt-4">
            <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
              <div>
                <img
                  className="education-sub-img-about education-sub-img-about-ed-aps"
                  src={featureImage.single_pic}
                  alt={featureImage.pic_alt}
                />
                <span className="education-sub-triangle-down-right"></span>
              </div>
            </div>

            <div className="col-lg-5 col-md-6 col-sm-12 challenges-left-li bfsi-serve-exp-desc-right bfsi-serve-exp-desc-right-unique">
              {solution && solution.length > 0
                ? solution.map((i, index) => (
                    <Fragment key={index}>
                      <p
                        onClick={() => tabChangehandler(index)}
                        style={tab === index ? { display: "none" } : {}}
                      >
                        {i.title}
                      </p>
                      <div
                        className={`challenges-left-arrow challenges-left-arrow-unique  ${
                          tab === index ? "show" : ""
                        }`}
                      >
                        <h3>{i.title}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: he.decode(i.description),
                          }}
                        ></p>
                      </div>
                    </Fragment>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>
      {/*---------------- Bpo service why-us section  ------------ */}
      <section className="bpo-serv-happens-sec" id="why-us">
        <div className="container">
          <h2
            className="bpo-serv-our-segments-heading"
            id="bpo-heading-hpn"
          ></h2>
          <h2
            className="indus-main-logistics-section-heading"
            dangerouslySetInnerHTML={{
              __html: he.decode(segmentTitleDes.Heading),
            }}
          ></h2>
          <p
            className="bpo-serv-our-segments-desc"
            dangerouslySetInnerHTML={{
              __html: he.decode(segmentTitleDes.description),
            }}
          ></p>
          {multiSegment && multiSegment.length > 0
            ? multiSegment.map((i, index) => (
                <div
                  className="row"
                  id="bpo-serv-happens-row-main-second"
                  key={index}
                >
                  <div
                    className={`col-lg-4 col-md-6 col-sm-12 bpo-serv-happens-box-right-col ${
                      index % 2 === 0 ? "order-md-1" : "order-md-2"
                    }`}
                    data-aos="fade-up-right"
                  >
                    <img
                      src={i.image}
                      alt={i.image_alt}
                      className="bpo-serv-happens-box-right-img"
                    />
                  </div>
                  <div
                    className={`col-lg-8 col-md-6 col-sm-12 bpo-serv-happens-box-left-col-second ${
                      index % 2 === 0 ? "order-md-2" : "order-md-1"
                    }`}
                    data-aos="fade-up-left"
                  >
                    <h2 className="bpo-serv-happens-box-head">
                      <span>{i.title}</span>
                    </h2>
                    <p
                      className="bpo-serv-happens-box-desc"
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </section>

      <CtaStripe />
      {/* ----------------  service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Explore the dynamic landscape of business process automation within
            the technology sector in our recent blog. Uncover recent
            advancements, success stories, and best practices for navigating the
            digital era.
          </p>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-once"
              data-aos="fade-down-right"
            >
              <img
                src={IMG.technologyBlog}
                alt="img"
                className="recent-blogs-slider-once-img"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12 recent-blogs-slider-second"
              data-aos="fade-down-left"
            >
              <h2 className="recent-blogs-slider-title">
                Cybersecurity in the Age of Digital Transformation: Best
                Practices for Protecting Your Business
              </h2>
              <p className="recent-blogs-slider-desc">
                In the rapidly evolving landscape of digital transformation,
                cybersecurity is paramount. This blog explores the latest
                cybersecurity threats facing businesses and provides essential
                best practices for protecting sensitive data and mitigating
                cyber risks effectively. From implementing robust security
                measures to fostering a culture of cybersecurity awareness,
                discover how to safeguard your business against evolving cyber
                threats and ensure resilience in the digital age.
              </p>
              <div className="row">
                <div className="col-4">
                  <Link
                    to="/blog-details"
                    className="recent-blogs-slider-btn px-4"
                  >
                    Learn More
                  </Link>
                </div>
                <div className="col-8 recent-blogs-slider-date">
                  <div className="dgt_blog_meta">
                    <a href="#">August 19, 2023 </a>
                    <span className="meta-date pl-3">Statistics, News</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------------- blog section ----------------- */}
      <section className="dgt-recent-blogs" id="dgt-recent-blogs">
        <div className="blog_area pt-85 pb-65">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Future of Remote Work: Navigating Challenges and
                            Embracing Opportunities
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Summary: Explore the evolving landscape of remote
                          work, from overcoming common challenges to harnessing
                          the benefits of remote collaboration.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Rise of Voice Commerce: How Voice Technology is
                            Transforming Online Shopping
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Dive into the world of voice commerce, exploring its
                          impact on consumer behavior, emerging trends, and
                          strategies for businesses...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog3} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Power of Influencer Marketing: Leveraging Social
                            Media Influencers to Grow Your Brand
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Delve into the realm of influencer marketing,
                          uncovering the strategies and tactics used by brands
                          to collaborate with social media influencers and
                          drive...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog1} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Future of Remote Work: Navigating Challenges and
                            Embracing Opportunities
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Summary: Explore the evolving landscape of remote
                          work, from overcoming common challenges to harnessing
                          the benefits of remote collaboration.
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More&nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-12"
                  data-aos="zoom-in-up"
                  data-aos-easing="ease-out-cubic"
                >
                  <div className="single_blog mb-4">
                    <div className="single_blog_thumb pb-4">
                      <Link to="/blog-details">
                        <img src={IMG.HomeBlog2} alt="img" />
                      </Link>
                    </div>
                    <div className="single_blog_content pl-4 pr-4">
                      <div className="dgt_blog_meta">
                        <a href="#">August 19, 2023 </a>
                        <span className="meta-date pl-3">Statistics, News</span>
                      </div>
                      <div className="blog_page_title pb-1 pt-3">
                        <h3>
                          <Link to="/blog-details">
                            The Rise of Voice Commerce: How Voice Technology is
                            Transforming Online Shopping
                          </Link>
                        </h3>
                      </div>
                      <div className="blog_description">
                        <p>
                          Dive into the world of voice commerce, exploring its
                          impact on consumer behavior, emerging trends, and
                          strategies for businesses...
                        </p>
                      </div>
                      <div className="blog_page_button pb-4">
                        <Link to="/blog-details">
                          Read More &nbsp;{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechBpo;

import React, { useContext, useMemo } from "react";
import Slider from "react-slick";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import ServiceBanner from "../../../components/ServiceBanner";

const BpoFinance = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const InnovationsSet = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    blogData,
    mainAboutData,
    mainServicesData,
    pagesData,
    segmentData,
  } = useContext(BlogContext);
  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 6 && i.page_id === 14),
    [bannerData]
  );
  const [first] = bpoBanner;
  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 6 && i.page_id === 14),
    [blogData]
  );

  const [firstItem, ...otherItems] = bpoBlog;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 6 && i.page_id === 14),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const serviceTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 26),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 6 && i.page_id === 14),
    [mainServicesData]
  );

  const pioneering = useMemo(
    () => segmentData.filter((i) => i.page_id === 14 && i.section_id === 26),
    [segmentData]
  );

  const pioneeringTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 72),
    [pagesData]
  );

  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#services"} />
      <BreadCrumb
        url={"/bpo-service"}
        pageTitle={"Bpo Services"}
        currentPage={"Finance & Accounting"}
      />

      {/*-------------------------BPO "understand the process" start--------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      <InnerServices headDesc={serviceTitleDes} innerServices={serviceData} />

      <CtaStripe />
      {/* -----------------BPO service finance page "service that change the pace" start------------------*/}
      <section id="fin-service-pace" className="fin-service-pace">
        <div className="container">
          {pioneeringTitleDes ? (
            <>
              <div className="what-we-heading">
                <h2
                  className="bpo-sub-section-heading"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(pioneeringTitleDes?.Heading),
                  }}
                ></h2>
              </div>
              <p
                className="bpo-sub-section-sub-heading"
                dangerouslySetInnerHTML={{
                  __html: he.decode(pioneeringTitleDes?.description),
                }}
              ></p>
            </>
          ) : null}
          <div className="fin-change-pace-cards">
            <Slider {...InnovationsSet}>
              {pioneering && pioneering.length > 0
                ? pioneering.map((i) => (
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 col-xl-6 col-xxl-6  fin-pace-card-1 fin-pace-card-content"
                      data-aos="flip-up"
                      key={i.id}
                    >
                      <img
                        src={i.image}
                        className="fin-pace-card-img "
                        id="fin-pace-card-img"
                        alt={i.image_alt}
                      />
                      <h6>{i.title}</h6>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: he.decode(i.description),
                        }}
                      ></p>
                    </div>
                  ))
                : null}
            </Slider>
          </div>
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default BpoFinance;

import React, { useContext, useMemo, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight, faPlay } from "@fortawesome/free-solid-svg-icons";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import LazyLoad from "react-lazyload";
import ServiceBanner from "../../../components/ServiceBanner";

const NetworkInfraServices = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    mainAboutData,
    statisticsData,
    mainServicesData,
    pagesData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBanner = useMemo(
    () => bannerData.filter((i) => i.category_id === 8 && i.page_id === 23),
    [bannerData]
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 8 && i.page_id === 23),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const statistics = useMemo(
    () => statisticsData.filter((i) => i.category_id === 8 && i.page_id === 23),
    [statisticsData]
  );

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 83),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 8 && i.page_id === 23),
    [mainServicesData]
  );

  const singleSegment = useMemo(
    () =>
      segmentData.find(
        (i) =>
          i.page_id === 23 &&
          i.section_id === 83 &&
          i.segment_type === "single-segment"
      ),
    [segmentData]
  );

  const multiSegment = useMemo(
    () =>
      segmentData.filter(
        (i) =>
          i.page_id === 23 &&
          i.section_id === 83 &&
          i.segment_type === "multi-segment"
      ),
    [segmentData]
  );

  return (
    <>
      {/* {first && (
        <section
          id="itsupport-sub-banner-ds"
          className="itsupport-sub-banner itsupport-sub-banner-infra"
          style={{
            backgroundImage: `url(${first.banner_img})`,
          }}
        >
          <div className="container" id="itsupport-sub-banner-main-cont">
            <div className="row">
              <div
                className="col-lg-10 col-md-10 col-sm-12"
                id="agile-banner-sec"
              >
                <h1
                  className="itsupport-sub-banner-Heading text-white"
                  data-aos="fade-left"
                >
                  {first.title}
                </h1>
                <p
                  className="itsupport-sub-banner-para text-white"
                  data-aos="fade-right"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(first.description),
                  }}
                ></p>

                <div className="it-support-inner-banner-bg-btn">
                  <a href="#services">{first.btn_first}</a>
                  <a
                    href="tel:+91-892-988-4560"
                    class="it-support-inner-banner-bg-btn-num"
                  >
                    {first.btn_second}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )} */}

      <ServiceBanner serviceBanner={first} indexId={"#services"} />

      <BreadCrumb
        url={"/it-consulting-support"}
        pageTitle={"IT Counsulting and Support"}
        currentPage={"Managed Network & Infra Services"}
      />

      {/*-------------It support intro section --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/*---------------- words from us section ------------------*/}
      <section id="itsopport-network-counter">
        <div className="container">
          <div className="row" id="itsopport-network-counter-row">
            {statistics && statistics.length > 0
              ? statistics.map((i) => (
                  <div
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 itsopport-network-counter-cards"
                    key={i.id}
                  >
                    <h2 data-aos="slide-down">
                      {i.prefix}
                      {i.number}
                      {i.suffix}
                    </h2>
                    <p data-aos="slide-down">{i.title}</p>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {singleSegment ? (
        <section id="itsupport-what-think-first">
          <div class="container">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-left"
                id="itsupport-what-think-head"
              >
                <h2 class="itsopport-intro-section-heading">
                  {singleSegment.title}
                  <span> </span>
                </h2>
                <p
                  class="itsopport-intro-section-sub-heading"
                  dangerouslySetInnerHTML={{
                    __html: he.decode(singleSegment.description),
                  }}
                ></p>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                data-aos="fade-right"
              >
                <div
                  className="itsupport-sub-under-img-right"
                  style={{
                    backgroundImage: `url(${singleSegment.image})`,
                  }}
                ></div>
                <span className="itsupport-sub-triangle-down"></span>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <CtaStripe />

      {/* ---------- application what think second ------------- */}
      <section id="itsupport-what-think-seocnd">
        <div className="container">
          {multiSegment && multiSegment.length > 0
            ? multiSegment.map((i) => (
                <div className="row" key={i.id}>
                  <div
                    className="col-lg-3 col-md-3 col-sm-12"
                    data-aos="flip-up"
                    id="itsupport-success-stories-multi"
                  >
                    <LazyLoad height={200} offset={100}>
                      <img
                        src={i.image}
                        className="itsupport-success-stories-multi-img"
                        alt={i.image_alt}
                      />
                    </LazyLoad>
                  </div>

                  <div
                    className="col-lg-9 col-md-9 col-sm-12"
                    data-aos="fade-up-left"
                    id="itsupport-success-stories-multi-data"
                  >
                    <h6 className="appser-ds-think-pre">{i.pre_heading}</h6>
                    <h5>{i.title}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: he.decode(i.description),
                      }}
                    ></p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </section>

      {/* ---------------- Bpo service latest one blog section  ----------- */}
      <section id="our-blog">
        <div className="container">
          <h2 className="recent-blogs-title-main">Recent Blogs</h2>
          <p className="recent-blogs-desc-main">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <Slider {...settings} className="recent-blog-cards-slider">
            {multiSegment && multiSegment.length > 0
              ? multiSegment.map((i) => (
                  <div className="blog-outer" key={i.id}>
                    <div className="blog-inner">
                      <div className="blog-top">
                        <LazyLoad height={200} offset={100}>
                          <img
                            src={i.image}
                            alt={i.image_alt}
                            className="img-fluid"
                          />
                        </LazyLoad>
                      </div>
                      <div className="blog-content">
                        <p className="date">24 May 2023</p>
                        <h3 className="title">{i.title}</h3>
                        <p className="desc">
                          {i.description.slice(0, 100) + "..."}
                        </p>
                        <Link to={`/blog/${i.id}`} className="read-more">
                          Read More
                          <FontAwesomeIcon
                            icon={faLongArrowAltRight}
                            className="right-arrow"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default NetworkInfraServices;

import React, { useContext, useMemo } from "react";
import { CtaStripe } from "../ApplicationService/ApplicationServices";
import BreadCrumb from "../../../components/BreadCrumb";
import BlogContext from "../../../context/blogContext";
import he from "he";
import MainAbout from "../../../components/MainAbout";
import InnerServices from "../../../components/InnerServices";
import FirstBlog from "../../../components/blogComponents/FirstBlog";
import OtherBlogs from "../../../components/blogComponents/OtherBlogs";
import ServiceBanner from "../../../components/ServiceBanner";

const DigitalWorkforce = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    bannerData,
    mainAboutData,
    pagesData,
    mainServicesData,
    ourExpertiseData,
    blogData,
    segmentData,
  } = useContext(BlogContext);

  const bpoBlog = useMemo(
    () => blogData.filter((i) => i.category_id === 7 && i.page_id === 17),
    [blogData]
  );
  const [firstItem, ...otherItems] = bpoBlog;

  const bpoBanner = bannerData.filter(
    (i) => i.category_id === 7 && i.page_id === 17
  );
  const [first] = bpoBanner;
  const dgtMainAbout = useMemo(
    () => mainAboutData.filter((i) => i.category_id === 7 && i.page_id === 17),
    [mainAboutData]
  );

  const [dgtMainAboutArray] = dgtMainAbout;

  const servicesTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 79),
    [pagesData]
  );

  const serviceData = useMemo(
    () =>
      mainServicesData.filter((i) => i.category_id === 7 && i.page_id === 17),
    [mainServicesData]
  );

  const expertiseTitleDes = useMemo(
    () => pagesData.find((i) => i.id === 80),
    [pagesData]
  );

  const expertise = useMemo(
    () =>
      ourExpertiseData.filter((i) => i.category_id === 7 && i.page_id === 17),
    [ourExpertiseData]
  );

  const segment = useMemo(
    () => segmentData.find((i) => i.page_id === 17 && i.section_id === 78),
    [segmentData]
  );

  if (!expertiseTitleDes || !segment) return null;
  return (
    <>
      <ServiceBanner serviceBanner={first} indexId={"#workforce-enablement"} />

      <BreadCrumb
        url={"/digital-transformation"}
        pageTitle={"Digital Transformation"}
        currentPage={"Digital Workforce Enablement"}
      />

      {/*-------------------------digital workfoce What-we-do --------------------*/}
      <MainAbout mainAboutData={dgtMainAboutArray} />

      {/* --------------------------digital workfoce  areas business ---------------------- */}
      <InnerServices headDesc={servicesTitleDes} innerServices={serviceData} />

      {/* --------------------------digital workfoce  Specialties of Workforce Enablement ---------------------- */}
      <section id="workforce-enablement">
        <div className="container">
          <h6 className="customer-experience-pre-head">
            {expertiseTitleDes.pre_heading}
          </h6>
          <div className="customer-experience-head-div">
            <h2
              className="dgt-sub-section-heading-spec"
              dangerouslySetInnerHTML={{
                __html: he.decode(expertiseTitleDes.Heading),
              }}
            ></h2>
          </div>
          <p
            className="dgo-workforce-para"
            dangerouslySetInnerHTML={{
              __html: he.decode(expertiseTitleDes.description),
            }}
          ></p>

          <div className="row workforce-cards-row">
            {expertise && expertise.length > 0
              ? expertise.map((i) => (
                  <div
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 dgo-enforcement-cards"
                    data-aos="flip-left"
                    key={i.id}
                  >
                    <img src={i.expertise_img} alt={i.expertise_img_alt} />
                    <div>
                      <h2>{i.title}</h2>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>

      <CtaStripe />
      {/* -------------------section What we Think start------------------------------ */}
      <section id="customer-what-we-think">
        <div class="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              data-aos="fade-up-right"
            >
              <h2
                class="dgt-sub-section-heading"
                dangerouslySetInnerHTML={{ __html: he.decode(segment.title) }}
              ></h2>
              <p
                class="dgt-sub-section-sub-heading"
                dangerouslySetInnerHTML={{
                  __html: he.decode(segment.description),
                }}
              ></p>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              data-aos="fade-up-left"
            >
              <div
                className="dgt-sub-under-img-right"
                style={{
                  backgroundImage: `url(${segment.image})`,
                }}
              ></div>
              <span className="dgt-sub-triangle-down"></span>
            </div>
          </div>
        </div>
      </section>

      {/* ----------section HR Services for Maximum Efficiency start------------- */}

      <FirstBlog firstItem={firstItem} />
      <OtherBlogs otherItems={otherItems} settings={sliderSettings} />
    </>
  );
};

export default DigitalWorkforce;

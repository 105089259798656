import React from "react";
import he from "he";

const ServiceBanner = ({ serviceBanner, indexId, blackTitle }) => {
  if (!serviceBanner) return null;
  return (
    <section
      className="service-banner"
      style={{ backgroundImage: `url(${serviceBanner.banner_img})` }}
    >
      <div className="service-banner-content">
        <h1
          data-aos="fade-left"
          className={blackTitle ? "head-text-black" : "text-white"}
          dangerouslySetInnerHTML={{ __html: he.decode(serviceBanner.title) }}
        ></h1>
        {serviceBanner.description && (
          <p
            className={blackTitle ? "para-text-black" : "text-white"}
            data-aos="fade-right"
            dangerouslySetInnerHTML={{
              __html: he.decode(serviceBanner.description),
            }}
          />
        )}

        <div className="service-banner-button">
          {serviceBanner.btn_first && (
            <a href={`${indexId || ""}`}>{serviceBanner.btn_first}</a>
          )}
          {serviceBanner.btn_second && (
            <a href={`tel:${serviceBanner.btn_second}`}>
              {serviceBanner.btn_second}
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default ServiceBanner;
